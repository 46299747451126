/**
 * Actions Types
 */

export enum AtendimentoTypes {
  SET_HORARIO_ATENDIMENTO = '@novo/atendimento/SET_HORARIO_ATENDIMENTO',
  SET_FORMA_ATENDIMENTO = '@novo/atendimento/SET_FORMA_ATENDIMENTO',
  SET_LOADING_ATENDIMENTO = '@novo/atendimento/SET_LOADING_ATENDIMENTO',
  CLEAR_ERROR_ATENDIMENTO = '@novo/atendimento/CLEAR_ERROR_ATENDIMENTO',
  POST_ATENDIMENTO = '@novo/atendimento/POST_ATENDIMENTO',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface AtendimentoState {
  readonly horario?: string
  readonly forma?: string
  readonly loadingAtendimento: boolean
}

export type AtendimentoPayload = {
  callback: () => void
}
