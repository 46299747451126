enum routes {
  CONSULTA_NAO_AUTORIZADA = '/simulador-app/consulta-nao-autorizada',
  GRUPO_MUNICIPAL = '/simulador-app/municipal',
  GRUPO_ESTADUAL = '/simulador-app/estadual',
  CONTATO = '/simulador-app/contato',
  CONTRATOPROPOSTA = '/simulador-app/contrato-proposta',
  RESUMO = '/simulador-app/resumo',
  NAO_LIBERADO = '/simulador-app/nao-liberado',
  DADOS_MANUAL = '/simulador-app/inss/dados-manual',
  AUTORIZACAO_CONSULTA_INSS = '/simulador-app/inss/autorizacao-consulta-inss',
  DADOS_RECEBIDOS = '/simulador-app/dados-recebidos',
  RESULTADO_SIMULACAO = '/simulador-app/resultado-simulacao',
  LOADING_PAGE = '/simulador-app/loading',
  NUMERO_BENEFICIO = '/simulador-app/inss/numero-beneficio',
  TERMOS = '/simulador-app/termos',
  SELECAO_GRUPO = '/simulador-app/selecao-grupo',
  TOKEN_SMS = '/simulador-app/token-sms',
  TOKEN_EMAIL = '/simulador-app/token-email',
  CONSULTA_MARGEM_CIP = '/simulador-app/consulta-margem-cip',
  CONSULTA_MARGEM_SIAPE = '/simulador-app/consulta-margem-siape',
  GRUPO_SIAPE = '/simulador-app/siape',
  OFERTA_PORTABILIDADE = '/simulador-novo/oferta-portabilidade',
  SIAPE_DADOS_MANUAL = '/simulador-app/siape/dados-manual',
  GRUPO_FEDERAL = '/simulador-app/federal',
  ALERTA_PORTABILIDADE = '/simulador-app/alerta-portabilidade',
  ONBOARDING = '/simulador-app/onboarding',
  VISUALIZAR_DOCUMENTO = '/simulador-app/visualizar-documento',
  FAQ = '/simulador-app/faq',
  ROOT = '/simulador-app',
}
export default routes
