import { useCallback, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { bottomSheetVariants, Variant } from '@utils/bottomSheetVariants'
import { setNovoValorTotal } from '@store/novo/simulador/actions'
import { FamiliaEnum, FamiliaType } from '@utils/simuladorUtils'

interface Props {
  valorTotal: number
  valorMinimo: number
  valorMaximo: number
  familia: FamiliaType
  hasObjetoSimulacao: boolean
  hasObjetoSimulacaoProtegida: boolean
  setVariant: (value: React.SetStateAction<Variant>) => void
  setOpenDialog?: (value: React.SetStateAction<boolean>) => void
  margemConsultada: boolean
  setNavigateNext?: (value: React.SetStateAction<boolean>) => void
  setNavigateError?: (value: React.SetStateAction<boolean>) => void
  isTotal: boolean
  valorSimulado: number
  convenioSelecionado: number
  isConvenioMargemAutorizada: boolean
}
const useVerificarMargem = ({
  valorTotal,
  valorMinimo,
  valorMaximo,
  hasObjetoSimulacao,
  hasObjetoSimulacaoProtegida,
  familia,
  setVariant,
  setOpenDialog,
  margemConsultada,
  setNavigateNext,
  setNavigateError,
  isTotal,
  valorSimulado,
}: Props) => {
  const dispatch = useDispatch()

  const marginRule = useCallback(() => {
    if (familia === FamiliaEnum.EMPRESA) {
      if (hasObjetoSimulacao || hasObjetoSimulacaoProtegida) {
        if (!isTotal) {
          dispatch(setNovoValorTotal(valorSimulado))
        }
        setNavigateNext && setNavigateNext(true)
      } else {
        setNavigateError && setNavigateError(true)
      }
    } else if (margemConsultada) {
      if (hasObjetoSimulacao || hasObjetoSimulacaoProtegida) {
        if (valorTotal <= valorMaximo) {
          if (!isTotal) {
            dispatch(setNovoValorTotal(valorSimulado))
          }
          setNavigateNext && setNavigateNext(true)
        }
      } else if (valorTotal < valorMinimo) {
        dispatch(setNovoValorTotal(valorMinimo))
        setVariant(bottomSheetVariants.margemMinima)
        setOpenDialog && setOpenDialog(true)
      } else if (valorTotal > valorMaximo) {
        dispatch(setNovoValorTotal(valorMaximo))
        setVariant(bottomSheetVariants.margemMenor)
        setOpenDialog && setOpenDialog(true)
      } else if (valorTotal <= valorMaximo && valorTotal >= valorMinimo) {
        setNavigateError && setNavigateError(true)
      } else {
        setNavigateError && setNavigateError(true)
      }
    } else if (hasObjetoSimulacao || hasObjetoSimulacaoProtegida) {
      if (!isTotal) {
        dispatch(setNovoValorTotal(valorSimulado))
      }
      setNavigateNext && setNavigateNext(true)
    } else if (valorTotal < valorMinimo) {
      setVariant(bottomSheetVariants.margemMinima)
      dispatch(setNovoValorTotal(valorMinimo))
      setOpenDialog && setOpenDialog(true)
    } else {
      setNavigateError && setNavigateError(true)
    }
  }, [
    dispatch,
    familia,
    hasObjetoSimulacao,
    hasObjetoSimulacaoProtegida,
    isTotal,
    margemConsultada,
    setNavigateError,
    setNavigateNext,
    setOpenDialog,
    setVariant,
    valorMaximo,
    valorMinimo,
    valorSimulado,
    valorTotal,
  ])

  useEffect(() => {
    marginRule()
  }, [marginRule])
}

export default useVerificarMargem
