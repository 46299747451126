import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { buildEstadosNovo } from '@utils/simuladorUtils'
import { Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet/types'
import Select from '@atoms/SelectNovo'

import { CustomBottomSheet } from './styles'

interface OwnProps {
  id: string
  estadoSelecionado: Option
  onChange: (value: Option) => void
  customLabel?: string
}

const EstadoDropdownNovo = ({ estadoSelecionado, onChange, id, customLabel }: OwnProps) => {
  const { estados = [], estadosLoading } = useSelector((state: ApplicationState) => state.globalApi)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp) {
    return (
      <Select
        label={customLabel || 'Em qual estado?'}
        name="dropdown"
        margin="0 0 20px 0"
        value={estadoSelecionado}
        hideSearch={false}
        options={buildEstadosNovo(estados)}
        placeholder="Selecione o estado"
        onChange={(value) => {
          onChange(value)
        }}
        disabled={estadosLoading}
        loading={estadosLoading}
      />
    )
  }

  return (
    <CustomBottomSheet
      id={id}
      label={customLabel || 'Em qual estado?'}
      name="dropdown"
      value={estadoSelecionado}
      onChange={(value) => {
        onChange(value)
      }}
      options={buildEstadosNovo(estados)}
      placeholder="Selecione o estado"
      disabled={estadosLoading}
      isLoading={estadosLoading}
      isUsingFilter
    />
  )
}

export default memo(EstadoDropdownNovo)
