import styled from 'styled-components'

import { FamiliaEnum, FamiliaType } from '@utils/simuladorUtils'

const GridFamilia = {
  publico: 4,
  inss: 3,
  empresa: 2,
}

export const GridButton = styled.div<{
  familia: FamiliaType
  height?: string | number
  margin?: string
}>`
  display: grid;
  grid-template-columns: ${({ familia }) => `repeat(${GridFamilia[familia]}, 1fr)`};
  grid-row-gap: 0.3em;
  grid-column-gap: 0.5em;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.familia === FamiliaEnum.PUBLICO ? '160px' : '150px')};
  height: ${(props) => props.height || '100%'};
  margin: ${(props) => props.margin || '0'};
`
