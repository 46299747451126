import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ApplicationState } from '@store/types'
import * as actions from '@store/token/actions'

export type TypeToken = 'sms' | 'email' | undefined

interface TypeValues {
  telefone?: string
  email?: string
}

interface OwnProps {
  open: boolean
  onSuccess: () => void
  onClose: () => void
  tokenType?: TypeToken
  showChoice?: boolean
  typeValues: TypeValues
  stateType: string
}

const mapStateToProps = (state: ApplicationState) => ({
  error: state.token.error,
  stepError: state.token.stepError,
  titleError: state.token.titleError,
  loading: state.token.loading,
  errorCallBack: state.token.errorCallback,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  ...bindActionCreators(actions, dispatch),
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
export type Props = OwnProps & ConnectedProps<typeof connector>
