import { BaseServices, HttpMethod } from '@services/base'
import { FontePagamentoApi } from '@store/globalApi/types'

export default (newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_FONTE_PAGAMENTOS
    : process.env.REACT_APP_FONTE_PAGAMENTOS_BFF
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request<FontePagamentoApi>({
    pathname,
    method,
    host,
  })
}
