import React, { lazy, Suspense } from 'react'

import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import ContratoModule from '@interco/cp-contratos-ui-lib'
import FallbackPage from '@components/organims/FallbackPage'
import { caching } from '@utils/functions'
import { ApplicationState } from '@store/types'

import routes from './routes'

const GrupoFederal = lazy(() => import('@components/pages/SimuladorApp/Grupos/Federal'))
const DadosManualSiape = lazy(
  () => import('@components/pages/SimuladorApp/Grupos/Siape/DadosManual'),
)
const OfertaPortabilidade = lazy(() => import('@components/pages/SimuladorApp/OfertaPortabilidade'))
const GrupoSiape = lazy(() => import('@components/pages/SimuladorApp/Grupos/Siape'))
const ConsultaMargemSiape = lazy(
  () => import('@components/pages/SimuladorApp/Consultas/ConsultaMargemSiape'),
)
const ConsultaMargemCip = lazy(
  () => import('@components/pages/SimuladorApp/Consultas/ConsultaMargemCip'),
)
const ConsultaNaoAutorizada = lazy(
  () => import('@components/pages/SimuladorApp/Consultas/ConsultaNaoAutorizada'),
)
const GrupoMunicipal = lazy(() => import('@components/pages/SimuladorApp/Grupos/Municipal'))
const Contato = lazy(() => import('@components/pages/SimuladorApp/Contato'))
const ContratoProposta = lazy(() => import('@components/pages/SimuladorApp/ContratoProposta'))
const Resumo = lazy(() => import('@components/pages/SimuladorApp/Resumo'))
const DadosManual = lazy(() => import('@components/pages/SimuladorApp/Grupos/Inss/DadosManual'))
const NaoLiberado = lazy(() => import('@components/pages/SimuladorApp/NaoLiberado'))
const DadosRecebidos = lazy(() => import('@components/pages/SimuladorApp/DadosRecebidos'))
const ResultadoSimulacao = lazy(() => import('@components/pages/SimuladorApp/ResultadoSimulacao'))
const LoadingPage = lazy(() => import('@components/pages/SimuladorApp/LoadingPage'))
const AutorizacaoConsultaInss = lazy(
  () => import('@components/pages/SimuladorApp/Grupos/Inss/AutorizacaoConsultaDocumento'),
)
const Termos = lazy(() => import('@components/pages/SimuladorApp/Grupos/Inss/Termos'))
const NumeroBeneficio = lazy(
  () => import('@components/pages/SimuladorApp/Grupos/Inss/NumeroBeneficio'),
)
const SelecaoGrupo = lazy(() => import('@components/pages/SimuladorApp/SelecaoGrupo'))
const TokenSms = lazy(() => import('@components/pages/SimuladorApp/Token/TokenSms'))
const TokenEmail = lazy(() => import('@components/pages/SimuladorApp/Token/TokenEmail'))
const Onboarding = lazy(() => import('@components/pages/SimuladorApp/Onboarding'))
const Faq = lazy(() => import('@components/pages/SimuladorApp/Onboarding/Faq'))
const RootPage = lazy(() => import('@components/pages/Privado/RootPage'))
const VisualizarDocumento = lazy(() => import('@components/pages/SimuladorApp/VisualizarDocumento'))

const Routes: React.FC = () => {
  const sessionStore = useSelector((state: ApplicationState) => state.session)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { numeroContrato } = useSelector((state: ApplicationState) => state.novo.dadosPessoais)

  caching(sessionStore)
  return (
    <Switch>
      <Suspense fallback={<FallbackPage />}>
        <Route exact path={routes.CONSULTA_NAO_AUTORIZADA} component={ConsultaNaoAutorizada} />
        <Route exact path={routes.GRUPO_MUNICIPAL} component={GrupoMunicipal} />
        <Route exact path={routes.CONTATO} component={Contato} />
        <Route exact path={routes.CONTRATOPROPOSTA} component={ContratoProposta} />
        <Route exact path={routes.RESUMO} component={Resumo} />
        <Route exact path={routes.NAO_LIBERADO} component={NaoLiberado} />
        <Route exact path={routes.DADOS_RECEBIDOS} component={DadosRecebidos} />
        <Route exact path={routes.RESULTADO_SIMULACAO} component={ResultadoSimulacao} />
        <Route exact path={routes.LOADING_PAGE} component={LoadingPage} />
        <Route exact path={routes.DADOS_MANUAL} component={DadosManual} />
        <Route exact path={routes.AUTORIZACAO_CONSULTA_INSS} component={AutorizacaoConsultaInss} />
        <Route exact path={routes.TERMOS} component={Termos} />
        <Route exact path={routes.NUMERO_BENEFICIO} component={NumeroBeneficio} />
        <Route exact path={routes.SELECAO_GRUPO} component={SelecaoGrupo} />
        <Route exact path={routes.TOKEN_SMS} component={TokenSms} />
        <Route exact path={routes.TOKEN_EMAIL} component={TokenEmail} />
        <Route exact path={routes.CONSULTA_MARGEM_CIP} component={ConsultaMargemCip} />
        <Route exact path={routes.CONSULTA_MARGEM_SIAPE} component={ConsultaMargemSiape} />
        <Route exact path={routes.GRUPO_SIAPE} component={GrupoSiape} />
        <Route exact path={routes.OFERTA_PORTABILIDADE} component={OfertaPortabilidade} />
        <Route exact path={routes.SIAPE_DADOS_MANUAL} component={DadosManualSiape} />
        <Route exact path={routes.GRUPO_FEDERAL} component={GrupoFederal} />
        <Route exact path={routes.ONBOARDING} component={Onboarding} />
        <Route exact path={routes.FAQ} component={Faq} />
        <Route exact path={routes.VISUALIZAR_DOCUMENTO} component={VisualizarDocumento} />
        <ContratoModule
          isApp={isApp}
          apiHost={process.env.REACT_APP_HOST_CONSIGNADO}
          environment={process.env.REACT_APP_ENVIROMENT}
          numeroContrato={numeroContrato}
        />
        <Route exact path={routes.ROOT} component={RootPage} />
      </Suspense>
    </Switch>
  )
}
export default Routes
