import { Reducer } from 'redux'

import { SiapeAutorizacaoAnuenciaEnum, TipoConsultaAutorizacaoSiape } from '@utils/enums'

import { SiapeAutorizacaoAnuenciaState, AutorizacaoAnuenciaTypes } from './types'

const INITIAL_STATE: SiapeAutorizacaoAnuenciaState = {
  autorizacaoSelecionada: TipoConsultaAutorizacaoSiape.CONSULTA_AUTORIZADA,
  anuenciaAutorizada: false,
  checkAnuenciaSiape: false,
  pageAtual: SiapeAutorizacaoAnuenciaEnum.OPCAO_AUTORIZAR_CONSULTA,
  pageAtualNovo: SiapeAutorizacaoAnuenciaEnum.AUTORIZACAO_SOU_GOV,
  tentativas: 0,
  consultaAutorizada: false,
  loading: false,
  pendencia: '',
  margem: 0,
}

const reducer: Reducer<SiapeAutorizacaoAnuenciaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AutorizacaoAnuenciaTypes.SET_AUTORIZACAO_ANUENCIA:
      return { ...state, autorizacaoSelecionada: action.payload }
    case AutorizacaoAnuenciaTypes.SET_ANUENCIA_AUTORIZADA:
      return { ...state, anuenciaAutorizada: action.payload }
    case AutorizacaoAnuenciaTypes.SET_CHECK_ANUENCIA_SIAPE:
      return { ...state, checkAnuenciaSiape: action.payload }
    case AutorizacaoAnuenciaTypes.SET_PAGES_SIAPE_AUTORIZACAO:
      return { ...state, pageAtual: action.payload }
    case AutorizacaoAnuenciaTypes.SET_PAGES_SIAPE_AUTORIZACAO_NOVO:
      return { ...state, pageAtualNovo: action.payload }
    case AutorizacaoAnuenciaTypes.SET_TENTATIVAS:
      return { ...state, tentativas: action.payload }
    case AutorizacaoAnuenciaTypes.SET_CONSULTA_AUTORIZADA:
      return { ...state, consultaAutorizada: action.payload }
    case AutorizacaoAnuenciaTypes.RESET_SIAPE:
      return { ...INITIAL_STATE }
    case AutorizacaoAnuenciaTypes.SET_LOADING:
      return { ...state, loading: action.payload }
    case AutorizacaoAnuenciaTypes.SET_PENDENCIA:
      return { ...state, pendencia: action.payload }
    case AutorizacaoAnuenciaTypes.SET_MARGEM:
      return { ...state, margem: action.payload }

    default:
      return state
  }
}

export default reducer
