import { Reducer } from 'redux'

import { AtendimentoTypes, AtendimentoState } from './types'

const INITIAL_STATE: AtendimentoState = {
  forma: undefined,
  horario: undefined,
  loadingAtendimento: false,
}

const reducer: Reducer<AtendimentoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AtendimentoTypes.SET_HORARIO_ATENDIMENTO:
      return { ...state, horario: action.payload }
    case AtendimentoTypes.SET_FORMA_ATENDIMENTO:
      return { ...state, forma: action.payload }
    case AtendimentoTypes.SET_LOADING_ATENDIMENTO:
      return { ...state, loadingAtendimento: action.payload }
    case AtendimentoTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
