import { action, Action } from 'typesafe-actions'

import {
  DadosConsultaInssState,
  DadosInssPayload,
  BeneficiarioInssTypes,
  StartPayload,
} from './types'

export const dadosBeneficiarioInssRequest = (payload: DadosInssPayload): Action =>
  action(BeneficiarioInssTypes.DADOS_BENEFICIARIO_INSS_REQUEST, payload)

export const dadosBeneficiarioInssSuccess = (payload: DadosConsultaInssState): Action =>
  action(BeneficiarioInssTypes.DADOS_BENEFICIARIO_INSS_SUCCESS, payload)

export const dadosBeneficiarioInssError = (): Action =>
  action(BeneficiarioInssTypes.DADOS_BENEFICIARIO_INSS_ERROR)

export const setRedirectInss = (payload: boolean): Action =>
  action(BeneficiarioInssTypes.SET_REDIRECT_INSS, payload)

export const setSimulacaoSimplificadaInss = (payload: boolean): Action =>
  action(BeneficiarioInssTypes.SET_SIMULACAO_SIMPLIFICADA_INSS, payload)

export const setCheckAutorizacaoInss = (payload: boolean): Action =>
  action(BeneficiarioInssTypes.SET_CHECK_AUTORIZACAO_INSS, payload)

export const clearSituacaoFuncional = (): Action =>
  action(BeneficiarioInssTypes.CLEAR_SITUACAOFUNCIONAL)

export const setScheduleSimulationId = (payload: number | string): Action =>
  action(BeneficiarioInssTypes.SET_SCHEDULE_SIMULATION_ID, payload)

export const scheduleSimulationRequest = (): Action =>
  action(BeneficiarioInssTypes.REQUEST_SCHEDULE_SIMULATION)

export const scheduleSimulationUpdate = (): Action =>
  action(BeneficiarioInssTypes.UPDATE_SCHEDULE_SIMULATION)

export const dadosInssResultRequest = (payload: DadosInssPayload): Action =>
  action(BeneficiarioInssTypes.DADOS_INSS_RESUlT_REQUEST, payload)

export const inssStartAsync = (payload: StartPayload): Action =>
  action(BeneficiarioInssTypes.DADOS_INSS_START_ASYNC, payload)

export const setLoadingInssApis = (payload: boolean): Action =>
  action(BeneficiarioInssTypes.SET_LOADING_INSS_APIS, payload)

export const setRecallInss = (payload: number): Action =>
  action(BeneficiarioInssTypes.SET_COUNTER_RECALL, payload)
