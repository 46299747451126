import React, { memo } from 'react'

import { InterUIInputGroup } from '@interco/inter-ui-react-lib'
import { Colors, Span } from '@interco/cp-react-ui-lib'
import { Grid } from '@material-ui/core'

import Props from './types'

const Input = ({
  id,
  type,
  label,
  placeholder,
  value,
  maxLength,
  margin,
  autoComplete,
  onChange,
  onBlur,
  dataTestId,
  infoButton,
  valid,
  invalid,
  validText,
  invalidText,
  observation,
  observationText,
  disabled,
  loading,
}: Props) => (
  <InterUIInputGroup margin={margin} valid={valid} invalid={invalid} loading={loading}>
    <label htmlFor={id} data-testid={`label-${dataTestId}`}>
      <Grid container justifyContent="space-between">
        <Grid item xs={8} sm={9}>
          {label}
        </Grid>
        {!!infoButton && (
          <Grid item xs={4} sm={3} data-testid="info-button" style={{ textAlign: 'right' }}>
            {infoButton}
          </Grid>
        )}
      </Grid>
    </label>
    <input
      autoComplete={autoComplete}
      placeholder={placeholder}
      id={id}
      name={id}
      value={value}
      type={type}
      maxLength={maxLength}
      onBlur={onBlur}
      onChange={onChange}
      data-testid={`input-${dataTestId}`}
      style={{ width: '100%' }}
      disabled={disabled}
    />
    {(invalid || valid) && (
      <Span
        style={{ float: 'right' }}
        color={valid ? Colors.SUCCESS500 : Colors.ERROR500}
        fontSize="12px"
        lineHeight="15px"
      >
        {valid ? validText : invalidText}
      </Span>
    )}
    {observation && !valid && !invalid && (
      <Span
        style={{ float: 'right', paddingTop: '4px' }}
        color={Colors.GRAY400}
        fontSize="12px"
        lineHeight="15px"
      >
        {observationText}
      </Span>
    )}
  </InterUIInputGroup>
)

Input.defaultProps = {
  type: 'text',
  autoComplete: 'off',
  value: undefined,
  margin: '0px 0px 30px 0px',
  placeholder: '',
  onChange: undefined,
  invalid: false,
  invalidText: '',
  disabled: false,
}

export default memo(Input)
