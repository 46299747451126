import { BaseServices, HttpMethod } from '@services/base'

export default (codigoProposta: string) => {
  const pathname = `${process.env.REACT_APP_SIMULACAO_SANGUE_LARANJA_ATUALIZAR_BFF}/${codigoProposta}`
  const method = HttpMethod.PUT
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request({
    pathname,
    method,
    host,
  })
}
