import { action, Action } from 'typesafe-actions'

import { AtendimentoTypes, AtendimentoPayload } from './types'

export const setHorarioAtendimento = (payload: string): Action =>
  action(AtendimentoTypes.SET_HORARIO_ATENDIMENTO, payload)

export const setFormaAtendimento = (payload: string): Action =>
  action(AtendimentoTypes.SET_FORMA_ATENDIMENTO, payload)

export const setLoadingAtendimento = (payload: boolean): Action =>
  action(AtendimentoTypes.SET_LOADING_ATENDIMENTO, payload)

export const clearErrorAtendimento = (): Action => action(AtendimentoTypes.CLEAR_ERROR_ATENDIMENTO)

export const postAtendimento = (payload: AtendimentoPayload): Action =>
  action(AtendimentoTypes.POST_ATENDIMENTO, payload)
