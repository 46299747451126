import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { removeMask } from '@utils/masks'
import { setPendencia } from '@store/novo/pendencia/actions'
import { setNovoValorTotal, setValorTotal } from '@store/novo/simulador/actions'
import { PendenciaEnum } from '@utils/enums'
import { setMatricula, setSalario } from '@store/novo/situacaoFuncional/actions'

import { dadosBeneficiarioInssError, dadosBeneficiarioInssSuccess } from '../actions'
import { DadosInssPayload } from '../types'

export default function* getDadosBeneficiarioInssSaga(action: AnyAction) {
  const payload = action.payload as DadosInssPayload
  const cpf = yield* select((state: ApplicationState) => state.novo.dadosPessoais.cpf)
  const beneficio = yield* select(
    (state: ApplicationState) => state.novo.situacaoFuncional.matricula,
  )
  yield put(setPendencia(''))

  try {
    const { data } = yield services.getDadosBeneficiarioInss(
      removeMask(cpf),
      removeMask(String(beneficio)),
    )
    yield put(dadosBeneficiarioInssSuccess(data))
    yield put(setPendencia(data?.pendencia))
    if (data?.infoInss?.margem) {
      yield put(setNovoValorTotal(data?.infoInss?.margem))
      yield put(setValorTotal(data?.infoInss?.margem))
    }
    if (data?.infoInss?.valorSalarioLiquido) {
      yield put(setSalario(data?.infoInss?.valorSalarioLiquido))
    }
    if (data?.infoInss?.numeroBeneficio) {
      yield put(setMatricula(data?.infoInss?.numeroBeneficio))
    }

    yield payload.callback(
      data?.isConsultaRealizada,
      data?.pendencia,
      data?.isBeneficioValido,
      data?.isHorarioValido,
    )
  } catch (error) {
    yield put(dadosBeneficiarioInssError())
    yield payload.callback(false, PendenciaEnum.SEM_PENDENCIA_LEAD_NOVO, false)
  }

  return cpf
}
