import React from 'react'

import { InterUIListDescription } from '@interco/inter-ui-react-lib'
import { FontFamily, Colors } from '@interco/cp-react-ui-lib'
import { withStyles, createStyles, WithStyles, withWidth, Grid } from '@material-ui/core'

const styles = createStyles({
  lista: {
    marginTop: '5px',
  },
  item: {
    marginLeft: '0',
  },
  itemCustom: {
    marginBottom: '14px',
    alignItems: 'center',
  },

  icone: {
    fontFamily: FontFamily.Inter,
    color: Colors.WHITE,
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    backgroundColor: Colors.GRAY500,
    fontSize: '12px',
    fontWeight: 600,
  },
  texto: {
    fontFamily: FontFamily.Inter,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '30px',
    color: Colors.GRAY500,
  },
  customStyleDescription: {
    fontFamily: FontFamily.Inter,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '17px',
    color: Colors.GRAY400,
  },
})

interface OwnProps extends WithStyles<typeof styles> {
  steps: string[]
  customStyleDescription?: boolean
}

const CustomVerticalSteps = ({ classes, steps, customStyleDescription }: OwnProps) => (
  <Grid container direction="column" className={classes.lista}>
    {Array.from(Array(steps.length), (item, index) => (
      <InterUIListDescription
        iconLeft={<div className={classes.icone}>{index + 1}</div>}
        key={index}
        className={customStyleDescription ? classes.itemCustom : classes.item}
      >
        <p className={customStyleDescription ? classes.customStyleDescription : classes.texto}>
          {steps[index]}
        </p>
      </InterUIListDescription>
    ))}
  </Grid>
)

CustomVerticalSteps.defaultProps = {
  customStyleDescription: false,
}

export default withWidth()(withStyles(styles)(CustomVerticalSteps))
