import { BaseServices, HttpMethod } from '@services/base'

export default (data: ValidaMatriculaRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_VALIDA_MATRICULA
    : process.env.REACT_APP_VALIDA_MATRICULA_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request<ValidaMatriculaResponse>({
    pathname,
    method,
    host,
    data,
  })
}

export interface ValidaMatriculaRequest {
  convenio: number
  cpf: string
  matricula: string | number
  indicativo?: number
  categoria?: number
  orgao: number
}

export interface ValidaMatriculaResponse {
  matriculaValida: boolean
  valorMargem: number
  message: string
}
