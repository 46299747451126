import { NovoState } from '@store/novo'
import {
  allNamesAfterFirstName,
  convenioMargemAutorizada,
  datePtToEn,
  FamiliaEnum,
  FamiliaType,
  findUpag,
  firstName,
} from '@utils/simuladorUtils'
import { SessionState } from '@store/session/types'
import { UiState } from '@store/ui'
import { SalvarLeadRequest } from '@services/postSalvarLeadNovo'
import {
  ConveniosCodes,
  FontesPagamento,
  SituacoesForcasArmadas,
  TipoEmprestimo,
  TiposIndicativos,
} from '@utils/enums'
import { removeMask } from '@utils/masks'
import { GlobalApiState } from '@store/globalApi/types'
import { FontePagamentoState } from '@store/novo/fontePagamento/types'
import { BeneficiarioInssState } from '@store/novo/consultasMargem/consultaBeneficiarioInss/types'

type SituacaoFuncionalTypes = 1 | 2 | 6
const indicativoOptions = (situacaoFuncional: SituacaoFuncionalTypes) => {
  const tipos = {
    1: TiposIndicativos.ativo,
    2: TiposIndicativos.inativo,
    6: TiposIndicativos.estabilizado,
  }
  return tipos[situacaoFuncional] || []
}

const isInss = (fontePagamento: FontePagamentoState, beneficiarioInss: BeneficiarioInssState) => {
  const { inssViaUrl } = fontePagamento
  const { simplificadaInss } = beneficiarioInss

  return Boolean(inssViaUrl || simplificadaInss)
}

const verificarConvenioDescricao = (novoState: NovoState) => {
  if (isInss(novoState.fontePagamento, novoState.beneficiarioInss)) {
    return 'INSS'
  }
  return (
    novoState.fontePagamento?.convenios?.find(
      (convenio) => convenio.codigo === Number(novoState.fontePagamento?.convenioSelecionado),
    )?.descricao || ''
  )
}

const verificarOrgaoDescricao = (novoState: NovoState) => {
  if (isInss(novoState.fontePagamento, novoState.beneficiarioInss)) {
    return 'INSS'
  }
  return (
    novoState.fontePagamento?.orgaos?.find(
      (orgao) => orgao.codigo === Number(novoState.fontePagamento?.orgaoSelecionado),
    )?.descricao || ''
  )
}

export const buildPostLead = (
  novoState: NovoState,
  session: SessionState,
  ui: UiState,
  familia: FamiliaType,
  globalState: GlobalApiState,
  idSimulacao: string,
  pendencia?: string | undefined,
  observacao?: string | undefined,
) => {
  const tipoSimulacao = novoState.simulacao?.simulacaoSelecionada
  const tipoSimulacoes = novoState.simulacao?.simulacoesSelecionada
  const simulationData = !ui.navigation.newSimulatorApi
    ? novoState?.simulacao[tipoSimulacao]
    : novoState?.simulacao.parcelaAtual
  const isFamiliaPrivada = familia === FamiliaEnum.EMPRESA

  const indicativoExercito = () => {
    if (
      novoState.consultaMargemExercito.tentativasExercito < 3 &&
      typeof novoState.situacaoFuncional.indicativo === 'string'
    ) {
      return novoState.situacaoFuncional.indicativo
    }
    return indicativoOptions(
      novoState.situacaoFuncional?.situacaoFuncional as SituacaoFuncionalTypes,
    )?.find((indicativo) => indicativo.value === Number(novoState.situacaoFuncional.indicativo))
      ?.label
  }

  const situacaoFuncionalExercito = () => {
    if (
      novoState.consultaMargemExercito.tentativasExercito < 3 &&
      typeof novoState.situacaoFuncional.situacaoFuncional === 'string'
    ) {
      return novoState.situacaoFuncional?.situacaoFuncional
    }
    return SituacoesForcasArmadas.find(
      (situacao) => situacao.value === Number(novoState.situacaoFuncional?.situacaoFuncional),
    )?.label
  }

  const dataIngressoExercito = () => {
    if (
      novoState.consultaMargemExercito.tentativasExercito < 3 &&
      typeof novoState.situacaoFuncional.situacaoFuncional === 'string'
    ) {
      return novoState?.situacaoFuncional?.dataIngresso
    }
    return datePtToEn(novoState?.situacaoFuncional?.dataIngresso)
  }

  const verificarSituacaoFuncional = () => {
    if (novoState.fontePagamento?.fonteSelecionada === FontesPagamento.FORCAS_ARMADAS) {
      return situacaoFuncionalExercito()
    }
    if (
      novoState.fontePagamento?.fonteSelecionada === FontesPagamento.INSS &&
      novoState.situacaoFuncional?.motivo === 'Por morte'
    ) {
      return 'Pensionista'
    }
    return novoState.situacaoFuncional?.situacaoFuncional
  }

  const margemPorConvenio = () => {
    const { convenioSelecionado } = novoState.fontePagamento

    switch (convenioSelecionado) {
      case ConveniosCodes.INSS:
        return Number(novoState.beneficiarioInss.dadosBeneficiario?.infoInss?.margem) || 0
      case ConveniosCodes.ESTADO_SP:
      case ConveniosCodes.PREF_SP:
      case ConveniosCodes.ESTADO_MT:
        return novoState.consultaMargemCip.margem
      case ConveniosCodes.ESTADO_RJ:
        return novoState.consultaMargemRio.valorMargem
      case ConveniosCodes.PREF_BH:
      case ConveniosCodes.PREF_SALVADOR:
      case ConveniosCodes.PREF_CAMPO_GRANDE:
      case ConveniosCodes.PREF_BETIM:
      case ConveniosCodes.PREF_VITORIA:
        return novoState.consultaMargemGeral.margem
      case ConveniosCodes.SIAPE_SERVIDOR_FEDERAL:
        return novoState.siapeAutorizarAnuencia.margem
      default:
        return 0
    }
  }

  const verificarCreditoProtegido = () => {
    if (ui?.navigation.newSimulatorApi) {
      return tipoSimulacoes === TipoEmprestimo.CREDITO_PROTEGIDO_ARRAY
    }
    return tipoSimulacao === TipoEmprestimo.CREDITO_PROTEGIDO
  }

  const objetoLead: SalvarLeadRequest = {
    autarquiaEmpresa: isFamiliaPrivada ? '' : verificarOrgaoDescricao(novoState),
    campanha: session.campanha,
    cartaoConsignado: false,
    celular: removeMask(novoState.codIndicacaoContato?.telefone),
    cidadeConvenio:
      globalState?.cidades?.find(
        (cidade) => cidade.codigo === String(novoState.fontePagamento?.cidadeSelecionada),
      )?.nome || '',
    clienteId: idSimulacao,
    convenio: isFamiliaPrivada ? '' : verificarConvenioDescricao(novoState),
    convenioId: isFamiliaPrivada ? '47' : String(novoState.fontePagamento?.convenioSelecionado),
    cpf: removeMask(novoState.dadosPessoais?.cpf),
    creditoProtegido: verificarCreditoProtegido(),
    dataIngressoExercito: dataIngressoExercito() || '',
    dataNascimento: datePtToEn(novoState.dadosPessoais?.dataNascimento),
    descricao: '',
    email: novoState.codIndicacaoContato?.email,
    etapaContratacaoDigital: 'Simulador',
    gClid: String(session?.gaClid) || '',
    gClientId: String(session?.gaClientId) || '',
    idFacebook: String(session?.facebookId) || '',
    indicativo:
      indicativoExercito() ||
      indicativoOptions(
        novoState.situacaoFuncional?.situacaoFuncional as SituacaoFuncionalTypes,
      )?.find((indicativo) => indicativo.value === Number(novoState.situacaoFuncional.indicativo))
        ?.label ||
      '',
    margem: margemPorConvenio(),
    matricula: isFamiliaPrivada ? '' : removeMask(String(novoState.situacaoFuncional?.matricula)),
    motivoSituacaoFuncional:
      novoState.fontePagamento?.fonteSelecionada === FontesPagamento.INSS
        ? String(novoState.situacaoFuncional?.motivo)
        : '',
    nomeEmpresa: isFamiliaPrivada ? novoState.dadosProfissionais.nomeEmpresa : '',
    origem: ui.navigation.isApp ? 'App' : 'Site',
    patente: novoState?.situacaoFuncional?.patente || '',
    primeiroNome: firstName(novoState.dadosPessoais?.nome),
    qtdParcelas: simulationData?.qtdeParcelas,
    salario: isFamiliaPrivada
      ? Number(novoState.dadosProfissionais.salario)
      : Number(novoState.situacaoFuncional?.salario),
    situacaoFuncional: verificarSituacaoFuncional(),
    taxa: simulationData?.taxaNominalAm,
    tipo: isFamiliaPrivada ? 'Crédito Consignado Privado' : 'Crédito Consignado Público',
    tipoContratacao: 'Consignado Digital',
    ufConvenio:
      novoState.fontePagamento?.convenios?.find(
        (convenio) => convenio.codigo === Number(novoState.fontePagamento?.convenioSelecionado),
      )?.estado || '',
    ultimoNome: allNamesAfterFirstName(novoState.dadosPessoais?.nome),
    upag: findUpag(globalState.upags, String(novoState?.situacaoFuncional.upag)),
    utmCampaign: String(session?.utmCampaign) || '',
    utmContent: String(session?.utmContent) || '',
    utmMedium: String(session?.utmMedium) || '',
    utmSource: String(session?.utmSource) || '',
    valorCartaoConsignado: novoState.simulacao?.limiteCartao,
    valorCET: simulationData?.cETAa,
    valorEmprestimo: simulationData?.valorLiberado,
    valorSeguro: simulationData?.valorSeguro,
    valorIOF: simulationData?.valorIOF,
    codigoIndiqueGanhe: novoState.codIndicacaoContato.validacaoCodigoMgm,
    codigoIndicacao: novoState.codIndicacaoContato.codigoIndicacao || '',
    pendencia: pendencia || '',
    observacao: observacao || '',
    convenioOutros: novoState.fontePagamento.nomeConvenio || '',
    margemConsultada: convenioMargemAutorizada(novoState),
    fluxo: 'SYNC',
  }

  return objetoLead
}
