import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import { propostasSuccess, propostasError, propostasLoading, propostasRequest } from '../actions'

export default function* getPropostas() {
  try {
    yield* put(propostasLoading())
    const cpfUser = yield* select((state: ApplicationState) => state.novo.dadosPessoais.cpf)
    const response = yield* call(services.getPropostas, cpfUser)

    yield* put(propostasSuccess(response.data.propostas))
  } catch (error) {
    yield* put(propostasError())
    yield* put(
      showError({
        title: 'Erro ao buscar as propostas',
        message: 'Ocorreu um erro listagem de propostas.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_002_FONTES,
        actionCallback: () => propostasRequest(),
      }),
    )
  }
}
