import { BaseServices, HttpMethod } from '@services/base'

export default (data: CloudRemoveRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_CLOUD_MARKET_REMOVE
    : process.env.REACT_APP_CLOUD_MARKET_REMOVE_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
}

export interface CloudRemoveRequest {
  cpf_cnpj: string
  origem: string
  modalidade_operacao: string
}
