import { action, Action } from 'typesafe-actions'

import {
  VerificaPendenciaLeadPayload,
  SimulacaoTypes,
  VerificaPendenciaPortabilidadePayload,
} from './types'

export const verificaPendenciaLeadRequest = (payload: VerificaPendenciaLeadPayload): Action =>
  action(SimulacaoTypes.VERIFICA_PENDENCIA_LEAD_REQUEST, payload)

export const verificaPendenciaPortabilidadeRequest = (
  payload: VerificaPendenciaPortabilidadePayload,
): Action => action(SimulacaoTypes.VERIFICA_PENDENCIA_PORTABILIDADE_REQUEST, payload)

export const verificaPendenciaLeadLoading = (payload: boolean): Action =>
  action(SimulacaoTypes.VERIFICA_PENDENCIA_LEAD_LOADING, payload)
