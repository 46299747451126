import { Reducer } from 'redux'

import { SimulacaoState, SimulacaoTypes } from './types'

const INITIAL_STATE: SimulacaoState = {
  loading: false,
  limiteCartao: 0,
  margemConsultada: false,
  simulacaoSelecionada: 'simulacaoComSeguro',
  simulacao: {
    cETAa: 0,
    dataUltimoVencimento: '',
    qtdeParcelas: 0,
    taxaNominalAm: 0,
    tIRAm: 0,
    tIRAa: 0,
    valorFinanciado: 0,
    valorIOF: 0,
    valorLiberado: 0,
    valorParcela: 0,
    valorSolicitado: 0,
    cETAm: 0,
    taxaNomialAa: 0,
    dataPrimeiroVencimento: '',
    jurosContrados: 0,
    jurosContradosPercentual: 0,
    valorTotalASerPago: 0,
    tarifaCadastroPercentual: 0,
    tarifaCadastro: 0,
    valorIOFPercentual: 0,
    valorSeguro: 0,
    valorSeguroPercentual: 0,
  },

  simulacaoComSeguro: {
    cETAa: 0,
    dataUltimoVencimento: '',
    qtdeParcelas: 0,
    taxaNominalAm: 0,
    tIRAm: 0,
    tIRAa: 0,
    valorFinanciado: 0,
    valorIOF: 0,
    valorLiberado: 0,
    valorParcela: 0,
    valorSolicitado: 0,
    cETAm: 0,
    valorIOFPercentual: 0,
    valorSeguro: 0,
    valorSeguroPercentual: 0,
    tarifaCadastro: 0,
    tarifaCadastroPercentual: 0,
    taxaNomialAa: 0,
    dataPrimeiroVencimento: '',
    jurosContrados: 0,
    jurosContradosPercentual: 0,
    valorTotalASerPago: 0,
  },
  simulacoes: [],
  simulacoesComSeguro: [],
  valorMaximoComSeguro: 60000,
  valorMaximo: 60000,
  valorMinimo: 2000,
  leadEnviado: false,
  hasObjetoSimulacao: false,
  hasObjetoSimulacaoProtegida: false,
  idSimulacao: undefined,
  isContratacaoCompleta: false,
  totalParcelas: 0,
  parcelaAtual: {
    qtdeParcelas: 0,
    valorSolicitado: 0,
    valorParcela: 0,
    valorLiberado: 0,
    valorIOF: 0,
    valorIOFPercentual: 0,
    valorSeguro: 0,
    valorSeguroPercentual: 0,
    tarifaCadastro: 0,
    tarifaCadastroPercentual: 0,
    valorFinanciado: 0,
    tIRAm: 0,
    tIRAa: 0,
    cETAa: 0,
    cETAm: 0,
    taxaNominalAm: 0,
    taxaNomialAa: 0,
    dataUltimoVencimento: '',
    dataPrimeiroVencimento: '',
    jurosContrados: 0,
    jurosContradosPercentual: 0,
    valorTotalASerPago: 0,
  },
  simulacoesSelecionada: 'simulacoesComSeguro',
  dataHoraDadosRecebidos: '',
  dadosSimulacaoAlterados: false,
}

const reducer: Reducer<SimulacaoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SimulacaoTypes.SIMULACAO_REQUEST:
    case SimulacaoTypes.SIMULACAO_SANGUE_LARANJA_REQUEST:
    case SimulacaoTypes.POST_CONTRATO_SANGUE_LARANJA_REQUEST:
    case SimulacaoTypes.PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_REQUEST:
    case SimulacaoTypes.GET_PARCELA_SIMULACAO_SANGUE_LARANJA_REQUEST:
      return { ...state, loading: true, error: false }
    case SimulacaoTypes.SIMULACAO_SUCCESS:
    case SimulacaoTypes.POST_CONTRATO_SANGUE_LARANJA_SUCCESS:
    case SimulacaoTypes.PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload,
      }
    case SimulacaoTypes.GET_PARCELA_SIMULACAO_SANGUE_LARANJA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case SimulacaoTypes.SIMULACAO_EXERCITO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        limiteCartao: action.payload.limiteCartao,
        margemConsultada: action.payload.margemConsultada,
        valorMaximo: action.payload.valorMaximo,
        valorMinimo: action.payload.valorMinimo,
        simulacaoComSeguro: action.payload.simulacao,
      }
    case SimulacaoTypes.SIMULACAO_ERROR:
    case SimulacaoTypes.SIMULACAO_SANGUE_LARANJA_ERROR:
    case SimulacaoTypes.POST_CONTRATO_SANGUE_LARANJA_ERROR:
    case SimulacaoTypes.PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_ERROR:
    case SimulacaoTypes.GET_PARCELA_SIMULACAO_SANGUE_LARANJA_ERROR:
      return { ...state, loading: false, error: true }
    case SimulacaoTypes.SELECT_SIMULACAO:
      return { ...state, simulacaoSelecionada: action.payload }
    case SimulacaoTypes.SALVAR_LEAD_REQUEST:
    case SimulacaoTypes.SALVAR_LEAD_SANGUE_LARANJA_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        leadEnviado: false,
      }
    case SimulacaoTypes.SALVAR_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        leadEnviado: true,
      }
    case SimulacaoTypes.SALVAR_LEAD_ERROR:
      return { ...state, loading: false, error: true }
    case SimulacaoTypes.CLEAR_SIMULACAO:
      return INITIAL_STATE
    case SimulacaoTypes.HAS_OBJETO_SIMULACAO:
      return { ...state, hasObjetoSimulacao: action.payload }
    case SimulacaoTypes.HAS_OBJETO_SIMULACAO_PROTEGIDA:
      return { ...state, hasObjetoSimulacaoProtegida: action.payload }
    case SimulacaoTypes.SET_VALOR_MAX:
      return { ...state, valorMaximo: action.payload }
    case SimulacaoTypes.SET_SIMULACAO_ID:
      return { ...state, idSimulacao: action.payload }
    case SimulacaoTypes.SET_IS_CONTRATACAO_COMPLETA:
      return { ...state, isContratacaoCompleta: action.payload }
    case SimulacaoTypes.SET_TOTAL_PARCELAS:
      return { ...state, totalParcelas: action.payload }
    case SimulacaoTypes.SET_PARCELA_SELECIONADA:
      return { ...state, parcelaAtual: action.payload }
    case SimulacaoTypes.SELECT_SIMULACOES:
      return { ...state, simulacoesSelecionada: action.payload }
    case SimulacaoTypes.SET_DATA_HORA_DADOS_RECEBIDOS:
      return { ...state, dataHoraDadosRecebidos: action.payload }
    case SimulacaoTypes.SET_DADOS_SIMULACAO_ALTERADOS:
      return { ...state, dadosSimulacaoAlterados: action.payload }
    case SimulacaoTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
