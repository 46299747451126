import React, { useEffect, useState } from 'react'

import { InterUIButton } from '@interco/inter-ui-react-lib'
import { P } from '@interco/cp-react-ui-lib'
import { Dialog } from '@molecules'
import { clearError } from '@store/ui/error/actions'

import { connector, Props } from './types'

const ErrorMessage = ({
  showError = false,
  message = 'Ocorreu um erro inesperado, tente novamente.',
  title = 'Ocorreu um erro',
  code,
  actionTitle = 'Entendi',
  actionCallback,
  block,
  dispatch,
}: Props) => {
  const [open, setOpen] = useState(showError)

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    setOpen(showError)
  }, [showError, setOpen])

  return (
    <Dialog
      onClose={
        !block
          ? () => {
              setOpen(false)
              setTimeout(() => {
                dispatch(clearError())
              }, 600)
            }
          : () => null
      }
      title={title}
      open={open}
    >
      <P margin={code ? 'unset' : '0 0 15px 0;'}>{message}</P>
      {code && <P margin="0 0 15px 0;">{`COD (${code})`}</P>}

      <InterUIButton
        onClick={() => {
          setOpen(false)
          setTimeout(() => {
            dispatch(clearError())
            if (actionCallback instanceof Function) {
              dispatch(actionCallback())
            }
          }, 600)
        }}
      >
        {actionTitle}
      </InterUIButton>
    </Dialog>
  )
}

export default connector(ErrorMessage)
