import { BaseServices, HttpMethod } from '@services/base'

export default (data: ConsultaMargemCipRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_CONSULTA_MARGEM_CIP
    : process.env.REACT_APP_CONSULTA_MARGEM_CIP_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ConsultaMargemCipResponse>({
    pathname,
    method,
    host,
    data,
  })

  return response
}

export interface ConsultaMargemCipRequest {
  cpf: string
  matricula: string
  convenio: number
  orgao: number
}

export interface ConsultaMargemCipResponse {
  readonly margem: number
  readonly tentativas: number
  readonly pendencia: string
}
