import React, { lazy, Suspense } from 'react'

import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import FallbackPage from '@components/organims/FallbackPage'
import { caching } from '@utils/functions'
import { ApplicationState } from '@store/types'

import routes from './routes'

const ConsultaMargemCip = lazy(
  () => import('@components/pages/SimuladorNovo/Consultas/ConsultaMargemCip'),
)
const DadosRecebidos = lazy(() => import('@components/pages/SimuladorNovo/DadosRecebidos'))
const ResumoEmprestimo = lazy(() => import('@components/pages/SimuladorNovo/ResumoEmprestimo'))
const VerificarValorSimulado = lazy(
  () => import('@components/pages/SimuladorNovo/VerificarValorSimulado'),
)
const OfertaPortabilidade = lazy(
  () => import('@components/pages/SimuladorNovo/OfertaPortabilidade'),
)
const NegativaParceria = lazy(() => import('@components/pages/SimuladorNovo/NegativaParceria'))
const Indicacao = lazy(() => import('@components/pages/SimuladorNovo/Indicacao'))
const ConsultaMargemExercito = lazy(
  () => import('@components/pages/SimuladorNovo/Consultas/ConsultaMargemExercito'),
)
const ConsultaMargemExercitoAverbacao = lazy(
  () =>
    import(
      '@components/pages/SimuladorNovo/Consultas/ConsultaMargemExercito/ExercitoSenhaAverbacao'
    ),
)
const ConsultaMargemRio = lazy(
  () => import('@components/pages/SimuladorNovo/Consultas/ConsultaMargemRio'),
)

const ConsultaMargemRioDadosMatricula = lazy(
  () => import('@components/pages/SimuladorNovo/Consultas/ConsultaMargemRio/DadosMatricula'),
)

const ConsultaMargemRioAverbacao = lazy(
  () => import('@components/pages/SimuladorNovo/Consultas/ConsultaMargemRio/RioSenhaAverbacao'),
)
const GrupoForcasArmadas = lazy(() => import('@pages/SimuladorNovo/Grupos/ForcasArmadas'))
const GrupoForcasArmadasFontePagamento = lazy(
  () => import('@pages/SimuladorNovo/Grupos/ForcasArmadas/FontePagamento'),
)
const GrupoForcasArmadasSituacaoFuncional = lazy(
  () => import('@pages/SimuladorNovo/Grupos/ForcasArmadas/SituacaoFuncional'),
)
const GrupoTribunais = lazy(() => import('@pages/SimuladorNovo/Grupos/Tribunais'))
const GrupoEstadual = lazy(() => import('@pages/SimuladorNovo/Grupos/Estadual'))
const SelecaoGrupo = lazy(() => import('@pages/SimuladorNovo/SelecaoGrupo'))
const Token = lazy(() => import('@pages/SimuladorNovo/Token'))
const Contato = lazy(() => import('@pages/SimuladorNovo/Contato'))
const NaoLiberado = lazy(() => import('@pages/SimuladorNovo/NaoLiberado'))
const DadosPessoais = lazy(() => import('@pages/SimuladorNovo/DadosPessoais'))
const Calculadora = lazy(() => import('@pages/SimuladorNovo/Calculadora'))
const Onboarding = lazy(() => import('@pages/SimuladorNovo/Onboarding'))
const GrupoAutonomo = lazy(() => import('@pages/SimuladorNovo/Grupos/Autonomo'))
const RootPage = lazy(() => import('@components/pages/Privado/RootPage'))

const Routes: React.FC = () => {
  const sessionStore = useSelector((state: ApplicationState) => state.session)

  caching(sessionStore)
  return (
    <Switch>
      <Suspense fallback={<FallbackPage />}>
        <Route exact path={routes.DADOS_RECEBIDOS} component={DadosRecebidos} />
        <Route exact path={routes.RESUMO_EMPRESTIMO} component={ResumoEmprestimo} />
        <Route exact path={routes.VERIFICAR_VALOR_SIMULADO} component={VerificarValorSimulado} />
        <Route exact path={routes.OFERTA_PORTABILIDADE} component={OfertaPortabilidade} />
        <Route exact path={routes.NEGATIVA_PARCERIA} component={NegativaParceria} />
        <Route exact path={routes.INDICACAO} component={Indicacao} />
        <Route exact path={routes.CONSULTA_MARGEM_EXERCITO} component={ConsultaMargemExercito} />
        <Route exact path={routes.CONSULTA_MARGEM_CIP} component={ConsultaMargemCip} />
        <Route
          exact
          path={routes.CONSULTA_MARGEM_EXERCITO_AVERBACAO}
          component={ConsultaMargemExercitoAverbacao}
        />
        <Route
          exact
          path={routes.GRUPO_FORCAS_ARMADAS_FONTE_PAGAMENTO}
          component={GrupoForcasArmadasFontePagamento}
        />
        <Route
          exact
          path={routes.GRUPO_FORCAS_ARMADAS_SITUACAO_FUNCIONAL}
          component={GrupoForcasArmadasSituacaoFuncional}
        />
        <Route exact path={routes.GRUPO_FORCAS_ARMADAS} component={GrupoForcasArmadas} />
        <Route exact path={routes.CONSULTA_MARGEM_RIO} component={ConsultaMargemRio} />
        <Route
          exact
          path={routes.CONSULTA_MARGEM_RIO_MATRICULA}
          component={ConsultaMargemRioDadosMatricula}
        />
        <Route
          exact
          path={routes.CONSULTA_MARGEM_RIO_AVERBACAO}
          component={ConsultaMargemRioAverbacao}
        />
        <Route exact path={routes.GRUPO_TRIBUNAIS} component={GrupoTribunais} />
        <Route exact path={routes.GRUPO_ESTADUAL} component={GrupoEstadual} />
        <Route exact path={routes.SELECAO_GRUPO} component={SelecaoGrupo} />
        <Route exact path={routes.TOKEN} component={Token} />
        <Route exact path={routes.CONTATO} component={Contato} />
        <Route exact path={routes.NAO_LIBERADO} component={NaoLiberado} />
        <Route exact path={routes.DADOS_PESSOAIS} component={DadosPessoais} />
        <Route exact path={routes.CALCULADORA} component={Calculadora} />
        <Route exact path={routes.ONBOARDING} component={Onboarding} />
        <Route exact path={routes.GRUPO_AUTONOMO} component={GrupoAutonomo} />
        <Route exact path={routes.ROOT} component={RootPage} />
      </Suspense>
    </Switch>
  )
}
export default Routes
