import { Reducer } from 'redux'

import { GlobalApiState, GlobalApiTypes } from './types'

const INITIAL_STATE: GlobalApiState = {
  fontesPagamento: [],
  fontesPagamentoError: false,
  fontesPagamentoLoading: false,

  estados: [],
  estadosError: false,
  estadosLoading: false,

  cidades: [],
  cidadesError: false,
  cidadesLoading: false,

  bancos: [],
  bancosError: false,
  bancosLoading: false,

  upags: [],
  upagsError: false,
  upagsLoading: false,

  propostas: [],
  propostasError: false,
  propostasLoading: false,

  contratosAtivos: [],
  contratosAtivosError: false,
  contratosAtivosLoading: false,
}

const reducer: Reducer<GlobalApiState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Fontes de Pagamento
    case GlobalApiTypes.FONTES_SUCCESS:
      return {
        ...state,
        fontesPagamentoError: false,
        fontesPagamentoLoading: false,
        fontesPagamento: action.payload,
      }
    case GlobalApiTypes.FONTES_LOADING:
      return {
        ...state,
        fontesPagamentoError: false,
        fontesPagamentoLoading: true,
      }
    case GlobalApiTypes.FONTES_ERROR:
      return {
        ...state,
        fontesPagamentoLoading: false,
        fontesPagamentoError: true,
      }

    // Estados
    case GlobalApiTypes.ESTADOS_SUCCESS:
      return {
        ...state,
        estadosError: false,
        estadosLoading: false,
        estados: action.payload,
      }
    case GlobalApiTypes.ESTADOS_LOADING:
      return { ...state, estadosError: false, estadosLoading: true }
    case GlobalApiTypes.ESTADOS_ERROR:
      return { ...state, estadosLoading: false, estadosError: true }

    // Cidades
    case GlobalApiTypes.CIDADES_SUCCESS:
      return {
        ...state,
        cidadesError: false,
        cidadesLoading: false,
        cidades: action.payload,
      }
    case GlobalApiTypes.CIDADES_LOADING:
      return { ...state, cidadesError: false, cidadesLoading: true }
    case GlobalApiTypes.CIDADES_ERROR:
      return { ...state, cidadesLoading: false, cidadesError: true }

    // Upags
    case GlobalApiTypes.UPAGS_SUCCESS:
      return {
        ...state,
        upagsError: false,
        upagsLoading: false,
        upags: action.payload,
      }
    case GlobalApiTypes.UPAGS_LOADING:
      return { ...state, upagsError: false, upagsLoading: true }
    case GlobalApiTypes.UPAGS_ERROR:
      return { ...state, upagsLoading: false, upagsError: true }

    // Propostas
    case GlobalApiTypes.PROPOSTAS_SUCCESS:
      return {
        ...state,
        propostasError: false,
        propostasLoading: false,
        propostas: action.payload,
      }
    case GlobalApiTypes.PROPOSTAS_LOADING:
      return {
        ...state,
        propostasError: false,
        propostasLoading: true,
      }
    case GlobalApiTypes.PROPOSTAS_ERROR:
      return {
        ...state,
        propostasLoading: false,
        propostasError: true,
      }

    // Contratos
    case GlobalApiTypes.CONTRATOS_ATIVOS_SUCCESS:
      return {
        ...state,
        contratosAtivosError: false,
        contratosAtivosLoading: false,
        contratosAtivos: action.payload,
      }
    case GlobalApiTypes.CONTRATOS_ATIVOS_LOADING:
      return {
        ...state,
        contratosAtivosError: false,
        contratosAtivosLoading: true,
      }
    case GlobalApiTypes.CONTRATOS_ATIVOS_ERROR:
      return {
        ...state,
        contratosAtivosLoading: false,
        contratosAtivosError: true,
      }

    case GlobalApiTypes.RESET:
      return { ...INITIAL_STATE }

    // Default
    default:
      return state
  }
}

export default reducer
