import React, { useState } from 'react'

import ArrowRight from '@interco/icons/orangeds/MD/arrow-right'
import ArrowLeft from '@interco/icons/orangeds/MD/arrow-left'
import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'

import { ContainerSlide, FooterLine, HeaderLine, Line, LineDiv, OrangeButton } from './styles'

interface Props {
  setOpen(isOpen: boolean): void
  title: string
  templatesArray: JSX.Element[]
}

const SliderTutorial = ({ setOpen, title, templatesArray }: Props) => {
  const [actualSlider, setActualSlider] = useState(0)

  const verifyClick = (direction: string) => {
    if (direction === 'left') {
      if (actualSlider > 0) {
        setActualSlider(actualSlider - 1)
      }
    } else if (actualSlider < templatesArray.length - 1) {
      setActualSlider(actualSlider + 1)
    }
  }
  return (
    <>
      <HeaderLine>
        <Paragraph
          fontFamily="Sora"
          fontSize="16px"
          fontWeight={600}
          lineHeight="20px"
          color={Colors.GRAY500}
        >
          {title}
        </Paragraph>
        <OrangeButton onClick={() => setOpen(false)}>fechar</OrangeButton>
      </HeaderLine>

      <ContainerSlide>
        {templatesArray.map((template, i) => {
          if (actualSlider === i) {
            return template
          }
          return null
        })}
      </ContainerSlide>

      <FooterLine>
        <ArrowLeft
          width={24}
          height={24}
          onClick={() => verifyClick('left')}
          color={actualSlider === 0 ? Colors.GRAY200 : Colors.PRIMARY500}
        />
        <LineDiv>
          {Array.from(Array(templatesArray.length), (v, i) => (
            <Line actived={actualSlider === i} key={`line-${i}`} />
          ))}
        </LineDiv>
        <ArrowRight
          width={24}
          height={24}
          onClick={() => verifyClick('right')}
          color={actualSlider === templatesArray.length - 1 ? Colors.GRAY200 : Colors.PRIMARY500}
        />
      </FooterLine>
    </>
  )
}

export default SliderTutorial
