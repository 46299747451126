import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import postIndicacaoWhatsapp from '@services/postIndicacaoWhatsapp'

import { postAtendimento, setLoadingAtendimento } from '../actions'

export default function* postAtendimentoSaga({ payload }: AnyAction) {
  const { nome, cpf } = yield* select((state: ApplicationState) => state.novo.dadosPessoais)
  const { telefone } = yield* select((state: ApplicationState) => state.novo.codIndicacaoContato)
  const { inssViaUrl, convenioSelecionado } = yield* select(
    (state: ApplicationState) => state.novo.fontePagamento,
  )
  try {
    yield put(setLoadingAtendimento(true))

    yield call(postIndicacaoWhatsapp, {
      name: nome,
      socialId: cpf,
      number: `55${telefone}`,
      queue:
        inssViaUrl || convenioSelecionado === 6
          ? 'DS_WAP_CONSIGNADO_URA_RECEPT_INSS'
          : 'DS_WAP_CONSIGNADO_URA_RECEPT',
      manager: 'BI008390',
      description: '',
      interNumber: process.env.REACT_APP_INTER_NUMBER_WHATSAPP as string,
      origin: 'simulador-consignado',
    })

    yield payload.callback(true)
    yield put(setLoadingAtendimento(false))
  } catch (error) {
    yield payload.callback(true)
    showError({
      title: 'Erro ao enviar forma de contato',
      message: 'Ocorreu um erro ao enviar a forma de contato.',
      actionTitle: 'Tentar Novamente',
      code: ErrorCodes.NO_0011_POST_INDICACAO_WHATSAPP,
      actionCallback: () => postAtendimento(payload),
    })
    yield put(setLoadingAtendimento(false))
  }
}
