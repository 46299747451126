import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { ApplicationState } from '@store/types'
import { buildCidadesNovo } from '@utils/simuladorUtils'
import { Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet/types'
import Select from '@atoms/SelectNovo'

import { CustomBottomSheet } from './styles'

interface OwnProps {
  id: string
  cidadeSelecionada: Option
  isDisabled: boolean
  onChange: (value: Option) => void
  customLabel?: string
}

const CidadeDropdownNovo = ({
  id,
  cidadeSelecionada,
  isDisabled,
  onChange,
  customLabel,
}: OwnProps) => {
  const { cidades = [], cidadesLoading } = useSelector((state: ApplicationState) => state.globalApi)

  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp) {
    return (
      <Select
        label={customLabel || 'De qual cidade?'}
        margin="0 0 20px 0"
        name="dropdown"
        value={cidadeSelecionada}
        hideSearch={false}
        options={buildCidadesNovo(cidades)}
        placeholder="Selecione a cidade"
        onChange={(value) => {
          onChange(value)
        }}
        disabled={isDisabled || cidadesLoading}
        loading={cidadesLoading}
      />
    )
  }

  return (
    <CustomBottomSheet
      id={id}
      label={customLabel || 'De qual cidade?'}
      name="dropdown"
      value={cidadeSelecionada}
      onChange={(value) => {
        onChange(value)
      }}
      options={buildCidadesNovo(cidades)}
      placeholder="Selecione a cidade"
      disabled={isDisabled || cidadesLoading}
      isLoading={cidadesLoading}
      isUsingFilter
    />
  )
}

export default memo(CidadeDropdownNovo)
