import React from 'react'

import { SmallButton } from '@atoms'
import { ParcelaValores, ParcelasTypes, ENUM_PARCELAS, FamiliaType } from '@utils/simuladorUtils'

import { GridButton } from './styles'

interface Props {
  height?: number | string
  margin?: string
  times: ParcelasTypes
  familia: FamiliaType
  handleClickParcelas: (i: ParcelasTypes) => void
}

const isSelected = (times: ParcelasTypes, value: ParcelasTypes) => {
  const numTimes = times || ParcelaValores.THIRTY_SIX
  return numTimes === value
}

const ButtonGroupParcelas = ({ height, margin, handleClickParcelas, times, familia }: Props) => (
  <GridButton height={height} margin={margin} familia={familia}>
    {ENUM_PARCELAS[familia].map((item) => (
      <div key={item as ParcelasTypes}>
        <SmallButton
          onClickButton={handleClickParcelas}
          active={isSelected(times, item as ParcelasTypes)}
          value={item as ParcelasTypes}
        >
          {item}
        </SmallButton>
      </div>
    ))}
  </GridButton>
)

ButtonGroupParcelas.defaultProps = {
  height: 'unset',
  margin: '0',
}

export default ButtonGroupParcelas
