import { BaseServices, HttpMethod } from '@services/base'

export default (data: ConsultaMargemSiapeApiRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_CONSULTA_MARGEM_SIAPE
    : process.env.REACT_APP_CONSULTA_MARGEM_SIAPE_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ResponseConsultarMargemSiape>({
    pathname,
    method,
    host,
    data,
  })

  return response
}

export interface ConsultaMargemSiapeApiRequest {
  cpf: string
  matricula: string
}

export interface ResponseConsultarMargemSiape {
  tentativas: number
  pendencia: string
  margem: number
}
