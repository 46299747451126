/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import { isMobile } from 'react-device-detect'

import { InterUIBottomSheet } from '@interco/inter-ui-react-lib'
import { Dialog as MuiDialog, makeStyles } from '@material-ui/core'

import { DialogTitleStyled, DialogContentStyled } from './styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string
  open: boolean
  onClose: () => void
}

const styles = makeStyles({
  variants: {
    maxWidth: '500px',
    minWidth: 'calc(30%)',
  },
  contratosSelecionados: {
    maxWidth: '450px',
    minWidth: 'calc(15%)',
  },
})

const Dialog = ({ title, open, className, children, onClose, ...props }: Props) => {
  const classes = styles()

  return isMobile ? (
    <InterUIBottomSheet
      onHide={onClose}
      title={title || ''}
      toggle={open}
      className={className}
      {...props}
    >
      {children}
    </InterUIBottomSheet>
  ) : (
    <MuiDialog
      open={open}
      onClose={onClose}
      classes={{
        paperScrollPaper: title?.includes('Contratos desse refinanciamento')
          ? classes.contratosSelecionados
          : classes.variants,
      }}
    >
      {title && <DialogTitleStyled>{title}</DialogTitleStyled>}
      <DialogContentStyled>{children}</DialogContentStyled>
    </MuiDialog>
  )
}

Dialog.defaultProps = {
  title: undefined,
}

export default Dialog
