import { call, put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import getParcelaSimulacaoSangueLaranja from '@services/getParcelaSimulacaoSangueLaranja'
import { SimulacoesSangueLaranjaRequest } from '@services/postSimulacaoNovo'
import { setMatricula } from '@store/novo/situacaoFuncional/actions'
import { TipoEmprestimo } from '@utils/enums'

import {
  getParcelaSangueLaranjaError,
  getParcelaSangueLaranjaSuccess,
  selectSimulacao,
  selectSimulacoes,
  setIdSimulacao,
  setParcelaSelecionada,
  setTotalParcelas,
} from '../actions'
import { ParcelaSimulacaoSangueLaranjaPayload, Simulacao } from '../types'

const mapObjetoSimulacao = (
  objSimulacaoRequest: SimulacoesSangueLaranjaRequest | undefined,
): Simulacao => {
  if (!objSimulacaoRequest) {
    return {} as Simulacao
  }

  return {
    ...objSimulacaoRequest,
    cETAa: objSimulacaoRequest.taxaCetAa,
    tIRAm: objSimulacaoRequest.taxaTirAm,
    tIRAa: objSimulacaoRequest.taxaTirAa,
    cETAm: objSimulacaoRequest.taxaCetAm,
  }
}

export default function* getParcelaSimulacaoSangueLaranjaSaga(action: AnyAction) {
  const payload = action.payload as ParcelaSimulacaoSangueLaranjaPayload

  try {
    const { data } = yield* call(getParcelaSimulacaoSangueLaranja, payload.codigoProposta)

    if (data) {
      const tipoEmprestimo = data.possuiSeguro
        ? TipoEmprestimo.CREDITO_PROTEGIDO_ARRAY
        : TipoEmprestimo.SEM_CREDITO_PROTEGIDO_ARRAY

      yield put(selectSimulacoes(tipoEmprestimo))
      yield put(selectSimulacao(tipoEmprestimo))

      yield put(setMatricula(data?.matricula))
      const parcelaMapped = mapObjetoSimulacao(data)
      yield put(setParcelaSelecionada(parcelaMapped))
      yield put(setTotalParcelas(parcelaMapped.qtdeParcelas))
      yield put(getParcelaSangueLaranjaSuccess())
      yield put(setIdSimulacao(payload.codigoProposta))
      yield payload.callback()
    }
  } catch {
    yield put(getParcelaSangueLaranjaError())
  }
}
