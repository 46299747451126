import { ApplicationState } from '@store/types'
import { datePtToEn } from '@utils/simuladorUtils'
import { VerificaPendenciaLeadRequest } from '@services/postVerificaPendenciaLead'
import { removeMask } from '@utils/masks'
import { VerificaPendenciaPortabilidadeRequest } from '@services/postVerificaPendenciaPortabilidade'

export const buildVerificaPendenciaLeadRequest = ({
  globalApi,
  ui,
  session,
  novo,
}: ApplicationState): VerificaPendenciaLeadRequest => ({
  dataNascimento: datePtToEn(novo.dadosPessoais.dataNascimento) || '',
  cpf: novo.dadosPessoais.cpf || '',
  email: novo.codIndicacaoContato.email,
  primeiroNome: novo.dadosPessoais.nome.split(' ').slice(0, 1).join(' ') || '',
  segundoNome:
    novo.dadosPessoais.nome.split(' ').slice(1, novo.dadosPessoais.nome.length).join(' ') || '',
  utmSource: String(session.utmSource) || '',
  utmMedium: String(session.utmMedium) || '',
  utmContent: String(session.utmContent) || '',
  utmCampaign: String(session.utmCampaign) || '',
  clienteId: session.sessionId || '',
  gaClientId: String(session.gaClientId) || '',
  gaClid: String(session.gaClid) || '',
  facebookId: String(session.facebookId) || '',
  upag: String(novo.situacaoFuncional.upag || ''),
  matricula: removeMask(String(novo.situacaoFuncional.matricula) || ''),
  origem: ui.navigation.origem,
  familiaId: novo.fontePagamento.fonteSelecionada || 0,
  telefoneCelular: novo.codIndicacaoContato.telefone,
  salarioBruto: novo.dadosProfissionais.salario || 0,
  valorEconomia: 0,
  saldoDevedor: 0,
  valorParcelaNovo:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.valorParcela || 0
      : novo.simulacao.simulacao.valorParcela || 0,
  taxaNominalMensal:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.taxaNominalAm || 0
      : novo.simulacao.simulacao.taxaNominalAm || 0,
  taxaNominalMensalNovo:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.taxaNominalAm || 0
      : novo.simulacao.simulacao.taxaNominalAm || 0,
  valorSolicitado:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.valorSolicitado || 0
      : novo.simulacao.simulacao.valorSolicitado || 0,
  valorFinanciado:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.valorFinanciado || 0
      : novo.simulacao.simulacao.valorFinanciado || 0,
  valorParcela:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.valorParcela || 0
      : novo.simulacao.simulacao.valorParcela || 0,
  bancoContratoAtual: '',
  fontePagamento: String(novo.fontePagamento.fonteSelecionada),
  convenioDesc:
    novo.fontePagamento.convenios?.find(
      (convenio) => convenio.codigo === Number(novo.fontePagamento.convenioSelecionado),
    )?.descricao || '',
  numeroContrato: '',
  situacaoFuncional: '',
  convenioEstado: String(novo.fontePagamento.estadoSelecionado) || '',
  convenioCidade:
    globalApi.cidades?.find(
      (cidade) => cidade.codigo === String(novo.fontePagamento.cidadeSelecionada),
    )?.nome || '',
  orgaoDescricao:
    novo.fontePagamento.orgaos?.find(
      (orgao) => orgao.codigo === Number(novo.fontePagamento.orgaoSelecionado),
    )?.descricao || '',
  familiaDescricao:
    globalApi.fontesPagamento?.find(
      (fonte) => fonte.codigo === Number(novo.fontePagamento.fonteSelecionada),
    )?.descricao || '',
  formaContato: '',
  patente: '',
  indicativo: '',
  dataIngressoExercito: '',
  convenioId: Number(novo.fontePagamento.convenioSelecionado) || 0,
  orgaoId: Number(novo.fontePagamento.orgaoSelecionado) || 0,
  quantidadeParcelasFalta: 0,
  pendencia: novo.pendencia.pendencia,
  origemSimulacao: 'SIMULADOR_NOVO',
})
export const buildVerificaPendenciaPortabilidadeRequest = ({
  globalApi,
  ui,
  session,
  novo,
}: ApplicationState): VerificaPendenciaPortabilidadeRequest => ({
  dataNascimento: datePtToEn(novo.dadosPessoais.dataNascimento) || '',
  cpf: novo.dadosPessoais.cpf || '',
  email: novo.codIndicacaoContato.email,
  primeiroNome: novo.dadosPessoais.nome.split(' ').slice(0, 1).join(' ') || '',
  segundoNome:
    novo.dadosPessoais.nome.split(' ').slice(1, novo.dadosPessoais.nome.length).join(' ') || '',
  utmSource: String(session.utmSource) || '',
  utmMedium: String(session.utmMedium) || '',
  utmContent: String(session.utmContent) || '',
  utmCampaign: String(session.utmCampaign) || '',
  clienteId: session.sessionId || '',
  gaClientId: String(session.gaClientId) || '',
  gaClid: String(session.gaClid) || '',
  facebookId: String(session.facebookId) || '',
  upag: String(novo.situacaoFuncional.upag || ''),
  matricula: removeMask(String(novo.situacaoFuncional.matricula) || ''),
  origem: ui.navigation.origem,
  familiaId: novo.fontePagamento.fonteSelecionada || 0,
  telefoneCelular: novo.codIndicacaoContato.telefone,
  salarioBruto: novo.dadosProfissionais.salario || 0,
  valorEconomia: 0,
  saldoDevedor: 0,
  valorParcelaNovo:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.valorParcela || 0
      : novo.simulacao.simulacao.valorParcela || 0,
  taxaNominalMensal:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.taxaNominalAm || 0
      : novo.simulacao.simulacao.taxaNominalAm || 0,
  taxaNominalMensalNovo:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.taxaNominalAm || 0
      : novo.simulacao.simulacao.taxaNominalAm || 0,
  valorSolicitado:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.valorSolicitado || 0
      : novo.simulacao.simulacao.valorSolicitado || 0,
  valorFinanciado:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.valorFinanciado || 0
      : novo.simulacao.simulacao.valorFinanciado || 0,
  valorParcela:
    novo.simulacao.simulacaoSelecionada === 'simulacaoComSeguro'
      ? novo.simulacao.simulacaoComSeguro.valorParcela || 0
      : novo.simulacao.simulacao.valorParcela || 0,
  bancoContratoAtual: '',
  fontePagamento: String(novo.fontePagamento.fonteSelecionada),
  convenioDesc:
    novo.fontePagamento.convenios?.find(
      (convenio) => convenio.codigo === Number(novo.fontePagamento.convenioSelecionado),
    )?.descricao || '',
  numeroContrato: '',
  situacaoFuncional: '',
  convenioEstado: String(novo.fontePagamento.estadoSelecionado) || '',
  convenioCidade:
    globalApi.cidades?.find(
      (cidade) => cidade.codigo === String(novo.fontePagamento.cidadeSelecionada),
    )?.nome || '',
  orgaoDescricao:
    novo.fontePagamento.orgaos?.find(
      (orgao) => orgao.codigo === Number(novo.fontePagamento.orgaoSelecionado),
    )?.descricao || '',
  familiaDescricao:
    globalApi.fontesPagamento?.find(
      (fonte) => fonte.codigo === Number(novo.fontePagamento.fonteSelecionada),
    )?.descricao || '',
  formaContato: '',
  patente: '',
  indicativo: '',
  dataIngressoExercito: '',
  convenioId: Number(novo.fontePagamento.convenioSelecionado) || 0,
  orgaoId: Number(novo.fontePagamento.orgaoSelecionado) || 0,
  quantidadeParcelasFalta: 0,
  pendencia: novo.pendencia.pendencia,
  observacao: '',
  origemSimulacao: 'SIMULADOR_NOVO',
})
