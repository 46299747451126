import { BaseServices, HttpMethod } from '@services/base'

export default (codigoProposta: string) => {
  const pathname = process.env.REACT_APP_SIMULACAO_SANGUE_LARANJA_SALVAR_BFF
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers = {
    codigoProposta,
  }

  return BaseServices.request<ResponseDadosUsuarioPropostaSangueLaranja>({
    headers,
    pathname,
    method,
    host,
  })
}

export interface ResponseDadosUsuarioPropostaSangueLaranja {
  documento: string
  endereco: string
  naturalidade: string
  nomeMae: string
  nomePai: string
  nomeConjuge: string
  dataNascimento: string
}
