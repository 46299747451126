import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { trackingSimulacao } from '@store/novo/simulacao/actions'
import { StepNRNovo } from '@utils/enums'

const useNewRelicTracker = (step: StepNRNovo) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(trackingSimulacao(step))
  }, [step, dispatch])
}

export default useNewRelicTracker
