import React from 'react'

import { FontFamily } from '@interco/cp-react-ui-lib'
import { createTheme, ThemeProvider, StylesProvider } from '@material-ui/core/styles'
import { Colors } from '@utils/Colors'

const theme = createTheme({
  palette: {
    background: {
      default: Colors.WHITE,
    },
    primary: {
      light: Colors.PRIMARY500,
      main: Colors.PRIMARY500,
      dark: Colors.PRIMARY500,
      contrastText: Colors.WHITE,
    },
    secondary: {
      light: Colors.PRIMARY500,
      main: Colors.PRIMARY500,
      dark: Colors.PRIMARY500,
      contrastText: Colors.GRAY500,
    },
  },
  typography: {
    fontFamily: FontFamily.Inter,
  },
})

type Props = {
  children: React.ReactNode
}

const Theme = ({ children }: Props) => (
  <StylesProvider injectFirst>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </StylesProvider>
)

export default Theme
