import React from 'react'

import { isMobile } from 'react-device-detect'

import { IInterUIDropdownProps } from '@interco/inter-ui-react-lib/dist/interfaces/inter-ui-dropdown-props'
import { InterUIDropdown } from '@interco/inter-ui-react-lib'

import Select from './Select'

const Dropdown = ({
  name,
  label,
  labelOptions,
  disabled,
  placeholder,
  value,
  options,
  margin,
  hideSearch,
  contentHeight,
  loading,
  showCloseLink,
  helperButton,
  onFilter,
  onChange,
  onClickHelper,
}: IInterUIDropdownProps) =>
  isMobile ? (
    <InterUIDropdown
      contentHeight={contentHeight}
      label={label}
      labelOptions={labelOptions}
      margin={margin}
      name={name}
      hideSearch={hideSearch}
      value={value}
      showCloseLink={showCloseLink}
      options={options}
      placeholder={placeholder}
      helperButton={helperButton}
      onChange={onChange}
      onFilter={onFilter}
      onClickHelper={onClickHelper}
      disabled={disabled}
      loading={loading}
    />
  ) : (
    <Select
      label={label}
      margin={margin}
      name={name}
      value={value}
      hideSearch={hideSearch}
      options={options}
      placeholder={placeholder}
      helperButton={helperButton}
      onFilter={onFilter}
      onChange={onChange}
      onClickHelper={onClickHelper}
      disabled={disabled}
      loading={loading}
    />
  )

export default Dropdown
