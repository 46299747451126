import React, { useState, useEffect } from 'react'

import { useSelector } from 'react-redux'

import { NovoPage } from '@components/templates'
import { Button } from '@interco/inter-ui/components/Button'
import GridStyled from '@atoms/GridStyled'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { ErrorIcon } from '@assets/icons/Signals'
import { ApplicationState } from '@store/types'

type Props = {
  children: React.ReactNode
}
function ErrorBoundary({ children }: Props) {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const errorHandler = () => {
      setHasError(true)
    }

    window.addEventListener('error', errorHandler)
    return () => {
      window.removeEventListener('error', errorHandler)
    }
  }, [])

  if (hasError) {
    return (
      <NovoPage
        id="erro-sistemico"
        stickyFooter={
          <>
            <Button
              variant="primary"
              onClick={() => {
                window.location.href = isApp
                  ? process.env.REACT_APP_HOME_EMPRESTIMO || ''
                  : process.env.REACT_APP_HOME_SIMULADOR_NOVO || ''
              }}
            >
              Voltar para home
            </Button>
          </>
        }
      >
        <GridStyled justify="space-around" margin="40px 0 24px 0">
          <ErrorIcon />
        </GridStyled>
        <ColorWrapper fontWeight="600" style={{ marginBottom: '8px', textAlign: 'center' }}>
          <TextParagraph variant="headline-h1" textAlign="center">
            Encontramos um erro
          </TextParagraph>
        </ColorWrapper>

        <TextParagraph variant="body-3" textAlign="center">
          Mas fique tranquilo, estamos resolvendo e logo mais você poderá dar continuidade no
          processo de contratação.
        </TextParagraph>
      </NovoPage>
    )
  }

  return <>{children}</>
}

export default ErrorBoundary
