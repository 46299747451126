import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { removeMask } from '@utils/masks'
import { ApplicationState } from '@store/types'
import {
  salvarLeadSuccess,
  salvarLeadError,
  salvarLeadSangueLaranjaRequest,
  setIdSimulacao,
} from '@store/novo/simulacao/actions'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import services from '@services'
import { setLoading } from '@store/novo/valida/actions'
import {
  SalvarSimulacaoSangueLaranjaRequest,
  ResponseSalvarSimulacaoSangueLaranja,
} from '@services/postSalvarSimulacaoSangueLaranja'

import { SalvarLeadSangueLaranjaRequestPayload } from '../types'

export default function* postSalvarLeadSangueLaranja(action: AnyAction) {
  const payload = action.payload as SalvarLeadSangueLaranjaRequestPayload
  try {
    const { novo } = yield* select((s: ApplicationState) => s)

    const simulationData = novo?.simulacao.parcelaAtual

    const bodySalvarSimulacao: SalvarSimulacaoSangueLaranjaRequest = {
      cpf: removeMask(novo.dadosPessoais?.cpf) || '',
      email: novo.codIndicacaoContato?.email || '',
      telefone: removeMask(novo.codIndicacaoContato?.telefone) || '',
      matricula: String(novo.situacaoFuncional.matricula) || '',
      qtdeParcelas: simulationData?.qtdeParcelas,
      valorSolicitado: simulationData?.valorSolicitado,
      valorParcela: simulationData?.valorParcela,
      valorLiberado: simulationData?.valorLiberado,
      valorIOF: simulationData?.valorIOF,
      valorIOFPercentual: simulationData?.valorIOFPercentual,
      valorSeguro: simulationData?.valorSeguro,
      valorSeguroPercentual: simulationData?.valorSeguroPercentual,
      tarifaCadastro: simulationData?.tarifaCadastro,
      tarifaCadastroPercentual: simulationData?.tarifaCadastroPercentual,
      valorFinanciado: simulationData?.valorFinanciado,
      txIRAm: simulationData?.tIRAm,
      txCETAa: simulationData?.cETAa,
      txCETAm: simulationData?.cETAm,
      txIRAa: simulationData?.tIRAa,
      taxaNominalAm: simulationData?.taxaNominalAm,
      taxaNomialAa: simulationData?.taxaNomialAa,
      dataUltimoVencimento: simulationData?.dataUltimoVencimento,
      dataPrimeiroVencimento: simulationData?.dataPrimeiroVencimento,
      jurosContrados: simulationData?.jurosContrados,
      jurosContradosPercentual: simulationData?.jurosContradosPercentual,
      valorTotalASerPago: simulationData?.valorTotalASerPago,
    }

    const response: ResponseSalvarSimulacaoSangueLaranja =
      yield services.postSalvarSimulacaoSangueLaranja(bodySalvarSimulacao)

    if (response.data.codigoProposta) {
      yield put(setIdSimulacao(response.data.codigoProposta))
    }

    yield put(salvarLeadSuccess())
    yield put(setLoading(false))
    yield payload.callback()
  } catch (error) {
    yield put(salvarLeadError())
    yield put(setLoading(false))
    yield put(
      showError({
        title: 'Erro ao salvar',
        message: 'Ocorreu um erro ao salvar suas informações',
        actionTitle: 'Tentar Novamente',
        block: true,
        code: ErrorCodes.N0_006_SALVAR_LEAD,
        actionCallback: () => salvarLeadSangueLaranjaRequest(payload),
      }),
    )
  }
}
