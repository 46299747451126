/**
 * Actions Types
 */

export enum AlertaTypes {
  SET_ABRIR_ALERTA = '@novo/alerta/SET_ABRIR_ALERTA',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface AlertaState {
  readonly isOpen: boolean
}
