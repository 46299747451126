import React, { ButtonHTMLAttributes } from 'react'

import { ParcelasTypes } from '@utils/simuladorUtils'

import { Button } from './styles'

interface OwnProps {
  active: boolean
  value: ParcelasTypes
  onClickButton: (i: ParcelasTypes) => void
}

type Props = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>

const SmallButton = ({ active = false, disabled, onClickButton, children, value }: Props) => (
  <Button type="button" isActive={active} onClick={() => onClickButton(value)} disabled={disabled}>
    {children}
  </Button>
)

export default SmallButton
