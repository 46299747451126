/**
 * Actions Types
 */

export enum PendenciaTypes {
  SET_PENDENCIA = '@novo/pendencia/SET_PENDENCIA',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface PendenciaState {
  readonly pendencia: string
}
