import { Reducer } from 'redux'

import { BeneficiarioInssTypes, BeneficiarioInssState } from './types'

const INITIAL_STATE: BeneficiarioInssState = {
  dadosBeneficiario: {
    isConsultaRealizada: false,
    isBeneficioValido: false,
    isHorarioValido: false,
    pendencia: '',
    infoInss: {
      tipoCredito: '',
      margem: 0,
      valorSalarioLiquido: 0,
      nome: '',
      dataNascimento: '',
      estado: '',
      numeroBeneficio: '',
      especieBeneficio: 0,
      situacaoBeneficio: '',
    },
  },
  dadosInssError: false,
  dadosInssLoading: false,
  redirectInss: false,
  simplificadaInss: false,
  checkAutorizacaoInss: false,
  scheduledSimulationId: undefined,
  loadingInssApis: false,
  counterRecall: 0,
}

const reducer: Reducer<BeneficiarioInssState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BeneficiarioInssTypes.DADOS_BENEFICIARIO_INSS_REQUEST:
      return { ...state, dadosInssError: false, dadosInssLoading: true }
    case BeneficiarioInssTypes.DADOS_INSS_RESUlT_REQUEST:
      return { ...state, dadosInssError: false, dadosInssLoading: true }
    case BeneficiarioInssTypes.DADOS_BENEFICIARIO_INSS_SUCCESS:
      return {
        ...state,
        dadosBeneficiario: action.payload,
        dadosInssError: false,
        dadosInssLoading: false,
      }
    case BeneficiarioInssTypes.DADOS_BENEFICIARIO_INSS_ERROR:
      return { ...state, dadosInssError: true, dadosInssLoading: false }
    case BeneficiarioInssTypes.SET_REDIRECT_INSS:
      return { ...state, redirectInss: action.payload }
    case BeneficiarioInssTypes.SET_SIMULACAO_SIMPLIFICADA_INSS:
      return { ...state, simplificadaInss: action.payload }
    case BeneficiarioInssTypes.SET_CHECK_AUTORIZACAO_INSS:
      return { ...state, checkAutorizacaoInss: action.payload }
    case BeneficiarioInssTypes.SET_SCHEDULE_SIMULATION_ID:
      return { ...state, scheduledSimulationId: action.payload }
    case BeneficiarioInssTypes.SET_LOADING_INSS_APIS:
      return { ...state, loadingInssApis: action.payload }
    case BeneficiarioInssTypes.SET_COUNTER_RECALL:
      return { ...state, counterRecall: state.counterRecall + action.payload }

    case BeneficiarioInssTypes.CLEAR_SITUACAOFUNCIONAL:
      return INITIAL_STATE
    case BeneficiarioInssTypes.RESET:
      return INITIAL_STATE
    default:
      return state
  }
}

export default reducer
