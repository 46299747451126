import { AnyAction } from 'redux'
import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { ValidaIndicacaoRequest } from '@services/getValidaIndicacao'
import { ApplicationState } from '@store/types'
import { CodigoValidacao } from '@utils/enums'

import { ValidaIndicacaoActionPayload } from '../types'
import { setValidacaoCodigoMgm, validaIndicacaoError, validaIndicacaoSuccess } from '../actions'

export default function* getValidaIndicacao(action: AnyAction) {
  const { codigo, success, error } = action.payload as ValidaIndicacaoActionPayload
  const { cpf } = yield select((state: ApplicationState) => state.novo.dadosPessoais)

  try {
    const request: ValidaIndicacaoRequest = {
      cpfUtilizador: cpf,
      codigo,
      campanha: process.env.REACT_APP_CAMPANHA_INDIQUE_E_GANHE || '',
    }
    const response = yield* call(services.getValidaIndicacao, request)

    if (response.data.valido) {
      yield put(validaIndicacaoSuccess())
      yield put(setValidacaoCodigoMgm(CodigoValidacao.VALIDO))
      if (success instanceof Function) {
        success()
      }
    } else {
      yield put(validaIndicacaoError())
      yield put(setValidacaoCodigoMgm(CodigoValidacao.INVALIDO))
      if (error instanceof Function) {
        error()
      }
    }
  } catch (e) {
    yield put(validaIndicacaoError())
    yield put(setValidacaoCodigoMgm(CodigoValidacao.INVALIDO))
    if (error instanceof Function) {
      error()
    }
  }
}
