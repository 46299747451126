import { action, Action } from 'typesafe-actions'

import { SiapeAutorizacaoAnuenciaEnum, TipoConsultaAutorizacaoSiape } from '@utils/enums'

import { AutorizacaoAnuenciaTypes, ConsultarMargemSiapePayload } from './types'
import { PayloadAtualizarCacheSiape } from './sagas/postAtualizarCacheSiapeSaga'
import { PayloadGetCacheSiape } from './sagas/getCacheSiapeSaga'

export const setAutorizacaoAnuencia = (payload: TipoConsultaAutorizacaoSiape): Action =>
  action(AutorizacaoAnuenciaTypes.SET_AUTORIZACAO_ANUENCIA, payload)

export const setAnuenciaAutorizada = (payload: boolean): Action =>
  action(AutorizacaoAnuenciaTypes.SET_ANUENCIA_AUTORIZADA, payload)

export const setCheckAnuenciaSiape = (payload: boolean): Action =>
  action(AutorizacaoAnuenciaTypes.SET_CHECK_ANUENCIA_SIAPE, payload)

export const setPagesSiapeAutorizacao = (payload: SiapeAutorizacaoAnuenciaEnum): Action =>
  action(AutorizacaoAnuenciaTypes.SET_PAGES_SIAPE_AUTORIZACAO, payload)

export const setPagesSiapeAutorizacaoNovo = (payload: SiapeAutorizacaoAnuenciaEnum): Action =>
  action(AutorizacaoAnuenciaTypes.SET_PAGES_SIAPE_AUTORIZACAO_NOVO, payload)

export const setTentativas = (payload: number): Action =>
  action(AutorizacaoAnuenciaTypes.SET_TENTATIVAS, payload)

export const atualizarCacheSiapeRequest = (payload: PayloadAtualizarCacheSiape): Action =>
  action(AutorizacaoAnuenciaTypes.ATUALIZAR_SIAPE_CACHE, payload)

export const getCacheSiapeRequest = (payload: PayloadGetCacheSiape): Action =>
  action(AutorizacaoAnuenciaTypes.GET_SIAPE_CACHE, payload)

export const consultarMargemSiapeRequest = (payload: ConsultarMargemSiapePayload): Action =>
  action(AutorizacaoAnuenciaTypes.CONSULTAR_MARGEM_SIAPE_REQUEST, payload)

export const setConsultaAutorizada = (payload: boolean): Action =>
  action(AutorizacaoAnuenciaTypes.SET_CONSULTA_AUTORIZADA, payload)

export const reset = (): Action => action(AutorizacaoAnuenciaTypes.RESET_SIAPE)

export const setLoading = (payload: boolean): Action =>
  action(AutorizacaoAnuenciaTypes.SET_LOADING, payload)

export const setPendencia = (payload: string): Action =>
  action(AutorizacaoAnuenciaTypes.SET_PENDENCIA, payload)

export const setMargem = (payload: number): Action =>
  action(AutorizacaoAnuenciaTypes.SET_MARGEM, payload)
