import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import {
  getCidades,
  getEstados,
  getFontesPagamento,
  getUpags,
  getPropostas,
  getContratosAtivos,
} from '@store/globalApi/sagas'

import { GlobalApiTypes } from './types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(GlobalApiTypes.FONTES_REQUEST, getFontesPagamento)
  yield* takeLatest(GlobalApiTypes.ESTADOS_REQUEST, getEstados)
  yield* takeLatest(GlobalApiTypes.CIDADES_REQUEST, getCidades)
  yield* takeLatest(GlobalApiTypes.UPAGS_REQUESTS, getUpags)
  yield* takeLatest(GlobalApiTypes.PROPOSTAS_REQUEST, getPropostas)
  yield* takeLatest(GlobalApiTypes.CONTRATOS_ATIVOS_REQUEST, getContratosAtivos)
}
