import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import * as actionsNovo from '@store/novo/fontePagamento/actions'
import { ApplicationState } from '@store/types'

interface ConveniosPayload {
  tipoSimulacao: 'novo'
  fontePagamento: number
  callback?: () => void
}

const actionsTypeSimulation = {
  novo: actionsNovo,
}

const errorCodesTypeSimulation = {
  novo: ErrorCodes.N0_001_CONVENIOS,
}

export default function* getConvenios(action: AnyAction) {
  const payload = action.payload as ConveniosPayload
  const {
    conveniosSucces,
    conveniosError,
    conveniosRequest,
    setConvenioSelecionado,
    orgaosSuccess,
    setOrgaoSelecionado,
  } = actionsTypeSimulation[payload.tipoSimulacao]

  try {
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const response = yield* call(services.getConvenios, payload.fontePagamento, newSimulatorApi)
    const { convenios } = response.data
    const data = Object.values(convenios).filter((value) => !!value && !!value?.codigo)
    yield* put(conveniosSucces(data))

    // Se houver apenas um convenio ja deixa ele como selecionado
    if (data?.length === 1) {
      const [convenio] = data
      yield* put(setConvenioSelecionado(convenio.codigo))

      // Busca orgaos de uma vez, ja que o convenio ja foi selecionado
      const responseOrgao = yield* call(services.getOrgaos, convenio.codigo, newSimulatorApi)
      const { orgaosPorConvenioConsulta } = responseOrgao.data
      const orgaos = Object.values(orgaosPorConvenioConsulta).filter(
        (value) => !!value && !!value?.codigo,
      )
      yield* put(orgaosSuccess(orgaos))

      // Se houver apenas um orgao também ja deixa ele como selecionado
      if (orgaos.length === 1) {
        const [orgao] = orgaos
        yield* put(setOrgaoSelecionado(orgao.codigo))
      }
    }

    if (payload.callback) {
      yield payload.callback()
    }
  } catch (error) {
    yield* put(conveniosError())
    yield* put(
      showError({
        title: 'Erro ao buscar os convênios',
        message: 'Ocorreu um erro listagem de convênios.',
        actionTitle: 'Tentar Novamente',
        code: errorCodesTypeSimulation[payload.tipoSimulacao],
        actionCallback: () => conveniosRequest(payload),
      }),
    )
  }
}
