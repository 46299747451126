/**
 * Actions Types
 */

export enum BeneficiarioInssTypes {
  DADOS_BENEFICIARIO_INSS_SUCCESS = '@novo/beneficiarioInss/DADOS_BENEFICIARIO_INSS_SUCCESS',
  DADOS_BENEFICIARIO_INSS_ERROR = '@novo/beneficiarioInss/DADOS_BENEFICIARIO_INSS_ERROR',
  DADOS_BENEFICIARIO_INSS_REQUEST = '@novo/beneficiarioInss/DADOS_BENEFICIARIO_INSS_REQUEST',
  SET_REDIRECT_INSS = '@novo/beneficiarioInss/SET_REDIRECT_INSS',
  SET_SIMULACAO_SIMPLIFICADA_INSS = '@novo/beneficiarioInss/SET_SIMULACAO_SIMPLIFICADA_INSS',
  CLEAR_SITUACAOFUNCIONAL = '@novo/beneficiarioInss/CLEAR_SITUACAOFUNCIONAL',
  SET_CHECK_AUTORIZACAO_INSS = '@novo/beneficiarioInss/SET_CHECK_AUTORIZACAO_INSS',

  REQUEST_SCHEDULE_SIMULATION = '@novo/beneficiarioInss/REQUEST_SCHEDULE_SIMULATION',
  UPDATE_SCHEDULE_SIMULATION = '@novo/beneficiarioInss/UPDATE_SCHEDULE_SIMULATION',
  SET_SCHEDULE_SIMULATION_ID = '@novo/beneficiarioInss/SET_SCHEDULE_SIMULATION_ID',

  DADOS_INSS_RESUlT_REQUEST = '@novo/beneficiarioInss/DADOS_INSS_RESUlT_REQUEST',
  DADOS_INSS_START_ASYNC = '@novo/beneficiarioInss/DADOS_INSS_START_ASYNC',

  SET_LOADING_INSS_APIS = '@novo/beneficiarioInss/SET_LOADING_INSS_APIS',
  SET_COUNTER_RECALL = '@novo/beneficiarioInss/SET_COUNTER_RECALL',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface BeneficiarioInssState {
  readonly redirectInss?: boolean
  readonly simplificadaInss?: boolean
  readonly dadosInssLoading: boolean
  readonly dadosInssError?: boolean
  readonly dadosBeneficiario?: DadosConsultaInssState
  readonly checkAutorizacaoInss?: boolean
  readonly scheduledSimulationId?: number | string
  readonly loadingInssApis?: boolean
  readonly counterRecall: number
}

export interface InfoBeneficiarioInss {
  tipoCredito: string
  margem: number
  valorSalarioLiquido: number
  nome?: string
  dataNascimento?: string
  estado?: string
  especieBeneficio: number
  situacaoBeneficio: string
  numeroBeneficio: string
}

export interface DadosConsultaInssState {
  isConsultaRealizada: boolean
  isHorarioValido: boolean
  isBeneficioValido: boolean
  pendencia?: tipoPendencia
  infoInss?: InfoBeneficiarioInss
}

export type tipoPendencia =
  | 'PENDENCIA_REVERSIVEL_LEAD_PORTABILIDADE'
  | 'SEM_PENDENCIA_LEAD_PORTABILIDADE'
  | 'PENDENCIA_REVERSIVEL_LEAD_NOVO'
  | 'SEM_PENDENCIA_LEAD_NOVO'
  | 'PENDENCIA_IRREVERSIVEL'
  | 'PENDENCIA_TEIMOSINHA'
  | 'PENDENCIA_VALIDACAO'
  | 'AGUARDANDO_VALIDACAO'
  | ''

export type DadosInssPayload = {
  callback: (
    isConsultaRealizada: boolean,
    pendencia: tipoPendencia,
    isBeneficioValido?: boolean,
    isHorarioValido?: boolean,
  ) => void
}

export type StartPayload = {
  callback: () => void
  callbackError?: () => void
}
