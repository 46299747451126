import { BaseServices, HttpMethod } from '@services/base'

export default (data: ValidaRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_VALIDAR_CPF_NOVO
    : process.env.REACT_APP_VALIDAR_CPF_NOVO_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request<ContatoResponse>({
    pathname,
    method,
    host,
    data,
  })
}

export interface ValidaRequest {
  cpf: string
  upag: string
  estado: string
  cidade: string
  idConvenio: number
  valorSolicitado: number
  dataUltimoVencimento: string
  dataNascimento: string
  simulacaoComSeguro: boolean
}

export interface ContatoResponse {
  contato: boolean
  riscoCpf: number
  pendencia: string
  descricaoPendencia: string
}
