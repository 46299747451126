import { BaseServices, HttpMethod } from '@services/base'

import { SimulacoesSangueLaranjaRequest } from './postSimulacaoNovo'

export default (cpf: string, valorSolicitado?: number) => {
  let pathname = process.env.REACT_APP_SIMULATE_SANGUE_LARANJA_BFF
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  if (valorSolicitado) {
    pathname = `${pathname}?valorSolicitacao=${valorSolicitado}`
  }

  const response = BaseServices.request<SimulacaoSangueLaranjaResponse>({
    headers: {
      'X-Inter-User-Social-Id': cpf,
    },
    pathname,
    method,
    host,
  })

  return response
}

export interface SimulacaoSangueLaranjaResponse {
  email: string
  telefone: string
  matricula: string
  valorMaximo: number
  valorMaximoComSeguro: number
  valorMinimo: number
  margemConsultada: boolean
  limiteCartao: number
  simulacoesSemSeguro: SimulacoesSangueLaranjaRequest[]
  simulacoesComSeguro: SimulacoesSangueLaranjaRequest[]
}
