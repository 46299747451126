import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { buildconveniosNovo } from '@utils/simuladorUtils'
import { Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet/types'
import { bottomSheetVariants, Variant } from '@utils/bottomSheetVariants'
import { Convenio } from '@store/globalApi/types'
import { Colors } from '@utils/Colors'
import { ApplicationState } from '@store/types'
import Select from '@atoms/SelectNovo'
import Paragraph from '@atoms/Paragraph'

import { CustomBottomSheet, SaibaMaisComponent } from './styles'

interface OwnProps {
  id: string
  setVariant?: (variant: Variant) => void
  setOpen?: (isOpen: boolean) => void
  convenioSelecionado: Option
  convenios: Convenio[]
  loading: boolean
  onChange: (value: Option) => void
  disabled?: boolean
  ocultarSaibaMais?: boolean
}

const ConvenioDropdownNovo = ({
  id,
  setOpen,
  setVariant,
  convenioSelecionado,
  convenios,
  loading,
  onChange,
  disabled,
  ocultarSaibaMais,
}: OwnProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp) {
    return (
      <Select
        label="Qual é o seu convênio/empresa?"
        name="dropdown"
        margin="0 0 20px 0"
        value={convenioSelecionado}
        hideSearch={false}
        options={buildconveniosNovo(convenios)}
        placeholder="Selecione o convênio/empresa"
        onChange={(value) => {
          onChange(value)
        }}
        loading={loading}
        disabled={disabled}
        helperButton={
          !ocultarSaibaMais ? (
            <Paragraph color={Colors.PRIMARY500} fontSize="12px">
              Saiba mais
            </Paragraph>
          ) : (
            <></>
          )
        }
        onClickHelper={() => {
          setVariant && setVariant(bottomSheetVariants.convenioNovo)
          setOpen && setOpen(true)
        }}
      />
    )
  }

  return (
    <>
      {!ocultarSaibaMais ? (
        <SaibaMaisComponent
          color={Colors.PRIMARY500}
          fontSize="12px"
          onClick={() => {
            setVariant && setVariant(bottomSheetVariants.convenioNovo)
            setOpen && setOpen(true)
          }}
        >
          Saiba mais
        </SaibaMaisComponent>
      ) : (
        <></>
      )}

      <CustomBottomSheet
        id={id}
        label="Qual é o seu convênio/empresa?"
        name="dropdown"
        isUsingFilter
        value={convenioSelecionado}
        onChange={(value) => {
          onChange(value)
        }}
        options={buildconveniosNovo(convenios)}
        placeholder="Selecione o convênio/empresa"
        isLoading={loading}
        disabled={disabled}
      />
    </>
  )
}
export default memo(ConvenioDropdownNovo)
