import { Reducer } from 'redux'

import { AlertaTypes, AlertaState } from './types'

const INITIAL_STATE: AlertaState = {
  isOpen: true,
}

const reducer: Reducer<AlertaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AlertaTypes.SET_ABRIR_ALERTA:
      return { ...state, isOpen: action.payload }
    default:
      return state
  }
}

export default reducer
