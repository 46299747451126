export default () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="32" fill="var(--gray100)" />
    <path
      d="M45.2673 33.2676C44.5984 40.0053 38.9136 45.2676 31.9998 45.2676C24.636 45.2676 18.6665 39.298 18.6665 31.9342C18.6665 25.0204 23.9287 19.3357 30.6665 18.6667"
      stroke="var(--gray500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.3335 22.6667H37.3335"
      stroke="var(--gray500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.3335 18.6667L41.3335 26.6667"
      stroke="var(--gray500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 28H32H30C28.8954 28 28 28.8954 28 30V30C28 31.1046 28.8954 32 30 32H32H34C35.1046 32 36 32.8954 36 34V34C36 35.1046 35.1046 36 34 36H28"
      stroke="var(--gray500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32 28L32 25.3333"
      stroke="var(--gray500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32 38.6667L32 36"
      stroke="var(--gray500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
