/**
 * Actions Types
 */

export enum DadosPessoaisTypes {
  SET_NOME = '@novo/dadosPessoais/SET_NOME',
  SET_CPF = '@novo/dadosPessoais/SET_CPF',
  SET_DATA_NASCIMENTO = '@novo/dadosPessoais/SET_DATA_NASCIMENTO',
  SET_PERFIL = '@novo/dadosPessoais/SET_PERFIL',
  SET_RENDA = '@novo/dadosPessoais/SET_RENDA',
  SET_FLAG_TITULAR_DADOS = '@novo/dadosPessoais/SET_FLAG_TITULAR_DADOS',
  SET_CONTA = '@novo/dadosPessoais/SET_CONTA',
  SET_SENHA_SERVIDOR = '@novo/dadosPessoais/SET_SENHA_SERVIDOR',
  GET_DADOS_USUARIO = '@novo/dadosPessoais/GET_DADOS_USUARIO',
  GET_DADOS_USUARIO_PROPOSTA_SANGUE_LARANJA = '@novo/dadosPessoais/GET_DADOS_USUARIO_PROPOSTA_SANGUE_LARANJA',
  SET_SANGUE_LARANJA = '@novo/dadosPessoais/SET_SANGUE_LARANJA',
  SET_DADOS_USUARIO_PROPOSTA_SANGUE_LARANJA = '@novo/dadosPessoais/SET_DADOS_USUARIO_PROPOSTA_SANGUE_LARANJA',
  SET_NUMERO_CONTRATO = '@novo/dadosPessoais/SET_NUMERO_CONTRATO',
  SET_NOME_SOCIAL = '@novo/dadosPessoais/SET_NOME_SOCIAL',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface DadosPessoaisState {
  readonly nome: string
  readonly cpf: string
  readonly dataNascimento: string
  readonly perfil?: Perfil
  readonly renda: number
  readonly flagTitularDados: boolean
  readonly conta: string
  readonly senhaServidor?: string
  readonly sangueLaranja?: boolean
  readonly documento?: string
  readonly endereco?: string
  readonly naturalidade?: string
  readonly nomeMae?: string
  readonly nomePai?: string
  readonly nomeConjuge?: string
  readonly numeroContrato?: string
  readonly nomeSocial?: string
}

export type Perfil = 'aposentado' | 'pensionista' | 'militar'
