import { BaseServices, HttpMethod } from '@services/base'

import { SimulacoesSangueLaranjaRequest } from './postSimulacaoNovo'

export default (proposalCode: string | undefined) => {
  const pathname = `${process.env.REACT_APP_SIMULATE_SANGUE_LARANJA_BFF}/${proposalCode}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ParcelaSimulacaoSangueLaranja>({
    pathname,
    method,
    host,
  })

  return response
}

export interface ParcelaSimulacaoSangueLaranja extends SimulacoesSangueLaranjaRequest {
  matricula: string
  possuiSeguro: boolean
}
