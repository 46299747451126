import React from 'react'

import clsx from 'clsx'

import {
  withStyles,
  Stepper,
  Step,
  StepLabel,
  createStyles,
  WithStyles,
  Grid,
  StepIconProps,
  makeStyles,
  StepConnector,
} from '@material-ui/core'
import { CheckStepIcon } from '@icons'

const styles = createStyles({
  step: {
    padding: '0px 2px',
  },
  stepper: {
    padding: '8px',
  },
})

interface OwnProps extends WithStyles<typeof styles> {
  activeStep: number
  stepNumbers: number
  margin?: string
}

const stepIconStyles = makeStyles({
  root: {
    color: '#FFFFFF',
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    backgroundColor: '#DEDFE4',
    fontSize: '12px',
    fontWeight: 700,
  },
  active: {
    backgroundColor: '#161616',
  },
})

const StepIcon = ({ active, completed, icon }: StepIconProps) => {
  const classes = stepIconStyles()
  return (
    <div className={clsx(classes.root, { [classes.active]: active })}>
      {completed ? <CheckStepIcon /> : <div>{icon}</div>}
    </div>
  )
}

const CustomStepConnector = withStyles({
  line: {
    display: 'none',
  },
})(StepConnector)

const CustomSteps = ({ classes, activeStep, stepNumbers, margin }: OwnProps) => (
  <Grid container direction="column" alignContent="center" style={{ margin }}>
    <Stepper
      activeStep={activeStep}
      className={classes.stepper}
      connector={<CustomStepConnector />}
      data-testid="stepper"
    >
      {stepNumbers <= 1 ? (
        <Step key={1} className={classes.step} data-testid="step">
          <StepLabel StepIconComponent={StepIcon} />
        </Step>
      ) : (
        Array.from(Array(stepNumbers), (v, i) => (
          <Step key={i} className={classes.step} data-testid={`step-${i}`}>
            <StepLabel StepIconComponent={StepIcon} />
          </Step>
        ))
      )}
    </Stepper>
  </Grid>
)

CustomSteps.defaultProps = {
  margin: '0 0 20px 0',
}

export default withStyles(styles)(CustomSteps)
