import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { removeMask } from '@utils/masks'
import { showError } from '@store/ui/error/actions'

import { inssStartAsync, setLoadingInssApis } from '../actions'
import { StartPayload } from '../types'

export default function* postInssAsyncStartSaga(action: AnyAction) {
  const payload = action.payload as StartPayload
  const cpf = yield* select((state: ApplicationState) => state.novo.dadosPessoais.cpf)
  try {
    yield put(setLoadingInssApis(true))
    yield services.postInssAsyncStart(removeMask(cpf))
    yield put(setLoadingInssApis(false))
    yield payload.callback()
  } catch (error) {
    yield put(setLoadingInssApis(false))
    if (payload.callbackError) {
      yield payload.callbackError()
    }
    yield put(
      showError({
        title: 'Erro',
        message: 'Ocorreu um erro ao iniciar a validação do benefício',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => inssStartAsync(payload),
      }),
    )
  }
}
