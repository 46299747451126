import React, { useCallback, useEffect, useRef, useState } from 'react'

import { P, Colors } from '@interco/cp-react-ui-lib'
import {
  InterUIIcon,
  InterUIInputGroup,
  InterUILoading,
  RemoveAccents,
} from '@interco/inter-ui-react-lib'
import {
  IInterUIDropdownOption,
  IInterUIDropdownProps,
} from '@interco/inter-ui-react-lib/dist/interfaces/inter-ui-dropdown-props'

import {
  Container,
  InputSelect,
  Label,
  Option,
  HelpButton,
  ContentOptions,
  Overlay,
  SelectArea,
} from './Select.styles'

const Select = ({
  margin,
  name,
  label,
  placeholder,
  value,
  options = [],
  disabled = false,
  hideSearch = false,
  helperButton,
  loading,
  onChange,
  onFilter,
  onClickHelper,
  ...props
}: IInterUIDropdownProps) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<IInterUIDropdownOption>()
  const [filteredData, setFilteredData] = useState<IInterUIDropdownOption[]>([])
  const [emptySearch, setEmptySearch] = useState<boolean>(true)
  const searchRef = useRef<HTMLInputElement>(null)

  const setValue = useCallback(() => {
    options.map((item) => (item.value === value ? setSelected(item) : null))
  }, [options, value, setSelected])

  useEffect(() => {
    setSelected(undefined)
    setFilteredData(options)

    setValue()
  }, [options, setValue])

  useEffect(() => {
    setValue()
  }, [value, setValue])

  useEffect(() => {
    if (open && searchRef.current) {
      searchRef.current.focus()
    }
  }, [open])

  /**
   * Apaga valor do input de search
   */
  const deleteSearchValue = () => {
    setEmptySearch(true)
    setFilteredData(options)
    if (searchRef.current) {
      searchRef.current.value = ''
    }
  }

  /**
   * Exibir o bottom sheet com as opções somente se o dropdown não estiver desabilitado.
   */
  const showOptions = () => setOpen(!disabled)

  /**
   * Ocultar o bottom sheet com as opções.
   */
  const hideOptions = () => {
    setOpen(false)
    deleteSearchValue()
  }

  /**
   * Evento disparado ao clicar em uma das opções exibidas no bottom sheet.
   * @param item Item selecionado.
   */
  const changeOption = (item: IInterUIDropdownOption) => {
    setSelected(item)
    onChange(item.value)
    hideOptions()
  }

  /**
   * Filtro default para o input search.
   * @param search Dado informado no input.
   * @param item Opção do dropdown para realizar a comparação.
   */
  const onFilterDefault = (search: string, item: IInterUIDropdownOption) =>
    RemoveAccents(item.label).includes(search)

  /**
   * Evento disparado ao digitar informações no search para filtrar.
   * @param event KeyboardEvent disparado.
   */
  const keyUpSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const valueSearch = (event.target as HTMLInputElement).value

    const search = RemoveAccents(valueSearch)
    const filter = onFilter || onFilterDefault

    setEmptySearch(!valueSearch)

    setFilteredData(options.filter((item, index) => filter(search, item, index)))
  }

  /**
   * Renderização da lista de opções para o bottom sheet.
   */
  const renderOptions = filteredData.map((item) => (
    <Option key={`dropdown-${item.value}`} role="option" onClick={() => changeOption(item)}>
      <P fontSize="14px" lineHeight="17px" color={Colors.GRAY400} margin="0">
        {item.label}
      </P>
    </Option>
  ))

  return (
    <Container margin={margin} {...props}>
      <Label id={name} disabled={disabled} useHelperButton={!!helperButton}>
        {label}

        {helperButton && (
          <HelpButton type="button" onClick={onClickHelper}>
            {helperButton}
          </HelpButton>
        )}
      </Label>
      <InputSelect
        onClick={showOptions}
        disabled={disabled}
        placeholder={!selected ? placeholder : undefined}
        role="listbox"
        aria-labelledby={name}
        aria-disabled={disabled}
        aria-expanded={open}
      >
        <p>{selected?.label || placeholder}</p>
        {loading ? (
          <InterUILoading />
        ) : (
          <InterUIIcon
            name="arrow-chevron-down"
            size="24px"
            color={disabled ? Colors.GRAY200 : Colors.ORANGE500}
          />
        )}
      </InputSelect>
      {open && (
        <>
          <Overlay open={open} onClick={hideOptions} />
          <SelectArea>
            {!hideSearch && (
              <InterUIInputGroup className="group-search" margin="0 0 5px">
                {emptySearch && <InterUIIcon name="search" size="16px" color={Colors.ORANGE500} />}

                <input
                  ref={searchRef}
                  type="search"
                  name="dropdown-search"
                  placeholder="Pesquisar"
                  autoComplete="off"
                  onKeyUp={keyUpSearch}
                  aria-labelledby={name}
                />

                {!emptySearch && (
                  <InterUIIcon
                    onClick={deleteSearchValue}
                    name="contextual-error"
                    size="16px"
                    color={Colors.ORANGE500}
                  />
                )}
              </InterUIInputGroup>
            )}
            <ContentOptions>{renderOptions}</ContentOptions>
          </SelectArea>
        </>
      )}
    </Container>
  )
}

export default Select
