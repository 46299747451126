import { Reducer } from 'redux'

import { ConsultaRioTypes, ConsultaMargemRioState } from './types'

const INITIAL_STATE: ConsultaMargemRioState = {
  senhaAverbacaoRio: '',
  averbacaoValidaRio: true,
  tentativasRio: 0,
  loadingRio: false,
  consultaRealizada: false,
  idFuncional: '',
  vinculo: '',
  numeroPensionista: '',
  valorMargem: 0,
}

const reducer: Reducer<ConsultaMargemRioState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConsultaRioTypes.SET_AVERBACAO_VALIDA_RIO:
      return { ...state, averbacaoValidaRio: action.payload }
    case ConsultaRioTypes.SET_TENTATIVAS_RIO:
      return { ...state, tentativasRio: action.payload }
    case ConsultaRioTypes.SET_SENHA_AVERBACAO_RIO:
      return { ...state, senhaAverbacaoRio: action.payload }
    case ConsultaRioTypes.SET_LOADING_RIO:
      return { ...state, loadingRio: action.payload }
    case ConsultaRioTypes.CLEAR_ERROR_RIO:
      return { ...state, averbacaoValidaRio: true }
    case ConsultaRioTypes.SET_ID_FUNCIONAL:
      return { ...state, idFuncional: action.payload }
    case ConsultaRioTypes.SET_VINCULO:
      return { ...state, vinculo: action.payload }
    case ConsultaRioTypes.SET_NUMERO_PENSIONISTA:
      return { ...state, numeroPensionista: action.payload }
    case ConsultaRioTypes.SET_VALOR_MARGEM:
      return { ...state, valorMargem: action.payload }
    case ConsultaRioTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
