import { Reducer } from 'redux'

import { ValidaState, ValidaTypes } from './types'

const INITIAL_STATE: ValidaState = {
  loading: false,
  loadingReforma: false,
}

const reducer: Reducer<ValidaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ValidaTypes.VALIDA_SET_LOADING:
      return { ...state, loading: action.payload }
    case ValidaTypes.VALIDA_REFORMA_SET_LOADING:
      return { ...state, loadingReforma: action.payload }

    case ValidaTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
