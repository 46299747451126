/**
 * Actions Types
 */

export enum ConsultaExercitoTypes {
  SET_SENHA_AVERBACAO_EXERCITO = '@novo/consultaExercito/SET_SENHA_AVERBACAO_EXERCITO',
  SET_AVERBACAO_VALIDA_EXERCITO = '@novo/consultaExercito/SET_AVERBACAO_VALIDA_EXERCITO',
  SET_TENTATIVAS_EXERCITO = '@novo/consultaExercito/SET_TENTATIVAS_EXERCITO',
  POST_VALIDA_SENHA_EXERCITO = '@novo/consultaExercito/POST_VALIDA_SENHA_EXERCITO',
  SET_LOADING_EXERCITO = '@novo/consultaExercito/SET_LOADING_EXERCITO',
  CLEAR_ERROR_EXERCITO = '@novo/consultaExercito/CLEAR_ERROR_EXERCITO',
  SET_CONSULTA_REALIZADA = '@novo/consultaExercito/SET_CONSULTA_REALIZADA',
}

/**
 * State Type
 */

export interface ConsultaExercitoState {
  readonly senhaAverbacaoExercito: string
  readonly averbacaoValidaExercito: boolean
  readonly tentativasExercito: number
  readonly loadingExercito: boolean
  readonly consultaRealizada: boolean
}

export type ValidaSenhaExercitoPayload = {
  callback: (isValido: boolean | undefined, tentativas: number) => void
}
