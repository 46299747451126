import React from 'react'

import { Alert } from '@interco/inter-ui/components/Alert'
import { Colors } from '@utils/Colors'
import Security from '@interco/icons/orangeds/SM/security'
import { Link } from '@interco/inter-ui/components/Link'
import Paragraph from '@atoms/Paragraph'

const AlertLGPD = ({
  title = 'Seus dados estão protegidos',
  children = 'Faça a sua simulação com tranquilidade que garantimos a segurança e privacidade de todos os seus dados.',
}: React.PropsWithChildren<{ title?: string }>) => (
  <Alert title={title} type="warning" customIcon={<Security height={20} width={60} />}>
    <Paragraph fontSize="12px" lineHeight="15px" color={Colors.GRAY500} margin="0">
      {children} Aproveite para conferir a nossa{' '}
      <Link
        href="https://www.bancointer.com.br/politica-de-privacidade/"
        variant="caption-1"
        color="primary"
        bold
      >
        Política de Privacidade.
      </Link>
    </Paragraph>
  </Alert>
)

export default AlertLGPD
