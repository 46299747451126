import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import { upagsLoading, upagsSuccess, upgasError, upagsRequest } from '../actions'

export default function* getUpags() {
  try {
    yield* put(upagsLoading())
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const response = yield* call(services.getUpags, newSimulatorApi)
    const { upags } = response.data
    const data = Object.values(upags).filter((value) => !!value && !!value?.sigla)
    yield* put(upagsSuccess(data))
  } catch (error) {
    yield put(upgasError())
    yield* put(
      showError({
        title: 'Erro ao buscar lista de upags',
        message: 'Ocorreu um erro ao buscar a listagem de upgs.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_007_UPAGS,
        actionCallback: () => upagsRequest(),
      }),
    )
  }
}
