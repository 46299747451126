import React from 'react'

import { InterUIButton } from '@interco/inter-ui-react-lib'
import { P, Colors } from '@interco/cp-react-ui-lib'
import { Grid } from '@material-ui/core'

import { TypeToken } from './types'

interface Props {
  setChoice: (type: TypeToken) => void
}

const ChoiceTypeToken = ({ setChoice }: Props) => (
  <Grid container direction="column">
    <P
      variant="sora"
      fontWeight="600"
      fontSize="16px"
      lineHeight="20px"
      color={Colors.GRAY500}
      margin="0 0 12px 0"
    >
      Código de validação
    </P>

    <P margin="0 0 20px 0">
      Para sua segurança, você receberá um código de acesso. Escolha onde deseja receber.
    </P>

    <InterUIButton onClick={() => setChoice('sms')}>Receber por SMS</InterUIButton>
    <InterUIButton
      onClick={() => setChoice('email')}
      style={{ margin: '10px auto 0' }}
      variant="secondary"
    >
      Receber por e-mail
    </InterUIButton>
  </Grid>
)

export default ChoiceTypeToken
