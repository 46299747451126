import React from 'react'

import { Page } from '@components/templates'
import { InnerLoading } from '@components/molecules'

const FallbackPage = () => (
  <Page id="novo-fallback">
    <InnerLoading height="150px" size={48} thickness={2} />
  </Page>
)

export default FallbackPage
