import { BaseServices, HttpMethod } from '@services/base'
import { ContratosAtivosApi } from '@store/globalApi/types'

export default (cpf: string) => {
  const pathname = process.env.REACT_APP_CONTRATOS_ATIVOS_SANGUE_LARANJA_BFF
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request<ContratosAtivosApi>({
    headers: {
      cpf,
    },
    pathname,
    method,
    host,
  })
}
