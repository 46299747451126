/**
 * Actions Types
 */

import { StepNRNovo } from '@utils/enums'

export enum SimulacaoTypes {
  SIMULACAO_REQUEST = '@novo/simulacao/SIMULACAO_REQUEST',
  SIMULACAO_SANGUE_LARANJA_REQUEST = '@novo/simulacao/SIMULACAO_SANGUE_LARANJA_REQUEST',
  SIMULACAO_SUCCESS = '@novo/simulacao/SIMULACAO_SUCCESS',
  SIMULACAO_EXERCITO_SUCCESS = '@novo/simulacao/SIMULACAO_EXERCITO_SUCCESS',
  SIMULACAO_ERROR = '@novo/simulacao/SIMULACAO_ERROR',
  SIMULACAO_SANGUE_LARANJA_ERROR = '@novo/simulacao/SIMULACAO_SANGUE_LARANJA_ERROR',
  SELECT_SIMULACAO = '@novo/simulacao/SELECT_SIMULACAO',
  TRACKING_SIMULACAO = '@novo/simulacao/TRACKING_SIMULACAO',
  SALVAR_LEAD_REQUEST = '@novo/simulacao/SALVAR_LEAD_REQUEST',
  SALVAR_LEAD_SANGUE_LARANJA_REQUEST = '@novo/simulacao/SALVAR_LEAD_SANGUE_LARANJA_REQUEST',
  SALVAR_LEAD_SUCCESS = '@novo/simulacao/SALVAR_LEAD_SUCCESS',
  SALVAR_LEAD_ERROR = '@novo/simulacao/SALVAR_LEAD_ERROR',
  CLEAR_SIMULACAO = '@novo/simulacao/CLEAR_SIMULACAO',
  HAS_OBJETO_SIMULACAO = '@novo/simulacao/HAS_OBJETO_SIMULACAO',
  HAS_OBJETO_SIMULACAO_PROTEGIDA = '@novo/simulacao/HAS_OBJETO_SIMULACAO_PROTEGIDA',
  SET_TOTAL_PARCELAS = '@novo/simulacao/SET_TOTAL_PARCELAS',
  SET_PARCELA_SELECIONADA = '@novo/simulacao/SET_PARCELA_SELECIONADA',
  SELECT_SIMULACOES = '@novo/simulacao/SELECT_SIMULACOES',
  POST_CONTRATO_SANGUE_LARANJA_REQUEST = '@novo/simulacao/POST_CONTRATO_SANGUE_LARANJA',
  POST_CONTRATO_SANGUE_LARANJA_ERROR = '@novo/simulacao/POST_CONTRATO_SANGUE_LARANJA_ERROR',
  POST_CONTRATO_SANGUE_LARANJA_SUCCESS = '@novo/simulacao/POST_CONTRATO_SANGUE_LARANJA_SUCCESS',
  PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_REQUEST = '@novo/simulacao/PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA',
  PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_ERROR = '@novo/simulacao/PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_ERROR',
  PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_SUCCESS = '@novo/simulacao/PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_SUCCESS',
  SET_VALOR_MAX = '@novo/simulacao/SET_VALOR_MAX',
  SET_DATA_HORA_DADOS_RECEBIDOS = '@novo/simulacao/SET_DATA_HORA_DADOS_RECEBIDOS',
  SET_SIMULACAO_ID = '@novo/simulacao/SET_SIMULACAO_ID',
  SET_IS_CONTRATACAO_COMPLETA = '@novo/simulacao/SET_IS_CONTRATACAO_COMPLETA',
  POST_ISAFE_AUTORIZACAO = '@novo/simulacao/POST_ISAFE_AUTORIZACAO',
  GET_PARCELA_SIMULACAO_SANGUE_LARANJA_REQUEST = '@novo/simulacao/GET_PARCELA_SIMULACAO_SANGUE_LARANJA_REQUEST',
  GET_PARCELA_SIMULACAO_SANGUE_LARANJA_SUCCESS = '@novo/simulacao/GET_PARCELA_SIMULACAO_SANGUE_LARANJA_SUCCESS',
  GET_PARCELA_SIMULACAO_SANGUE_LARANJA_ERROR = '@novo/simulacao/GET_PARCELA_SIMULACAO_SANGUE_LARANJA_ERROR',
  SET_DADOS_SIMULACAO_ALTERADOS = '@novo/simulacao/SET_DADOS_SIMULACAO_ALTERADOS',
  RESET = 'RESET',
}

/**
 * State Type  Simulacao
 */

export interface Simulacao {
  qtdeParcelas: number
  valorSolicitado: number
  valorParcela: number
  valorLiberado: number
  valorIOF: number
  valorFinanciado: number
  taxaNominalAm: number
  dataUltimoVencimento: string
  tIRAm: number
  tIRAa: number
  cETAa: number
  cETAm: number
  taxaNomialAa: number
  dataPrimeiroVencimento: string
  jurosContrados: number
  jurosContradosPercentual: number
  valorTotalASerPago: number
  tarifaCadastroPercentual: number
  tarifaCadastro: number
  valorIOFPercentual: number
  valorSeguro: number
  valorSeguroPercentual: number
}

export interface Simulacoes {
  qtdeParcelas: number
  valorSolicitado: number
  valorParcela: number
  valorLiberado: number
  valorIOF: number
  valorIOFPercentual: number
  valorSeguro: number
  valorSeguroPercentual: number
  tarifaCadastro: number
  tarifaCadastroPercentual: number
  valorFinanciado: number
  tIRAm: number
  tIRAa: number
  cETAa: number
  cETAm: number
  taxaNominalAm: number
  taxaNomialAa: number
  dataUltimoVencimento: string
  dataPrimeiroVencimento: string
  jurosContrados: number
  jurosContradosPercentual: number
  valorTotalASerPago: number
}

/**
 * State Type
 */

export interface SimulacaoState {
  readonly loading: boolean
  readonly limiteCartao: number
  readonly margemConsultada: boolean
  readonly simulacaoSelecionada: 'simulacao' | 'simulacaoComSeguro'
  readonly simulacao: Simulacao
  readonly simulacaoComSeguro: Simulacao
  readonly simulacoes: Simulacoes[]
  readonly simulacoesComSeguro: Simulacoes[]
  readonly valorMaximo: number
  readonly valorMaximoComSeguro: number
  readonly valorMinimo: number
  readonly leadEnviado: boolean
  readonly hasObjetoSimulacao: boolean
  readonly hasObjetoSimulacaoProtegida: boolean
  readonly idSimulacao?: string | number
  readonly isContratacaoCompleta: boolean
  readonly totalParcelas: number
  readonly parcelaAtual: Simulacoes
  readonly simulacoesSelecionada: 'simulacoes' | 'simulacoesComSeguro'
  readonly dataHoraDadosRecebidos: string
  readonly dadosSimulacaoAlterados: boolean
}

/**
 * Actions Payload Types
 */
export interface CallbackSimulacaoType {
  naoLiberado: boolean
  propostaEmAndamento?: boolean
}

export type SimulacaoRequestPayload = {
  callback: (params: CallbackSimulacaoType) => void
  checkSeguro?: boolean
}

export type SimulacaoSangueLaranjaRequestPayload = {
  callback: (params: CallbackSimulacaoType) => void
  valorSolicitacao?: number
}

export type ParcelaSimulacaoSangueLaranjaPayload = {
  callback: () => void
  codigoProposta?: string
}

export type SalvarLeadRequestPayload = {
  pendencia?: string
  observacao?: string
  callback: (idSimulacao: string | number | undefined) => void
}

export type SalvarLeadSangueLaranjaRequestPayload = {
  callback: (params?: CallbackSimulacaoType) => void
}

export type TrackerRelicRequestPayload = {
  step: StepNRNovo
}
