import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import postConsultaMargemExercito from '@services/postConsultaMargemExercito'
import { removeMask } from '@utils/masks'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { setPendencia } from '@store/novo/pendencia/actions'
import {
  setDataIngresso,
  setIndicativo,
  setIsMatriculaValida,
  setSituacao,
} from '@store/novo/situacaoFuncional/actions'

import {
  postValidaSenhaExercito,
  setAverbacaoValidaExercito,
  setConsultaRealizada,
  setLoadingExercito,
  setTentativasExercito,
} from '../actions'

interface ErrorType {
  status: number
  data: {
    message: string
  }
}

export default function* postValidaSenhaExercitoSaga({ payload }: AnyAction) {
  const { matricula } = yield* select((state: ApplicationState) => state.novo.situacaoFuncional)
  const { senhaAverbacaoExercito } = yield* select(
    (state: ApplicationState) => state.novo.consultaMargemExercito,
  )
  const { cpf } = yield* select((state: ApplicationState) => state.novo.dadosPessoais)
  const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)

  try {
    yield put(setLoadingExercito(true))
    yield put(setIsMatriculaValida(true))
    const { data } = yield* call(
      postConsultaMargemExercito,
      {
        cpf,
        matricula: removeMask(String(matricula)),
        senhaServidor: senhaAverbacaoExercito,
        matriculaMultipla: false,
      },
      newSimulatorApi,
    )
    yield put(setPendencia(data?.pendencia))
    yield put(setConsultaRealizada(true))

    if (data.tentativas) {
      yield put(setAverbacaoValidaExercito(false))
      yield put(setTentativasExercito(data.tentativas))
      yield payload.callback(false, data.tentativas)
    } else {
      yield put(setSituacao(data.situacaoFuncional))
      yield put(setIndicativo(data.indicativo))
      yield put(setDataIngresso(data.dataIngresso))
      yield put(setAverbacaoValidaExercito(true))
      yield put(setTentativasExercito(0))
      yield payload.callback(true, 0)
    }
    yield put(setLoadingExercito(false))
  } catch (error) {
    const erroTyped = error as ErrorType
    if (
      erroTyped &&
      erroTyped?.status === 400 &&
      erroTyped?.data?.message === 'Matrícula invalida'
    ) {
      yield put(setIsMatriculaValida(false))
    } else {
      yield put(
        showError({
          title: 'Erro ao consultar margem',
          message: 'Ocorreu um erro ao consultar margem.',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.NO_0010_CONSULTA_MARGEM_EXERCITO,
          actionCallback: () => postValidaSenhaExercito(payload),
        }),
      )
    }

    yield put(setLoadingExercito(false))
  }
}
