import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'
import axios from 'axios'

import { ApplicationState } from '@store/types'
import { HttpStatus } from '@services/base'
import getValidaCodigo from '@services/getValidaToken'

import { ValidateTokenRequestPayload } from '../types'
import { setError, tokenSuccess } from '../actions'
import { buildValidateToken } from './models'

interface ErrorType {
  response: { status: HttpStatus; data: { mensagem: string } }
  status: HttpStatus
}

const verificarErro = (error: {
  response: { status: HttpStatus; data: { mensagem: string } }
  status: HttpStatus
}) =>
  axios.isAxiosError(error) &&
  (error.response?.status === HttpStatus.PRECONDITITON_FAILED ||
    error.response?.status === HttpStatus.BAD_REQUEST)

export default function* validateTokenSaga(action: AnyAction) {
  const payload = action.payload as ValidateTokenRequestPayload
  const appState = yield* select((state: ApplicationState) => state)

  try {
    const bodyToken = buildValidateToken[payload.stateType](appState, payload.token, payload.type)
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)

    const { data } = yield getValidaCodigo(bodyToken, payload.recaptchaToken, newSimulatorApi)
    if (data && data.tokenValido) {
      yield* put(tokenSuccess())
      if (payload?.success) {
        yield payload.success()
      }
    } else {
      yield* put(
        setError({
          title: 'Token inválido!',
          mensagem: 'Por favor, confirme o token e tente novamente.',
          step: 'validate-token',
        }),
      )
    }
  } catch (error) {
    if (verificarErro(error as ErrorType)) {
      yield* put(
        setError({
          title: 'Token inválido!',
          mensagem: 'Por favor, confirme o token e tente novamente.',
          step: 'validate-token',
        }),
      )
    } else {
      yield* put(
        setError({
          title: 'Ocorreu um erro!',
          mensagem:
            'Aconteceu um erro interno ao tentar validar seu token! Por favor tente novamente mais tarde.',
          step: 'validate-token',
        }),
      )
    }
  }
}
