import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { TipoEmprestimo } from '@utils/enums'
import { getParcelaAtualSimulacoes } from '@utils/functions'
import getSimulacaoSangueLaranja from '@services/getSimulacaoSangueLaranja'
import { ApplicationState } from '@store/types'
import {
  SimulacaoNovoResponse,
  SimulacaoRequest,
  SimulacoesRequest,
  SimulacoesSangueLaranjaRequest,
} from '@services/postSimulacaoNovo'
import { setMatricula } from '@store/novo/situacaoFuncional/actions'
import { showError } from '@store/ui/error/actions'

import {
  hasObjetoSimulacao,
  hasObjetoSimulacaoProtegida,
  selectSimulacao,
  selectSimulacoes,
  setParcelaSelecionada,
  setTotalParcelas,
  simulacaoError,
  simulacaoSuccess,
} from '../actions'
import { SimulacaoSangueLaranjaRequestPayload } from '../types'

interface ErrorType {
  status: number
  data: {
    message: string
  }
}

const mapObjetoSimulacao = (
  objSimulacaoRequest: SimulacoesSangueLaranjaRequest | undefined,
): SimulacaoRequest => {
  if (!objSimulacaoRequest) {
    return {} as SimulacaoRequest
  }

  return {
    ...objSimulacaoRequest,
    cETAa: objSimulacaoRequest.taxaCetAa,
    tIRAm: objSimulacaoRequest.taxaTirAm,
    tIRAa: objSimulacaoRequest.taxaTirAa,
    cETAm: objSimulacaoRequest.taxaCetAm,
  }
}

const mapArraySimulacoes = (
  objSimulacaoRequest: SimulacoesSangueLaranjaRequest[],
): SimulacoesRequest[] =>
  objSimulacaoRequest?.map((obj) => ({
    ...obj,
    tIRAm: obj.taxaTirAm,
    tIRAa: obj.taxaTirAa,
    cETAm: obj.taxaCetAm,
    cETAa: obj.taxaCetAa,
  }))

export default function* getSimulacaoSangueLaranjaRequest(action: AnyAction) {
  const payload = action.payload as SimulacaoSangueLaranjaRequestPayload

  try {
    const cpfUser = yield* select((state: ApplicationState) => state.novo.dadosPessoais.cpf)
    const { data } = yield* call(getSimulacaoSangueLaranja, cpfUser, payload.valorSolicitacao)

    const objSimulacao: SimulacaoNovoResponse = {
      limiteCartao: data?.limiteCartao,
      margemConsultada: data?.margemConsultada,
      simulacao: mapObjetoSimulacao(data?.simulacoesSemSeguro?.[0] ?? undefined),
      simulacoes: mapArraySimulacoes(data?.simulacoesSemSeguro),
      simulacaoComSeguro: mapObjetoSimulacao(data?.simulacoesComSeguro?.[0] ?? undefined),
      simulacoesComSeguro: mapArraySimulacoes(data?.simulacoesComSeguro),
      valorMaximo: data?.valorMaximo,
      valorMinimo: data?.valorMinimo,
      valorMaximoComSeguro: data?.valorMaximo,
    }

    yield put(simulacaoSuccess(objSimulacao))
    yield put(setMatricula(data?.matricula))
    yield put(hasObjetoSimulacao(Boolean(objSimulacao?.simulacao)))
    yield put(hasObjetoSimulacaoProtegida(Boolean(objSimulacao?.simulacaoComSeguro)))

    if (objSimulacao?.simulacoes || objSimulacao?.simulacoesComSeguro) {
      const parcelaAtual =
        objSimulacao?.simulacoesComSeguro?.length > 0
          ? getParcelaAtualSimulacoes(objSimulacao.simulacoesComSeguro)
          : getParcelaAtualSimulacoes(objSimulacao.simulacoes)

      const tipoEmprestimo =
        objSimulacao?.simulacoesComSeguro?.length > 0
          ? TipoEmprestimo.CREDITO_PROTEGIDO_ARRAY
          : TipoEmprestimo.SEM_CREDITO_PROTEGIDO_ARRAY

      yield put(setParcelaSelecionada(parcelaAtual))
      yield put(setTotalParcelas(parcelaAtual.qtdeParcelas))
      yield put(selectSimulacoes(tipoEmprestimo))
      yield put(selectSimulacao(tipoEmprestimo))
    }

    if (!objSimulacao?.simulacoes.length && !objSimulacao?.simulacoesComSeguro.length) {
      yield payload.callback({ naoLiberado: true })
    } else {
      yield payload.callback({
        naoLiberado: false,
      })
    }
  } catch (error) {
    const erroTyped = error as ErrorType
    yield put(simulacaoError())
    if (erroTyped && erroTyped?.status === 412) {
      yield payload.callback({ naoLiberado: false, propostaEmAndamento: true })
    } else if (erroTyped?.status === 406) {
      yield put(
        showError({
          title: 'Atenção!',
          message: 'É necessário aguardar 60 dias para realizar uma nova simulação',
          actionTitle: 'Entendi',
        }),
      )
      yield payload.callback({ naoLiberado: false, propostaEmAndamento: true })
    } else {
      yield payload.callback({ naoLiberado: true })
    }
  }
}
