import { NovoState } from '@store/novo'
import { FamiliaEnum } from '@utils/simuladorUtils'
import { SessionState } from '@store/session/types'
import { UiState } from '@store/ui'
import { TipoEmprestimo } from '@utils/enums'
import { TrackingSimulacaoRequest } from '@services/postTrackingSimulacao'
import { FontePagamentoState } from '@store/novo/fontePagamento/types'
import { BeneficiarioInssState } from '@store/novo/consultasMargem/consultaBeneficiarioInss/types'

const isInss = (fontePagamento: FontePagamentoState, beneficiarioInss: BeneficiarioInssState) => {
  const { inssViaUrl } = fontePagamento
  const { simplificadaInss } = beneficiarioInss

  return Boolean(inssViaUrl || simplificadaInss)
}

export const buildTrackingSimulacao = (
  novo: NovoState,
  session: SessionState,
  ui: UiState,
  step: string,
): TrackingSimulacaoRequest => {
  const trackingRequest: TrackingSimulacaoRequest = {
    origem: ui?.navigation?.origem,
    session: session?.sessionId,
    step,
  }
  const tipoSimulacao = novo.simulacao?.simulacaoSelecionada
  const tipoSimulacoes = novo.simulacao?.simulacoesSelecionada
  const verificarCreditoProtegido = () => {
    if (ui?.navigation.newSimulatorApi) {
      return tipoSimulacoes === TipoEmprestimo.CREDITO_PROTEGIDO_ARRAY
    }
    return tipoSimulacao === TipoEmprestimo.CREDITO_PROTEGIDO
  }

  const {
    simulacao,
    dadosPessoais,
    simulador,
    situacaoFuncional,
    dadosProfissionais,
    fontePagamento,
    codIndicacaoContato,
    beneficiarioInss,
  } = novo

  if (simulador) {
    trackingRequest.familia =
      simulador.familia === FamiliaEnum.EMPRESA ? 'privado' : simulador?.familia
    trackingRequest.tipoSimulacao = simulador?.isTotal ? 'Valor Total' : 'Valor Parcela'
    trackingRequest.valorFinanciado = simulador?.valorTotal || 0
  }

  if (dadosPessoais) {
    trackingRequest.dataNascimento = dadosPessoais?.dataNascimento
    trackingRequest.flagTitularDados = dadosPessoais?.flagTitularDados
  }
  if (codIndicacaoContato) {
    trackingRequest.flagInfoCredito = codIndicacaoContato?.flagInfoCredito
  }

  if (dadosProfissionais) {
    trackingRequest.empresa = dadosProfissionais?.nomeEmpresa || ''
    trackingRequest.tempoEmpresa = dadosProfissionais?.tempoEmpresaSelecionado || ''
    trackingRequest.salarioBruto = dadosProfissionais?.salario || 0
  }

  if (situacaoFuncional) {
    trackingRequest.matricula = situacaoFuncional?.matricula || ''
    trackingRequest.salarioBruto = situacaoFuncional?.salario || 0
  }
  if (fontePagamento) {
    trackingRequest.convenioId = isInss(fontePagamento, beneficiarioInss)
      ? 6
      : Number(fontePagamento?.convenioSelecionado)
    trackingRequest.convenio = isInss(fontePagamento, beneficiarioInss)
      ? 'INSS'
      : fontePagamento?.convenios?.find(
          (convenio) => convenio.codigo === fontePagamento?.convenioSelecionado,
        )?.descricao || ''
    trackingRequest.descricaoConvenio = isInss(fontePagamento, beneficiarioInss)
      ? 'INSS'
      : fontePagamento?.convenios?.find(
          (convenio) => convenio.codigo === fontePagamento?.convenioSelecionado,
        )?.descricao || ''
    trackingRequest.orgao = isInss(fontePagamento, beneficiarioInss)
      ? '12'
      : String(fontePagamento?.orgaoSelecionado) || ''
    trackingRequest.descricaoOrgao = isInss(fontePagamento, beneficiarioInss)
      ? 'INSS'
      : fontePagamento?.orgaos?.find(
          (orgao) => orgao.codigo === Number(fontePagamento?.orgaoSelecionado),
        )?.descricao || ''
    trackingRequest.autarquiaEmpresa = isInss(fontePagamento, beneficiarioInss)
      ? 'INSS'
      : fontePagamento?.orgaos?.find(
          (orgao) => orgao.codigo === Number(fontePagamento?.orgaoSelecionado),
        )?.descricao || ''
    trackingRequest.tipoConvenio = isInss(fontePagamento, beneficiarioInss)
      ? 6
      : fontePagamento?.convenioSelecionado || ''
  }

  if (simulacao) {
    const simulacaoSelecionada = !ui.navigation.newSimulatorApi
      ? simulacao[tipoSimulacao]
      : simulacao.parcelaAtual
    trackingRequest.valorLiberado = simulacaoSelecionada?.valorLiberado || 0
    trackingRequest.qtdParcelas = simulacaoSelecionada?.qtdeParcelas || 0
    trackingRequest.quantidadeParcelas = simulacaoSelecionada?.qtdeParcelas || 0
    trackingRequest.valorIOF = simulacaoSelecionada?.valorIOF || 0
    trackingRequest.valorParcela = simulacaoSelecionada?.valorParcela || 0
    trackingRequest.taxa = simulacaoSelecionada?.taxaNominalAm || 0
    trackingRequest.valorEmprestimo = simulacaoSelecionada?.valorLiberado || 0
    trackingRequest.creditoProtegido = verificarCreditoProtegido()
    trackingRequest.cartaoConsignado = false
    trackingRequest.valorFinanciado = simulacaoSelecionada?.valorFinanciado || 0
    trackingRequest.valorSolicitado = simulacaoSelecionada?.valorSolicitado || 0
    trackingRequest.dataUltimoVencimento = simulacaoSelecionada?.dataUltimoVencimento || ''
    trackingRequest.tIRAM = simulacaoSelecionada?.tIRAm || 0
    trackingRequest.valorCET = simulacaoSelecionada?.cETAa || 0
    trackingRequest.valorCartaoConsignado = simulacao?.limiteCartao
  }

  return trackingRequest
}
