import React, { memo } from 'react'

import Input from '@atoms/Input'

interface OwnProps {
  id: string
  label?: string
  placeholder?: string
  infoButton?: JSX.Element
  matricula: string | number | undefined
  matriculaError?: boolean
  onChange: (ev: string) => void
  onBlur?: () => void
  maxLength?: number
  disabled?: boolean
  invalidText?: string
}
const MatriculaInput = ({
  id,
  label,
  placeholder,
  infoButton,
  matricula,
  matriculaError = false,
  invalidText,
  onChange,
  onBlur,
  maxLength,
  disabled,
}: OwnProps) => (
  <Input
    id={id}
    dataTestId={id}
    label={label}
    placeholder={placeholder}
    value={matricula}
    invalid={matriculaError}
    invalidText={invalidText}
    onChange={(e) => onChange(e.target.value)}
    onBlur={onBlur}
    infoButton={infoButton}
    maxLength={maxLength}
    disabled={disabled}
  />
)

MatriculaInput.defaultProps = {
  label: 'Qual é sua matrícula?',
  placeholder: 'Informe os números',
  matriculaError: false,
  maxLength: undefined,
  onBlur: undefined,
  disabled: false,
  invalidText: 'Matrícula não encontrada',
}

export default memo(MatriculaInput)
