import { BaseServices, HttpMethod } from './base'

export default async (data: PostAutorizacao) => {
  const pathname = process.env.REACT_APP_POST_AUTORIZACAO_ISAFE
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<void>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface PostAutorizacao {
  token: string
  tipoAutenticacao: string
  valor: string
}
