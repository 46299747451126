import { Reducer } from 'redux'

import { CodigoValidacao } from '@utils/enums'

import { CodIndicacaoContatoState, CodIndicacaoContatoTypes } from './types'

const INITIAL_STATE: CodIndicacaoContatoState = {
  codigoIndicacao: '',
  telefone: '',
  email: '',
  checkAutorizacao: false,
  codigoError: false,
  codigoLoading: false,
  validacaoCodigoMgm: CodigoValidacao.VAZIO,
  flagInfoCredito: true,
  editarCodigo: false,
}

const reducer: Reducer<CodIndicacaoContatoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CodIndicacaoContatoTypes.SET_CODIGO_INDICACAO:
      return { ...state, codigoIndicacao: action.payload }
    case CodIndicacaoContatoTypes.SET_TELEFONE:
      return { ...state, telefone: action.payload }
    case CodIndicacaoContatoTypes.SET_EMAIL:
      return { ...state, email: action.payload }
    case CodIndicacaoContatoTypes.SET_CHECK_AUTORIZACAO:
      return { ...state, checkAutorizacao: action.payload }
    case CodIndicacaoContatoTypes.VALIDA_INDICACAO_REQUEST:
      return { ...state, codigoError: false, codigoLoading: true }
    case CodIndicacaoContatoTypes.VALIDA_INDICACAO_ERROR:
      return { ...state, codigoError: true, codigoLoading: false }
    case CodIndicacaoContatoTypes.VALIDA_INDICACAO_SUCCESS:
      return { ...state, codigoError: false, codigoLoading: false }
    case CodIndicacaoContatoTypes.SET_VALIDACAO_CODIGO_MGM:
      return { ...state, validacaoCodigoMgm: action.payload }
    case CodIndicacaoContatoTypes.RESET_INDICACAO:
      return {
        ...state,
        codigoError: false,
        codigoLoading: false,
      }
    case CodIndicacaoContatoTypes.DATA_LAKE_CONTATO_REQUEST:
      return { ...state, codigoLoading: true, codigoError: false }
    case CodIndicacaoContatoTypes.DATA_LAKE_CONTATO_SUCCESS:
      return { ...state, codigoLoading: false, codigoError: false }
    case CodIndicacaoContatoTypes.DATA_LAKE_CONTATO_ERROR:
      return { ...state, codigoLoading: false, codigoError: true }
    case CodIndicacaoContatoTypes.SET_FLAG_INFO_CREDITO:
      return { ...state, flagInfoCredito: action.payload }
    case CodIndicacaoContatoTypes.SET_EDITAR_CODIGO:
      return { ...state, editarCodigo: action.payload }
    case CodIndicacaoContatoTypes.RESET_INDICACAO_APP:
      return {
        ...state,
        codigoError: false,
        codigoLoading: false,
        editarCodigo: false,
        validacaoCodigoMgm: CodigoValidacao.VAZIO,
        codigoIndicacao: '',
      }

    case CodIndicacaoContatoTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
