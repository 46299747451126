import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import { estadosSuccess, estadosError, estadosLoading, estadosRequest } from '../actions'
import { Estado, EstadosAPI } from '../types'

const estadosBuild = (data: EstadosAPI): Estado[] =>
  data && data?.uf.map((estado) => ({ uf: estado, nome: estado }))

export default function* getEstados() {
  try {
    yield* put(estadosLoading())
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const response = yield* call(services.getEstados, newSimulatorApi)
    yield* put(estadosSuccess(estadosBuild(response.data)))
  } catch (error) {
    yield* put(estadosError())
    yield* put(
      showError({
        title: 'Erro ao buscar os estados',
        message: 'Ocorreu um erro listagem de estados.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_003_ESTADOS,
        actionCallback: () => estadosRequest(),
      }),
    )
  }
}
