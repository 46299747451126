import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import postVerificarPendenciaLead, {
  VerificaPendenciaLeadRequest,
} from '@services/postVerificaPendenciaLead'
import { ApplicationState } from '@store/types'
import { verificaPendenciaLeadRequest } from '@store/portabilidade/simulacao/actions'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'

import { buildVerificaPendenciaLeadRequest } from './models'
import { VerificaPendenciaLeadPayload } from '../types'

export default function* postVerificaPendenciaLead(action: AnyAction) {
  const payload = action.payload as VerificaPendenciaLeadPayload
  try {
    const state = yield* select((s: ApplicationState) => s)
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const lead: VerificaPendenciaLeadRequest = buildVerificaPendenciaLeadRequest(state)
    yield* call(
      postVerificarPendenciaLead,
      lead,
      String(state.novo.dadosPessoais.cpf),
      newSimulatorApi,
    )
    yield payload.callback()
  } catch (error) {
    yield* put(
      showError({
        title: 'Erro ao salvar',
        message: 'Ocorreu um erro ao salvar suas informações',
        actionTitle: 'Tentar Novamente',
        block: true,
        code: ErrorCodes.P0_010_VERIFICA_PENDENCIA_LEAD,
        actionCallback: () => verificaPendenciaLeadRequest(payload),
      }),
    )
  }
}
