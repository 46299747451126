import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { removeMask } from '@utils/masks'
import { setPendencia } from '@store/novo/pendencia/actions'
import { setNovoValorTotal, setValorTotal } from '@store/novo/simulador/actions'
import { PendenciaEnum, PendenciaEnumV3 } from '@utils/enums'
import { setMatricula, setSalario } from '@store/novo/situacaoFuncional/actions'

import {
  dadosBeneficiarioInssError,
  dadosBeneficiarioInssSuccess,
  setLoadingInssApis,
  setRecallInss,
} from '../actions'
import { DadosInssPayload } from '../types'

export default function* getDadosInssResultSaga(action: AnyAction) {
  const payload = action.payload as DadosInssPayload
  const cpf = yield* select((state: ApplicationState) => state.novo.dadosPessoais.cpf)
  const { counterRecall } = yield* select((state: ApplicationState) => state.novo.beneficiarioInss)
  yield put(setPendencia(''))

  try {
    yield put(setLoadingInssApis(true))
    const { data } = yield services.getDadosInssResult(removeMask(cpf))
    yield put(dadosBeneficiarioInssSuccess(data))
    yield put(setPendencia(data?.pendencia))
    if (data?.infoInss?.margem) {
      yield put(setNovoValorTotal(data?.infoInss?.margem))
      yield put(setValorTotal(data?.infoInss?.margem))
    }
    if (data?.infoInss?.valorSalarioLiquido) {
      yield put(setSalario(data?.infoInss?.valorSalarioLiquido))
    }
    if (data?.infoInss?.numeroBeneficio) {
      yield put(setMatricula(data?.infoInss?.numeroBeneficio))
    }
    yield put(setLoadingInssApis(false))
    if (data?.pendencia === PendenciaEnumV3.PENDENCIA_VALIDACAO) {
      yield put(setRecallInss(1))
    }
    if (counterRecall > 2) {
      // Tenta até 3 vezes chamar a api de resultados, se a mesma permanecer "em validação" leva para tela de não liberado
      yield put(setLoadingInssApis(false))
      yield payload.callback(false, PendenciaEnum.PENDENCIA_IRREVERSIVEL, false)
    } else {
      yield payload.callback(
        data?.isConsultaRealizada,
        data?.pendencia,
        data?.isBeneficioValido,
        data?.isHorarioValido,
      )
    }
  } catch (error) {
    yield put(setLoadingInssApis(false))
    yield put(dadosBeneficiarioInssError())
    yield payload.callback(false, PendenciaEnum.PENDENCIA_IRREVERSIVEL, false)
  }
}
