import React from 'react'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import HeaderAndFooter from '@components/templates/HeaderAndFooter'
import { GoBackConfig } from '@utils/goBackConfig'
import { BridgeService } from '@services/bridge'

import Privado from './Privado'
import routes from './routes'
import SimuladorNovo from './SimuladorNovo'
import SimuladorApp from './SimuladorApp'
import SimuladorV3 from './SimuladorV3'

const Routes: React.FC = () => (
  <>
    {BridgeService.isBrowser() ? (
      <GoogleReCaptchaProvider
        reCaptchaKey={String(process.env.REACT_APP_RECAPTCHA_SITE_KEY)}
        useEnterprise
      >
        <BrowserRouter>
          <GoBackConfig />
          <HeaderAndFooter>
            <Switch>
              <Route path={routes.NOVO} component={Privado} />
            </Switch>
            <Switch>
              <Route path={routes.SIMULADOR_NOVO} component={SimuladorNovo} />
            </Switch>
            <Switch>
              <Route path={routes.SIMULADOR_APP} component={SimuladorApp} />
            </Switch>
            <Switch>
              <Route path={routes.SIMULADOR_V3} component={SimuladorV3} />
            </Switch>
          </HeaderAndFooter>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    ) : (
      <BrowserRouter>
        <GoBackConfig />
        <Switch>
          <Route path={routes.NOVO} component={Privado} />
        </Switch>
        <Switch>
          <Route path={routes.SIMULADOR_NOVO} component={SimuladorNovo} />
        </Switch>
        <Switch>
          <Route path={routes.SIMULADOR_APP} component={SimuladorApp} />
        </Switch>
        <Switch>
          <Route path={routes.SIMULADOR_V3} component={SimuladorV3} />
        </Switch>
      </BrowserRouter>
    )}
  </>
)
export default Routes
