import { BaseServices, HttpMethod } from '@services/base'

export default (data: SalvarSimulacaoSangueLaranjaRequest) => {
  const pathname = process.env.REACT_APP_SIMULACAO_SANGUE_LARANJA_SALVAR_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ResponseSalvarSimulacaoSangueLaranja>({
    pathname,
    method,
    host,
    data,
  })

  return response
}

/**
 * API Types
 */
export interface SalvarSimulacaoSangueLaranjaRequest {
  cpf: string
  email: string
  telefone: string
  matricula: string
  qtdeParcelas: number
  valorSolicitado: number
  valorParcela: number
  valorLiberado: number
  valorIOF: number
  valorIOFPercentual: number
  valorSeguro: number
  valorSeguroPercentual: number
  tarifaCadastro: number
  tarifaCadastroPercentual: number
  valorFinanciado: number
  txIRAm: number
  txCETAa: number
  txCETAm: number
  txIRAa: number
  taxaNominalAm: number
  taxaNomialAa: number
  dataUltimoVencimento: string
  dataPrimeiroVencimento: string
  jurosContrados: number
  jurosContradosPercentual: number
  valorTotalASerPago: number
}

export interface ResponseSalvarSimulacaoSangueLaranja {
  data: {
    codigoProposta: string
  }
}
