import { put, select } from 'typed-redux-saga'

import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { removeMask } from '@utils/masks'

import { scheduleSimulationRequest, setScheduleSimulationId } from '../actions'

export default function* postScheduleSimulationSaga() {
  const novoState = yield* select((state: ApplicationState) => state.novo)

  const modelBody = {
    origem: 'NOVO',
    beneficio: removeMask(String(novoState.situacaoFuncional.matricula)),
    documento: novoState.dadosPessoais.cpf,
    idConvenio: Number(novoState.fontePagamento.convenioSelecionado) || 0,
    convenio:
      novoState.fontePagamento?.convenios?.find(
        (convenio) => convenio.codigo === Number(novoState.fontePagamento?.convenioSelecionado),
      )?.descricao || 'INSS',
    idOrgao: Number(novoState.fontePagamento?.orgaoSelecionado) || 0,
    orgao:
      novoState.fontePagamento?.orgaos?.find(
        (orgao) => orgao.codigo === Number(novoState.fontePagamento?.orgaoSelecionado),
      )?.descricao || 'INSS',
    email: novoState.codIndicacaoContato?.email,
    telefone: removeMask(novoState.codIndicacaoContato?.telefone),
  }

  try {
    const { data } = yield services.postScheduleSimulation(modelBody)
    yield put(setScheduleSimulationId(data.id))
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao enviar dados para simulação',
        message: 'Ocorreu um erro ao enviar dados para simulação',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => scheduleSimulationRequest(),
      }),
    )
  }
}
