import React, { useState, useEffect, memo, useCallback } from 'react'

import clsx from 'clsx'
import { useSelector } from 'react-redux'

import { useGetRecaptchaToken } from '@hooks'
import { InterUIButton } from '@interco/inter-ui-react-lib'
import { P, Colors } from '@interco/cp-react-ui-lib'
import { Grid } from '@material-ui/core'
import { ocultarTelefone, ocultarEmail } from '@utils/strings'
import { InnerLoading } from '@components/molecules'
import { BottomSheetStyled } from '@molecules/Dialog/styles'
import { ApplicationState } from '@store/types'

import InputToken from '../../molecules/InputToken/index'
import ErrorToken from './ErrorToken'
import ChoiceTypeToken from './ChoiceTypeToken'
import { Props, connector, TypeToken } from './types'

const RESEND_TIMER = 60

const DialogToken = ({
  open,
  showChoice,
  tokenType,
  typeValues,
  loading,
  error,
  titleError,
  stepError,
  stateType,
  dispatch,
  onClose,
  onSuccess,
  sendToken,
  validateToken,
  errorCallBack,
  resetToken,
}: Props) => {
  const [typeSelected, setTypeSelected] = useState<TypeToken>(undefined)
  const [token, setToken] = useState('')
  const [disableConfirm, setDisableConfirm] = useState(true)
  const [timer, setTimer] = useState(RESEND_TIMER)
  const { getRecaptchaToken } = useGetRecaptchaToken()
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  const sendTokenIfOpen = useCallback(async () => {
    if (!isApp) {
      if (open) {
        if (!showChoice) {
          const recaptchaToken = await getRecaptchaToken()
          sendToken({ type: tokenType, stateType, recaptchaToken })
        }
      }
      setTypeSelected(undefined)
      setTimer(RESEND_TIMER)
    } else {
      if (open) {
        if (!showChoice) {
          sendToken({ type: tokenType, stateType, recaptchaToken: 'NULLCAPTCHA' })
        }
      }
      setTypeSelected(undefined)
      setTimer(RESEND_TIMER)
    }
  }, [isApp, open, showChoice, getRecaptchaToken, sendToken, tokenType, stateType])

  useEffect(() => {
    sendTokenIfOpen()
  }, [sendTokenIfOpen])

  useEffect(() => {
    if (!showChoice) {
      setTypeSelected(tokenType)
      setTimer(RESEND_TIMER)
    }
  }, [tokenType, showChoice])

  useEffect(() => {
    const time = setTimeout(() => {
      if (timer) {
        setTimer(timer - 1)
      }
    }, 1000)
    return () => clearTimeout(time)
  })

  const setTypeAndCreateToken = useCallback(
    async (t: TypeToken) => {
      if (!isApp) {
        const recaptchaToken = await getRecaptchaToken()
        setTypeSelected(t)
        sendToken({ type: t, stateType, recaptchaToken })
      } else {
        setTypeSelected(t)
        sendToken({ type: t, stateType, recaptchaToken: 'NULLCAPTCHA' })
      }
    },
    [isApp, getRecaptchaToken, sendToken, stateType],
  )

  const validateTokenClick = useCallback(async () => {
    if (!isApp) {
      const recaptchaToken = await getRecaptchaToken()
      validateToken({
        token,
        stateType,
        type: typeSelected || tokenType,
        recaptchaToken,
        success: () => {
          onSuccess()
          onClose()
        },
      })
    } else {
      validateToken({
        token,
        stateType,
        type: typeSelected || tokenType,
        recaptchaToken: 'NULLCAPTCHA',
        success: () => {
          onSuccess()
          onClose()
        },
      })
    }
  }, [
    isApp,
    getRecaptchaToken,
    validateToken,
    token,
    stateType,
    typeSelected,
    tokenType,
    onSuccess,
    onClose,
  ])

  const textContentDialogToken = () => ({
    sms: {
      title: 'Código SMS',
      description: (
        <P fontSize="14px" lineHeight="17px" margin="8px 0 0">
          {'Enviamos um SMS com o código de verificação para o número '}
          <strong>{ocultarTelefone(typeValues?.telefone || '')}</strong>
          {'. Informe os 6 dígitos que você recebeu: '}
        </P>
      ),
    },
    email: {
      title: 'Código e-mail',
      description: (
        <P fontSize="14px" lineHeight="17px" margin="8px 0 0">
          {'Precisamos que você informe o código enviado para o e-mail '}
          <strong>{ocultarEmail(typeValues?.email || '')}</strong>
          {'. Informe os 6 dígitos que você recebeu: '}
        </P>
      ),
    },
  })

  return (
    <BottomSheetStyled
      onHide={onClose}
      title=""
      toggle={open}
      className={clsx(
        'MuiGrid-grid-xs-12',
        'MuiGrid-grid-sm-8',
        'MuiGrid-grid-md-6',
        'MuiGrid-grid-lg-4',
        'MuiGrid-grid-xl-4',
      )}
    >
      {!!loading && <InnerLoading height="150px" />}

      {!loading && !!error && (
        <ErrorToken
          titleError={titleError}
          errorMessage={error}
          onRetry={() => {
            resetToken()
            if (stepError !== 'validate-token') {
              if (errorCallBack instanceof Function) {
                dispatch(errorCallBack())
              }
            }
          }}
        />
      )}

      {!loading && !error && (
        <>
          {showChoice && !typeSelected ? (
            <ChoiceTypeToken setChoice={setTypeAndCreateToken} />
          ) : (
            <Grid container direction="column">
              <P
                variant="sora"
                fontWeight="600"
                fontSize="16px"
                lineHeight="20px"
                color={Colors.GRAY500}
              >
                {typeSelected !== undefined
                  ? textContentDialogToken()[typeSelected].title
                  : tokenType !== undefined &&
                    textContentDialogToken()[typeSelected || tokenType].title}
              </P>
              {typeSelected !== undefined
                ? textContentDialogToken()[typeSelected].description
                : tokenType !== undefined &&
                  textContentDialogToken()[typeSelected || tokenType].description}
              <InputToken
                id={`input-token-${typeSelected || tokenType}`}
                getToken={(t, filled) => {
                  setToken(t)
                  setDisableConfirm(!filled)
                }}
              />
              <InterUIButton
                variant="secondary"
                onClick={() => {
                  setToken('')
                  setTimer(RESEND_TIMER)
                  setTypeAndCreateToken(typeSelected || tokenType)
                }}
                style={{
                  margin: '10px auto 0',
                  border: 'none',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }}
                disabled={timer > 0}
              >
                {`Reenviar ${timer > 0 ? `(${timer}s)` : ''}`}
              </InterUIButton>
              <InterUIButton
                style={{ margin: '10px auto 0' }}
                disabled={disableConfirm || loading}
                onClick={validateTokenClick}
              >
                Confirmar
              </InterUIButton>
            </Grid>
          )}
        </>
      )}
    </BottomSheetStyled>
  )
}

DialogToken.defaultProps = {
  showChoice: false,
  tokenType: undefined,
}

export default connector(memo(DialogToken))
