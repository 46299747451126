import { action, Action } from 'typesafe-actions'

import { Convenio, Orgao } from '@store/globalApi/types'

import { ConvenioRequestPayload, FontePagamentoTypes } from './types'

export const fontesRequest = (): Action => action(FontePagamentoTypes.FONTES_REQUEST)

export const conveniosRequest = (payload: ConvenioRequestPayload): Action =>
  action(FontePagamentoTypes.CONVENIOS_REQUEST, payload)

export const conveniosSucces = (payload: Convenio[]): Action =>
  action(FontePagamentoTypes.CONVENIOS_SUCCESS, payload)

export const conveniosError = (): Action => action(FontePagamentoTypes.CONVENIOS_ERROR)

export const conveniosLoading = (): Action => action(FontePagamentoTypes.CONVENIOS_LOADING)

export const estadosRequest = (): Action => action(FontePagamentoTypes.ESTADOS_REQUEST)

export const cidadesRequest = (payload: string): Action =>
  action(FontePagamentoTypes.CIDADES_REQUEST, payload)

export const setFonteSelecionada = (
  payload: string | string[] | number[] | number | undefined,
): Action => action(FontePagamentoTypes.SET_FONTE_SELECIONADA, payload)

export const setConvenioSelecionado = (
  payload: string | string[] | number[] | number | undefined,
): Action => action(FontePagamentoTypes.SET_CONVENIO_SELECIONADO, payload)

export const setEstadoSelecionado = (
  payload: string | string[] | number[] | number | undefined,
): Action => action(FontePagamentoTypes.SET_ESTADO_SELECIONADO, payload)

export const setCidadeSelecionada = (
  payload: string | string[] | number[] | number | undefined,
): Action => action(FontePagamentoTypes.SET_CIDADE_SELECIONADA, payload)

export const setOrgaoSelecionado = (
  payload: string | string[] | number[] | number | undefined,
): Action => action(FontePagamentoTypes.SET_ORGAO_SELECIONADO, payload)

export const orgaosSuccess = (payload: Orgao[] | null): Action =>
  action(FontePagamentoTypes.ORGAOS_SUCCESS, payload)

export const setLoading = (payload: boolean): Action =>
  action(FontePagamentoTypes.SET_LOADING, payload)

export const orgaosError = (): Action => action(FontePagamentoTypes.ORGAOS_ERROR)

export const orgaosLoading = (): Action => action(FontePagamentoTypes.ORGAOS_LOADING)

export const orgaosRequest = (convenio: number): Action =>
  action(FontePagamentoTypes.ORGAOS_REQUEST, {
    convenio,
    tipoSimulacao: 'novo',
  })

export const setNomeConvenio = (payload: string): Action =>
  action(FontePagamentoTypes.SET_NOME_CONVENIO, payload)

export const setInssViaUrl = (payload: boolean): Action =>
  action(FontePagamentoTypes.INSS_VIA_URL, payload)
