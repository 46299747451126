import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import {
  contratosAtivosSuccess,
  contratosAtivosError,
  contratosAtivosLoading,
  contratosAtivosRequest,
} from '../actions'

export default function* getContratosAtivos() {
  try {
    yield* put(contratosAtivosLoading())
    const cpfUser = yield* select((state: ApplicationState) => state.novo.dadosPessoais.cpf)
    const response = yield* call(services.getContratosAtivos, cpfUser)

    yield* put(contratosAtivosSuccess(response.data.contratos))
  } catch (error) {
    yield* put(contratosAtivosError())
    yield* put(
      showError({
        title: 'Erro ao buscar os contratos ativos',
        message: 'Ocorreu um erro na listagem de contratos ativos.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_011_CONTRATOS_ATIVOS,
        actionCallback: () => contratosAtivosRequest(),
      }),
    )
  }
}
