import React from 'react'

import { Box } from './styles'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onClick: () => void
  actived: boolean
}

const BoxSelection = ({ children, onClick, actived }: Props) => (
  <Box onClick={onClick} actived={actived}>
    {children}
  </Box>
)

export default BoxSelection
