import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import {
  getCidades,
  getEstados,
  getFontesPagamento,
  getUpags,
  getConvenios,
  getOrgaos,
} from '@store/globalApi/sagas'

import postAutorizacaoISafe from './simulacao/sagas/postAutorizacaoISafe'
import { FontePagamentoTypes } from './fontePagamento/types'
import { SituacaoFuncionalTypes } from './situacaoFuncional/types'
import { SimulacaoTypes } from './simulacao/types'
import { ValidaTypes } from './valida/types'
import { postValida, postValidaPendenciaSaga, postValidaReforma } from './valida/sagas'
import {
  postSalvarLead,
  postTrackingSimulacao,
  postSimulacao,
  postSalvarLeadSangueLaranja,
} from './simulacao/sagas'
import { getValidaEmpresaSaga } from './dadosProfissionais/sagas'
import { DadosProfissionaisTypes } from './dadosProfissionais/types'
import { getValidaIndicacao, postDataLakeContatoSaga } from './codIndicacaoContato/sagas'
import { CodIndicacaoContatoTypes } from './codIndicacaoContato/types'
import { AutorizacaoAnuenciaTypes } from './consultasMargem/consultaMargemSiape/types'
import {
  postConsultarMargemSiapeSaga,
  postAtualizarCacheSiapeSaga,
  getCacheSiapeSaga,
} from './consultasMargem/consultaMargemSiape/sagas'
import { ConsultaMargemCipTypes } from './consultasMargem/consultaMargemCip/types'
import { postConsultarMargemCipSaga } from './consultasMargem/consultaMargemCip/sagas'
import { postValidarMatriculaSaga } from './situacaoFuncional/sagas'
import {
  getDadosBeneficiarioInssSaga,
  putScheduleSimulationSaga,
  postScheduleSimulationSaga,
  postInssAsyncStartSaga,
  getDadosInssResultSaga,
} from './consultasMargem/consultaBeneficiarioInss/sagas'
import { BeneficiarioInssTypes } from './consultasMargem/consultaBeneficiarioInss/types'
import { postValidaSenhaExercito } from './consultasMargem/consultaMargemExercito/sagas'
import { ConsultaExercitoTypes } from './consultasMargem/consultaMargemExercito/types'
import { ConsultaRioTypes } from './consultasMargem/consultaMargemRio/types'
import { postValidaSenhaRio } from './consultasMargem/consultaMargemRio/sagas'
import { AtendimentoTypes } from './atendimento/types'
import { postAtendimento } from './atendimento/sagas'
import { DadosPessoaisTypes } from './dadosPessoais/types'
import {
  getDadosUsuarioSaga,
  getDadosUsuarioPropostaSangueLaranjaSaga,
} from './dadosPessoais/sagas'
import { ConsultaMargemGeralTypes } from './consultasMargem/consultaMargemGeral/types'
import { postConsultarMargemGeralSaga } from './consultasMargem/consultaMargemGeral/sagas'
import getSimulacaoSangueLaranjaRequest from './simulacao/sagas/getSimulacaoSangueLaranja'
import postSalvarContratoSangueLaranjaSaga from './simulacao/sagas/postSalvarContratoSangueLaranja'
import putAtualizarPropostaSaga from './simulacao/sagas/putAtualizarPropostaSaga'
import getParcelaSimulacaoSangueLaranjaSaga from './simulacao/sagas/getParcelaSimulacaoSangueLaranja'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(FontePagamentoTypes.FONTES_REQUEST, getFontesPagamento)
  yield* takeLatest(FontePagamentoTypes.CONVENIOS_REQUEST, getConvenios)
  yield* takeLatest(FontePagamentoTypes.ESTADOS_REQUEST, getEstados)
  yield* takeLatest(FontePagamentoTypes.CIDADES_REQUEST, getCidades)
  yield* takeLatest(FontePagamentoTypes.ORGAOS_REQUEST, getOrgaos)
  yield* takeLatest(SituacaoFuncionalTypes.UPAGS_REQUESTS, getUpags)
  yield* takeLatest(SimulacaoTypes.SIMULACAO_REQUEST, postSimulacao)
  yield* takeLatest(
    SimulacaoTypes.SIMULACAO_SANGUE_LARANJA_REQUEST,
    getSimulacaoSangueLaranjaRequest,
  )
  yield* takeLatest(
    SimulacaoTypes.POST_CONTRATO_SANGUE_LARANJA_REQUEST,
    postSalvarContratoSangueLaranjaSaga,
  )
  yield* takeLatest(
    SimulacaoTypes.PUT_ATUALIZAR_CONTRATO_SANGUE_LARANJA_REQUEST,
    putAtualizarPropostaSaga,
  )
  yield* takeLatest(
    SimulacaoTypes.GET_PARCELA_SIMULACAO_SANGUE_LARANJA_REQUEST,
    getParcelaSimulacaoSangueLaranjaSaga,
  )
  yield* takeLatest(ValidaTypes.VALIDA_REQUEST, postValida)
  yield* takeLatest(SituacaoFuncionalTypes.VALIDAR_MATRICULA, postValidarMatriculaSaga)
  yield* takeLatest(SimulacaoTypes.SALVAR_LEAD_REQUEST, postSalvarLead)
  yield* takeLatest(SimulacaoTypes.SALVAR_LEAD_SANGUE_LARANJA_REQUEST, postSalvarLeadSangueLaranja)
  yield* takeLatest(SimulacaoTypes.TRACKING_SIMULACAO, postTrackingSimulacao)
  yield* takeLatest(DadosProfissionaisTypes.REQUEST_VALIDA_EMPRESA, getValidaEmpresaSaga)
  yield* takeLatest(CodIndicacaoContatoTypes.VALIDA_INDICACAO_REQUEST, getValidaIndicacao)
  yield* takeLatest(
    BeneficiarioInssTypes.DADOS_BENEFICIARIO_INSS_REQUEST,
    getDadosBeneficiarioInssSaga,
  )
  yield* takeLatest(CodIndicacaoContatoTypes.DATA_LAKE_CONTATO_REQUEST, postDataLakeContatoSaga)
  yield* takeLatest(ConsultaMargemCipTypes.CONSULTA_MARGEM_CIP_REQUEST, postConsultarMargemCipSaga)
  yield* takeLatest(
    ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_REQUEST,
    postConsultarMargemGeralSaga,
  )
  yield* takeLatest(
    AutorizacaoAnuenciaTypes.CONSULTAR_MARGEM_SIAPE_REQUEST,
    postConsultarMargemSiapeSaga,
  )
  yield* takeLatest(AutorizacaoAnuenciaTypes.ATUALIZAR_SIAPE_CACHE, postAtualizarCacheSiapeSaga)
  yield* takeLatest(AutorizacaoAnuenciaTypes.GET_SIAPE_CACHE, getCacheSiapeSaga)
  yield* takeLatest(ConsultaExercitoTypes.POST_VALIDA_SENHA_EXERCITO, postValidaSenhaExercito)
  yield* takeLatest(ValidaTypes.VALIDA_PENDENCIA_REQUEST, postValidaPendenciaSaga)
  yield* takeLatest(ValidaTypes.VALIDA_REFORMA_REQUEST, postValidaReforma)
  yield* takeLatest(ConsultaRioTypes.POST_VALIDA_SENHA_RIO, postValidaSenhaRio)
  yield* takeLatest(AtendimentoTypes.POST_ATENDIMENTO, postAtendimento)
  yield* takeLatest(DadosPessoaisTypes.GET_DADOS_USUARIO, getDadosUsuarioSaga)
  yield* takeLatest(
    DadosPessoaisTypes.GET_DADOS_USUARIO_PROPOSTA_SANGUE_LARANJA,
    getDadosUsuarioPropostaSangueLaranjaSaga,
  )

  yield* takeLatest(BeneficiarioInssTypes.REQUEST_SCHEDULE_SIMULATION, postScheduleSimulationSaga)
  yield* takeLatest(BeneficiarioInssTypes.UPDATE_SCHEDULE_SIMULATION, putScheduleSimulationSaga)
  yield* takeLatest(SimulacaoTypes.POST_ISAFE_AUTORIZACAO, postAutorizacaoISafe)
  yield* takeLatest(BeneficiarioInssTypes.DADOS_INSS_START_ASYNC, postInssAsyncStartSaga)
  yield* takeLatest(BeneficiarioInssTypes.DADOS_INSS_RESUlT_REQUEST, getDadosInssResultSaga)
}
