import { all } from 'typed-redux-saga'

import watchPortabilidade from './portabilidade/saga'
import watchGlobal from './globalApi/saga'
import watchNovo from './novo/saga'
import watchToken from './token/saga'

export default function* rootSaga() {
  yield* all([watchPortabilidade(), watchNovo(), watchToken(), watchGlobal()])
}
