import { createStyles } from '@material-ui/core/styles'

const styles = createStyles({
  pageContainer: {
    '@media (max-width: 767px)': {
      minHeight: 'calc(100vh - 148px)',
    },
    '@media (min-width: 767px) and (max-width: 991px)': {
      minHeight: 'calc(100vh - 180px)',
    },
  },
  pageWithHeader: {
    paddingTop: '0',
    '@media (min-width: 992px)': {
      minHeight: 'calc(100vh - 113px)',
    },
  },
  pageWithProgessStep: {
    minHeight: 'calc(100vh - 41px)',
  },
  pageWithHeaderAndProgessStep: {
    minHeight: 'calc(100vh - 154px)',
    '@media (max-width: 767px)': {
      minHeight: 'calc(100vh - 188px)',
    },
    '@media (min-width: 767px) and (max-width: 991px)': {
      minHeight: 'calc(100vh - 225px)',
    },
  },
})

export default styles
