import { createStyles } from '@material-ui/core'

const styles = createStyles({
  root: {
    position: 'relative',
  },
  top: {
    animationDuration: '800ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
})

export default styles
