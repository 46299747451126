import { BaseServices, HttpMethod } from '@services/base'

export default (data: IndicacaoPayload) => {
  const pathname = process.env.REACT_APP_POST_INDICACAO_WHATSAPP
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}

interface IndicacaoPayload {
  name: string
  socialId: string
  number: string
  queue: string
  manager: string
  description: string
  interNumber: string
  origin: string
}
