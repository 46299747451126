import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import postSimulacaoNovo, { SimulacaoNovoRequest } from '@services/postSimulacaoNovo'
import { ApplicationState } from '@store/types'
import { ConveniosCodes, TipoEmprestimo } from '@utils/enums'
import { getParcelaAtualSimulacoes } from '@utils/functions'

import {
  hasObjetoSimulacao,
  hasObjetoSimulacaoProtegida,
  selectSimulacoes,
  setParcelaSelecionada,
  setTotalParcelas,
  simulacaoError,
  simulacaoExercitoSuccess,
  simulacaoSuccess,
} from '../actions'
import { SimulacaoRequestPayload } from '../types'
import { buildPostSimulacao, buildPostSimulacaoInss } from './models/modelSimulacao'

export default function* postSimulacao(action: AnyAction) {
  const payload = action.payload as SimulacaoRequestPayload

  try {
    const novoState = yield* select((state: ApplicationState) => state.novo)
    const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)
    const simplificadaInss = yield* select(
      (state: ApplicationState) => state.novo.beneficiarioInss.simplificadaInss,
    )
    const { inssViaUrl } = yield* select((state: ApplicationState) => state.novo.fontePagamento)
    let simulacao: SimulacaoNovoRequest
    if (simplificadaInss || inssViaUrl) {
      simulacao = buildPostSimulacaoInss(novoState)
    } else {
      simulacao = buildPostSimulacao(novoState)
    }

    let pathFinal = ''
    if (!newSimulatorApi) {
      pathFinal = novoState.simulador.isTotal ? 'valor-liberado' : 'valor-parcela'
    } else {
      pathFinal = novoState.simulador.isTotal ? 'total-value' : 'installment'
    }

    const response = yield* call(postSimulacaoNovo, simulacao, pathFinal, newSimulatorApi)

    if (novoState.fontePagamento.convenioSelecionado === ConveniosCodes.EXERCITO_BR) {
      yield put(simulacaoExercitoSuccess(response?.data))
      yield put(hasObjetoSimulacaoProtegida(Boolean(response?.data?.simulacao)))
    } else {
      yield put(simulacaoSuccess(response?.data))
      yield put(hasObjetoSimulacao(Boolean(response?.data?.simulacao)))
      yield put(hasObjetoSimulacaoProtegida(Boolean(response?.data?.simulacaoComSeguro)))

      if (response.data?.simulacoes || response.data?.simulacoesComSeguro) {
        if (payload?.checkSeguro === false && response.data?.simulacoes) {
          const getParcelaAtual = getParcelaAtualSimulacoes(response.data.simulacoes)
          yield put(setParcelaSelecionada(getParcelaAtual))
          yield put(setTotalParcelas(getParcelaAtual.qtdeParcelas))
          yield put(selectSimulacoes(TipoEmprestimo.SEM_CREDITO_PROTEGIDO_ARRAY))
        } else if (
          response.data?.simulacoesComSeguro &&
          response.data?.simulacoesComSeguro?.length > 0
        ) {
          const getParcelaAtual = getParcelaAtualSimulacoes(response.data.simulacoesComSeguro)
          yield put(setParcelaSelecionada(getParcelaAtual))
          yield put(setTotalParcelas(getParcelaAtual.qtdeParcelas))
          yield put(selectSimulacoes(TipoEmprestimo.CREDITO_PROTEGIDO_ARRAY))
        } else if (response.data?.simulacoes && response.data?.simulacoes?.length > 0) {
          const getParcelaAtual = getParcelaAtualSimulacoes(response.data.simulacoes)
          yield put(setParcelaSelecionada(getParcelaAtual))
          yield put(setTotalParcelas(getParcelaAtual.qtdeParcelas))
          yield put(selectSimulacoes(TipoEmprestimo.SEM_CREDITO_PROTEGIDO_ARRAY))
        }
      }
    }

    if (
      newSimulatorApi &&
      !response.data?.simulacoes.length &&
      !response.data?.simulacoesComSeguro.length
    ) {
      yield payload.callback({ naoLiberado: true })
    } else {
      yield payload.callback({
        naoLiberado: false,
      })
    }
  } catch (error) {
    yield put(simulacaoError())
    yield payload.callback({ naoLiberado: true })
  }
}
