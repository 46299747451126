import { action, Action } from 'typesafe-actions'

import { ConsultaExercitoTypes, ValidaSenhaExercitoPayload } from './types'

export const setTentativasExercito = (payload: number): Action =>
  action(ConsultaExercitoTypes.SET_TENTATIVAS_EXERCITO, payload)

export const setAverbacaoValidaExercito = (payload: boolean): Action =>
  action(ConsultaExercitoTypes.SET_AVERBACAO_VALIDA_EXERCITO, payload)

export const setSenhaAverbacaoExercito = (payload: string): Action =>
  action(ConsultaExercitoTypes.SET_SENHA_AVERBACAO_EXERCITO, payload)

export const postValidaSenhaExercito = (payload: ValidaSenhaExercitoPayload): Action =>
  action(ConsultaExercitoTypes.POST_VALIDA_SENHA_EXERCITO, payload)

export const setLoadingExercito = (payload: boolean): Action =>
  action(ConsultaExercitoTypes.SET_LOADING_EXERCITO, payload)

export const clearErrorExercito = (): Action => action(ConsultaExercitoTypes.CLEAR_ERROR_EXERCITO)

export const setConsultaRealizada = (payload: boolean): Action =>
  action(ConsultaExercitoTypes.SET_CONSULTA_REALIZADA, payload)
