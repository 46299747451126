import { select, call, put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import postConsultaMargemGeral, {
  ConsultaMargemGeralRequest,
} from '@services/postConsultaMargemGeral'
import { showError } from '@store/ui/error/actions'
import { setPendencia } from '@store/novo/pendencia/actions'
import { setNovoValorTotal, setValorTotal } from '@store/novo/simulador/actions'
import { setMatricula } from '@store/novo/situacaoFuncional/actions'

import {
  consultaMargemGeralError,
  consultaMargemGeralRequest,
  consultaMargemGeralSuccess,
  consultaMargemGeralSuccessCallback,
  setValorMargem,
  setDataValidadeAutorizacao,
} from '../actions'
import { ConsultaMargemGeralPayload } from '../types'
import { modelMargemGeral } from './model'

export default function* postConsultaMargemGeralSaga(action: AnyAction) {
  const payload = action.payload as ConsultaMargemGeralPayload
  const { novo } = yield* select((s: ApplicationState) => s)
  const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)
  const bodyRequest: ConsultaMargemGeralRequest = modelMargemGeral(novo)
  yield put(setPendencia(''))

  try {
    const response = yield* call(postConsultaMargemGeral, bodyRequest, newSimulatorApi)

    if (response.data?.pendencia) yield put(setPendencia(response.data?.pendencia))

    if (response.data?.matricula) yield put(setMatricula(response.data.matricula))

    if (response.data?.margem) {
      yield put(consultaMargemGeralSuccess())
      yield put(setNovoValorTotal(response.data.margem))
      yield put(setValorTotal(response.data.margem))
      yield put(setValorMargem(response.data.margem))
      yield put(setDataValidadeAutorizacao(response.data.dataValidadeAutorizacao))
    } else {
      yield put(consultaMargemGeralSuccessCallback())
    }
    yield payload.callback(response.data.margem, response.data.tentativas, response.data.pendencia)
  } catch (error) {
    yield put(consultaMargemGeralError())
    yield put(
      showError({
        title: 'Erro ao consultar margem',
        message: 'Ocorreu um erro ao consultar margem.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => consultaMargemGeralRequest(payload),
      }),
    )
  }
}
