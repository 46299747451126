import { BaseServices, HttpMethod } from '@services/base'

export default (cpf: string) => {
  const pathname = process.env.REACT_APP_INSS_ASYNC_START
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const data = {
    socialNumber: String(cpf),
    source: 'NOVO',
  }

  return BaseServices.request({
    pathname,
    method,
    data,
    host,
  })
}
