import { SiapeAutorizacaoAnuenciaEnum, TipoConsultaAutorizacaoSiape } from '@utils/enums'

/**
 * Actions Types
 */

export enum AutorizacaoAnuenciaTypes {
  SET_AUTORIZACAO_ANUENCIA = '@novo/simulacao/SET_AUTORIZACAO_ANUENCIA',
  SET_SHOW_OPCOES_ANUENCIA = '@novo/simulacao/SET_SHOW_OPCOES_ANUENCIA',
  SET_ANUENCIA_AUTORIZADA = '@novo/simulacao/SET_ANUENCIA_AUTORIZADA',
  SET_CHECK_ANUENCIA_SIAPE = '@novo/simulacao/SET_CHECK_ANUENCIA_SIAPE',
  SET_PAGES_SIAPE_AUTORIZACAO = '@novo/simulacao/SET_PAGES_SIAPE_AUTORIZACAO',
  SET_TENTATIVAS = '@novo/siapeAutorizarAnuencia/SET_TENTATIVAS',
  CONSULTAR_MARGEM_SIAPE_REQUEST = '@novo/siapeAutorizarAnuencia/CONSULTAR_MARGEM_SIAPE_REQUEST',
  SET_CONSULTA_AUTORIZADA = '@novo/siapeAutorizarAnuencia/SET_CONSULTA_AUTORIZADA',
  SET_LOADING = '@novo/siapeAutorizarAnuencia/SET_LOADING',
  SET_PENDENCIA = '@novo/siapeAutorizarAnuencia/SET_PENDENCIA',
  SET_MARGEM = '@novo/siapeAutorizarAnuencia/SET_MARGEM',
  ATUALIZAR_SIAPE_CACHE = '@novo/siapeAutorizarAnuencia/ATUALIZAR_SIAPE_CACHE',
  GET_SIAPE_CACHE = '@novo/siapeAutorizarAnuencia/GET_SIAPE_CACHE',

  SET_PAGES_SIAPE_AUTORIZACAO_NOVO = '@novo/simulacao/SET_PAGES_SIAPE_AUTORIZACAO_NOVO',
  RESET_SIAPE = '@novo/siapeAutorizarAnuencia/RESET',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface SiapeAutorizacaoAnuenciaState {
  readonly autorizacaoSelecionada: TipoConsultaAutorizacaoSiape
  readonly anuenciaAutorizada: boolean
  readonly checkAnuenciaSiape: boolean
  readonly pageAtual: SiapeAutorizacaoAnuenciaEnum
  readonly tentativas: number
  readonly consultaAutorizada: boolean
  readonly loading: boolean
  readonly pendencia: string

  readonly pageAtualNovo: SiapeAutorizacaoAnuenciaEnum
  readonly margem: number
}

/**
 * Action Payload Types
 */

export interface ConsultarMargemSiapePayload {
  success: (pendencia: string) => void
  created: () => void
}
