/**
 * Actions Types
 */

export enum DadosProfissionaisTypes {
  SET_NOME_EMPRESA = '@novo/dadosProfissionais/SET_NOME_EMPRESA',
  SET_SALARIO = '@novo/dadosProfissionais/SET_SALARIO',
  SET_TEMPO_EMPRESA = '@novo/dadosProfissionais/SET_TEMPO_EMPRESA',
  REQUEST_VALIDA_EMPRESA = '@novo/dadosProfissionais/REQUEST_VALIDA_EMPRESA',
  SET_IS_EMPRESA_VALIDA = '@novo/dadosProfissionais/SET_IS_EMPRESA_VALIDA',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface DadosProfissionaisState {
  readonly nomeEmpresa: string
  readonly salario: number
  readonly tempoEmpresaSelecionado: string | number
  readonly error: boolean
  readonly loading: boolean
  readonly isEmpresaValida: boolean
}

export interface CallbackEmpresaType {
  naoLiberado: boolean
}

export type RequestEmpresaPayload = {
  callback: (params: CallbackEmpresaType) => void
}
