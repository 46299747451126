import { Reducer } from 'redux'

import { ConsultaExercitoTypes, ConsultaExercitoState } from './types'

const INITIAL_STATE: ConsultaExercitoState = {
  senhaAverbacaoExercito: '',
  averbacaoValidaExercito: true,
  tentativasExercito: 0,
  loadingExercito: false,
  consultaRealizada: false,
}

const reducer: Reducer<ConsultaExercitoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConsultaExercitoTypes.SET_AVERBACAO_VALIDA_EXERCITO:
      return { ...state, averbacaoValidaExercito: action.payload }
    case ConsultaExercitoTypes.SET_TENTATIVAS_EXERCITO:
      return { ...state, tentativasExercito: action.payload }
    case ConsultaExercitoTypes.SET_SENHA_AVERBACAO_EXERCITO:
      return { ...state, senhaAverbacaoExercito: action.payload }
    case ConsultaExercitoTypes.SET_LOADING_EXERCITO:
      return { ...state, loadingExercito: action.payload }
    case ConsultaExercitoTypes.CLEAR_ERROR_EXERCITO:
      return { ...state, averbacaoValidaExercito: true }

    default:
      return state
  }
}

export default reducer
