import React from 'react'

interface Props {
  style?: React.CSSProperties | undefined
}

const ArrowRigthIcon = ({ style }: Props) => (
  <svg
    width="7"
    height="12"
    style={style}
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.40845 1.3335L6.02268 6.00016L1.40845 10.6668"
      stroke="var(--neutral-theme)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

ArrowRigthIcon.defaultProps = {
  style: undefined,
}

export default ArrowRigthIcon
