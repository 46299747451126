import { select, call, put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import postConsultaMargemSiape, {
  ConsultaMargemSiapeApiRequest,
} from '@services/postConsultaMargemSiape'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { setPendencia } from '@store/novo/pendencia/actions'
import { setNovoValorTotal, setValorTotal } from '@store/novo/simulador/actions'

import { buildConsultaMargemSiape } from './models'
import {
  consultarMargemSiapeRequest,
  setConsultaAutorizada,
  setLoading,
  setMargem,
  setTentativas,
} from '../actions'
import { ConsultarMargemSiapePayload } from '../types'

export default function* postConsultaMargemSiapeSaga(action: AnyAction) {
  const payload = action.payload as ConsultarMargemSiapePayload

  yield put(setPendencia(''))
  try {
    yield put(setLoading(true))

    const { novo } = yield* select((s: ApplicationState) => s)
    const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)
    const body: ConsultaMargemSiapeApiRequest = buildConsultaMargemSiape(novo)
    const response = yield* call(postConsultaMargemSiape, body, newSimulatorApi)

    yield put(setLoading(false))
    if (response.data.margem) {
      yield put(setNovoValorTotal(response.data.margem))
      yield put(setValorTotal(response.data.margem))
      yield put(setMargem(response.data.margem))
    }
    if (response.data?.tentativas || response.data?.pendencia) {
      yield put(setTentativas(response.data?.tentativas))
      yield put(setPendencia(response.data?.pendencia))
      yield payload.success(response.data?.pendencia)
    } else {
      yield put(setConsultaAutorizada(true))
      yield payload.created()
    }
  } catch (error) {
    yield put(setLoading(false))
    yield put(
      showError({
        title: 'Erro ao consultar margem',
        message: 'Ocorreu um erro ao consultar margem.',
        actionTitle: 'Tentar Novamente',
        block: true,
        code: ErrorCodes.NO_009_CONSULTAR_MARGEM_SIAPE,
        actionCallback: () => consultarMargemSiapeRequest(payload),
      }),
    )
  }
}
