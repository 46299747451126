import { call, select } from 'typed-redux-saga'

import { ApplicationState } from '@store/types'
import { removeMask } from '@utils/masks'
import postCloudMarketRemove from '@services/postCloudMarketRemove'

export default function* postCloudMarketRemoveSaga() {
  try {
    const { cpf } = yield* select((s: ApplicationState) => s.novo.dadosPessoais)
    const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)
    yield* call(
      postCloudMarketRemove,
      {
        cpf_cnpj: removeMask(cpf),
        origem: 'ONBOARDING_PF',
        modalidade_operacao: 'CONSIGNADO',
      },
      newSimulatorApi,
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('​error', error)
  }
}
