/**
 * Actions Types
 */

export enum ConsultaRioTypes {
  SET_SENHA_AVERBACAO_RIO = '@novo/consultaRio/SET_SENHA_AVERBACAO_RIO',
  SET_AVERBACAO_VALIDA_RIO = '@novo/consultaRio/SET_AVERBACAO_VALIDA_RIO',
  SET_TENTATIVAS_RIO = '@novo/consultaRio/SET_TENTATIVAS_RIO',
  POST_VALIDA_SENHA_RIO = '@novo/consultaRio/POST_VALIDA_SENHA_RIO',
  SET_LOADING_RIO = '@novo/consultaRio/SET_LOADING_RIO',
  CLEAR_ERROR_RIO = '@novo/consultaRio/CLEAR_ERROR_RIO',
  SET_CONSULTA_REALIZADA = '@novo/consultaRio/SET_CONSULTA_REALIZADA',

  SET_ID_FUNCIONAL = '@novo/consultaRio/SET_ID_FUNCIONAL',
  SET_VINCULO = '@novo/consultaRio/SET_VINCULO',
  SET_NUMERO_PENSIONISTA = '@novo/consultaRio/SET_NUMERO_PENSIONISTA',
  SET_VALOR_MARGEM = '@novo/situacaoFuncional/SET_VALOR_MARGEM',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface ConsultaMargemRioState {
  readonly senhaAverbacaoRio: string
  readonly averbacaoValidaRio: boolean
  readonly tentativasRio: number
  readonly loadingRio: boolean
  readonly consultaRealizada: boolean
  readonly idFuncional: string
  readonly vinculo: string
  readonly numeroPensionista: string
  readonly valorMargem: number
}

export type ValidaSenhaRioPayload = {
  callback: (isValido: boolean | undefined, tentativas: number) => void
}
