import { Convenio, FontePagamento, Orgao } from '@store/globalApi/types'

/**
 * Actions Types
 */
export enum FontePagamentoTypes {
  FONTES_REQUEST = '@novo/fontePagamento/FONTES_REQUEST',
  CONVENIOS_REQUEST = '@novo/fontePagamento/CONVENIOS_REQUEST',
  CONVENIOS_SUCCESS = '@novo/fontePagamento/CONVENIOS_SUCCESS',
  CONVENIOS_ERROR = '@novo/fontePagamento/CONVENIOS_ERROR',
  CONVENIOS_LOADING = '@novo/fontePagamento/CONVENIOS_LOADING',
  ESTADOS_REQUEST = '@novo/fontePagamento/ESTADOS_REQUEST',
  CIDADES_REQUEST = '@novo/fontePagamento/CIDADES_REQUEST',
  SET_FONTE_SELECIONADA = '@novo/fontePagamento/SET_FONTE_PAGEMENTO',
  SET_CONVENIO_SELECIONADO = '@novo/fontePagamento/SET_CONVENIO_SELECIONADO',
  SET_ESTADO_SELECIONADO = '@novo/fontePagamento/SET_ESTADO_SELECIONADO',
  SET_CIDADE_SELECIONADA = '@novo/fontePagamento/SET_CIDADE_SELECIONADA',
  SET_ORGAO_SELECIONADO = '@novo/fontePagamento/SET_ORGAO_SELECIONADO',
  SET_LOADING = '@novo/fontePagamento/SET_LOADING',
  SET_NOME_CONVENIO = '@novo/fontePagamento/SET_NOME_CONVENIO',

  ORGAOS_SUCCESS = '@novo/fontePagamento/ORGAOS_SUCCESS',
  ORGAOS_LOADING = '@novo/fontePagamento/ORGAOS_LOADING',
  ORGAOS_ERROR = '@novo/fontePagamento/ORGAOS_ERROR',
  ORGAOS_REQUEST = '@novo/fontePagamento/ORGAOS_REQUEST',

  INSS_VIA_URL = '@novo/fontePagamento/INSS_VIA_URL',

  RESET = 'RESET',
}
/**
 * State Type
 */
export interface FontePagamentoState {
  readonly cidadeSelecionada?: string | number
  readonly convenios?: Convenio[]
  readonly convenioSelecionado?: string | number
  readonly conveniosLoading: boolean
  readonly error: boolean
  readonly estadoSelecionado?: string | number
  readonly fontes?: FontePagamento[]
  readonly fonteSelecionada?: number
  readonly loading: boolean
  readonly nomeConvenio?: string

  readonly orgaoSelecionado?: string | number
  readonly orgaos?: Orgao[]
  readonly orgaosError?: boolean
  readonly orgaosLoading?: boolean

  readonly inssViaUrl?: boolean
}

export interface ConvenioRequestPayload {
  fontePagamento: number
  tipoSimulacao: string
  callback?: () => void
}
