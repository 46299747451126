import { Reducer } from 'redux'

import { SituacaoFuncionalTypes, SituacaoFuncionalState } from './types'

const INITIAL_STATE: SituacaoFuncionalState = {
  matriculaValida: true,
  matricula: '',
  salario: 0,
  situacaoFuncional: '',
  motivo: '',
  upag: '',
  patente: '',
  indicativo: '',
  dataIngresso: '',
  loading: false,
}

const reducer: Reducer<SituacaoFuncionalState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SituacaoFuncionalTypes.SET_MATRICULA:
      return { ...state, matricula: action.payload }
    case SituacaoFuncionalTypes.SET_MATRICULA_VALIDA:
      return { ...state, matriculaValida: action.payload, loading: false }
    case SituacaoFuncionalTypes.VALIDAR_MATRICULA:
      return { ...state, loading: true, error: false }
    case SituacaoFuncionalTypes.SET_SALARIO:
      return { ...state, salario: action.payload }
    case SituacaoFuncionalTypes.SET_SITUACAO:
      return { ...state, situacaoFuncional: action.payload }
    case SituacaoFuncionalTypes.SET_MOTIVO:
      return { ...state, motivo: action.payload }
    case SituacaoFuncionalTypes.SET_UPAG:
      return { ...state, upag: action.payload }
    case SituacaoFuncionalTypes.SET_PATENTE:
      return { ...state, patente: action.payload }
    case SituacaoFuncionalTypes.SET_INDICATIVO:
      return { ...state, indicativo: action.payload }
    case SituacaoFuncionalTypes.SET_DATA_INGRESSO:
      return { ...state, dataIngresso: action.payload }

    case SituacaoFuncionalTypes.CLEAR_SITUACAOFUNCIONAL:
      return INITIAL_STATE

    case SituacaoFuncionalTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
