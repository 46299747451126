import React, { useCallback, useEffect, useState, memo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Button } from '@interco/inter-ui/components/Button'
import { InnerLoading, InputToken } from '@molecules'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { ocultarTelefone } from '@utils/strings'
import { sendToken, resetToken, validateToken } from '@store/token/actions'
import { Variant } from '@utils/bottomSheetVariants'
import { trackingSimulacao } from '@store/novo/simulacao/actions'
import { StepNRNovo } from '@utils/enums'
import { useGetRecaptchaToken } from '@hooks'
import { ApplicationState } from '@store/types'
import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'
import GoogleTag from '@utils/GoogleTag'

import { Header } from './styles'

interface Props {
  error?: string
  loading: boolean
  telefone: string
  onSuccess: () => void
  navegarTrocarTelefone: () => void
  bottomSheetVariant: Variant
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  type: 'sms' | 'email' | undefined
  stateType: string
  stepError?: string
}

const RESEND_TIMER = 60

const PageToken = ({
  error,
  loading,
  telefone,
  onSuccess,
  navegarTrocarTelefone,
  bottomSheetVariant,
  open,
  setOpen,
  type,
  stateType,
  stepError,
}: Props) => {
  const dispatch = useDispatch()
  const [token, setToken] = useState('')
  const [filledToken, setFilledToken] = useState(false)
  const { getRecaptchaToken } = useGetRecaptchaToken()
  const [timer, setTimer] = useState(RESEND_TIMER)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { valorTotal, parcelas } = useSelector((state: ApplicationState) => state.novo.simulador)

  useEffect(() => {
    dispatch(resetToken())
  }, [dispatch])

  const sendTokenCallback = useCallback(async () => {
    if (!isApp) {
      const recaptchaToken = await getRecaptchaToken()
      if (recaptchaToken) {
        dispatch(trackingSimulacao(StepNRNovo.ENVIO_TOKEN))
        dispatch(sendToken({ type, stateType, recaptchaToken }))
      }
    } else {
      dispatch(trackingSimulacao(StepNRNovo.ENVIO_TOKEN))
      dispatch(sendToken({ type, stateType, recaptchaToken: 'NULLCAPTCHA' }))
    }
  }, [dispatch, getRecaptchaToken, isApp, stateType, type])

  useEffect(() => {
    sendTokenCallback()
  }, [sendTokenCallback])

  useEffect(() => {
    const time = setTimeout(() => {
      if (timer) {
        setTimer(timer - 1)
      }
    }, 1000)
    return () => clearTimeout(time)
  })

  const sendValidateToken = useCallback(async () => {
    if (!isApp) {
      const recaptchaToken = await getRecaptchaToken()
      dispatch(
        validateToken({
          stateType,
          token,
          type,
          recaptchaToken,
          success: () => onSuccess(),
        }),
      )
      dispatch(trackingSimulacao(StepNRNovo.VALIDAR_TOKEN))
    } else {
      dispatch(
        validateToken({
          stateType,
          token,
          type,
          recaptchaToken: 'NULLCAPTCHA',
          success: () => onSuccess(),
        }),
      )
      dispatch(trackingSimulacao(StepNRNovo.VALIDAR_TOKEN))
    }
  }, [isApp, dispatch, stateType, token, type, getRecaptchaToken, onSuccess])

  useEffect(() => {
    if (filledToken) {
      sendValidateToken()
    }
  }, [filledToken, sendValidateToken])

  return (
    <>
      {loading ? (
        <InnerLoading height="150px" size={48} thickness={2} />
      ) : (
        <>
          <Paragraph variant="inter" fontSize="14px" lineHeight="17px" color={Colors.GRAY400}>
            Pra garantir sua segurança, enviamos um SMS com o código de verificação para o número{' '}
            {ocultarTelefone(telefone || '')}. Digite o código aqui :
          </Paragraph>
          <InputToken
            error={error}
            stepError={stepError}
            getToken={(t, filled) => {
              setToken(t)
              setFilledToken(filled)
            }}
            id="pageToken"
          />
          <Button
            variant="secondary"
            disabled={timer > 0}
            onClick={() => {
              GoogleTag.simuladorConsignadoTag({
                content_name: 'Reenviar código',
                tela: 'Token',
                content_action: 'Clique',
                price: valorTotal,
                value: parcelas,
                content_aba: '',
                content_grupo: '',
                content_conv: '',
                content_sel: '',
                contato: telefone,
                element_action: 'click button',
                c_page: window.location.href,
                step: '3',
              })
              dispatch(resetToken())
              sendTokenCallback()
            }}
            style={{
              margin: '10px auto 0',
              border: 'none',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              display: 'flex',
            }}
          >
            {`Reenviar código ${timer > 0 ? `(${timer}s)` : ''}`}
          </Button>
          {open && (
            <BottomSheet onClose={() => setOpen(false)}>
              <Header>
                <Paragraph
                  fontFamily="sora"
                  fontSize="1rem"
                  lineHeight="1.25rem"
                  color={Colors.GRAY500}
                  fontWeight={600}
                >
                  {bottomSheetVariant.title}
                </Paragraph>
              </Header>
              <Paragraph
                variant="inter"
                fontWeight="400"
                fontSize="14px"
                lineHeight="17px"
                color={Colors.GRAY400}
                margin="0 0 32px 0"
              >
                {bottomSheetVariant.text}
              </Paragraph>
              <Button
                fullWidth
                onClick={() => {
                  GoogleTag.simuladorConsignadoTag({
                    content_name: 'Não tenho acesso ao celular',
                    tela: 'Token',
                    content_action: 'Clique',
                    price: valorTotal,
                    value: parcelas,
                    content_aba: '',
                    content_grupo: '',
                    content_conv: '',
                    content_sel: '',
                    contato: telefone,
                    element_action: 'click button',
                    c_page: window.location.href,
                    step: '3',
                  })
                  setOpen(false)
                  navegarTrocarTelefone()
                }}
                style={{ marginBottom: '16px' }}
              >
                Informar outro número
              </Button>
              <Button variant="secondary" onClick={() => setOpen(false)} fullWidth>
                Cancelar
              </Button>
            </BottomSheet>
          )}
        </>
      )}
    </>
  )
}
PageToken.defaultProps = {
  error: false,
}
export default memo(PageToken)
