import React from 'react'

import { GiftIcon } from '@interco/cp-react-ui-lib'
import { MatriculaSiapeImage, SiglaUpagImage, SiglaUpagImageNovo, NumeroPRECCP } from '@images'

export const bottomSheetVariants = {
  fontePagamento: {
    title: 'Fonte de pagamento',
    text: 'Informe a origem de seu recebimento, seja ele um salário ou benefício.',
  },
  convenio: {
    title: 'Convênios',
    text: 'São os órgãos municipais, estaduais e federais que possuem parceria com o Inter',
  },
  convenioNovo: {
    title: 'Convênio / Empresa',
    text: 'Aqui, você confere apenas as empresas ou orgãos públicos que possuem parceria com o Inter para ofertar essa modalidade de empréstimo. Por isso, que nem todos estão listados.',
  },
  situacao: {
    title: 'Situação funcional',
    text:
      'É a classificação do Servidor Público, que pode variar de acordo com o órgão, como efetivo, ' +
      'aposentado, pensionista, entre outros.',
  },
  matricula: {
    title: 'Matrícula',
    text: 'Você encontra esta informação em seu contracheque.',
  },
  matriculaNovo: {
    title: 'Matrícula',
    text: 'Você pode conseguir esta informação no seu contracheque. Precisamos dela para calcular sua simulação personalizada.',
  },
  valorBeneficio: {
    title: 'Valor do benefício',
    text: 'Você encontra o valor do benefício no seu extrato de pagamento.',
  },
  numeroBeneficio: {
    title: 'Benefício',
    text: 'Você pode conseguir esta informação no seu contracheque. Precisamos deste número para consultar seus dados no INSS.',
  },
  matriculaSiape: {
    title: 'Matrícula',
    text: 'Você encontra esta informação em seu contracheque.',
    image: (
      <MatriculaSiapeImage
        style={{
          width: '100%',
          height: 'auto',
          maxWidth: '600px',
          marginBottom: '20px',
        }}
      />
    ),
  },
  siglaUpag: {
    title: 'Sigla UPAG',
    text: 'É a Unidade Pagadora. Você encontra a essa informação em seu contracheque.',
    image: (
      <SiglaUpagImage
        style={{
          width: '100%',
          height: 'auto',
          maxWidth: '600px',
          marginBottom: '20px',
        }}
      />
    ),
  },
  siglaUpagNovo: {
    title: 'Sigla UPAG',
    text: 'É a Unidade Pagadora. Você encontra a essa informação em seu contracheque.',
    image: (
      <SiglaUpagImageNovo
        style={{
          width: '100%',
          height: 'auto',
          maxWidth: '600px',
          marginBottom: '20px',
        }}
      />
    ),
  },
  numeroPRECCP: {
    title: 'Número de PREC-CP',
    text: 'Você encontra esta informação em seu contracheque.',
    image: (
      <NumeroPRECCP
        style={{
          width: '100%',
          height: 'auto',
          maxWidth: '600px',
          marginBottom: '20px',
        }}
      />
    ),
  },
  codigoIndicacao: {
    title: 'Código de indicação',
    text: 'O código Indique e Ganhe pode ser enviado pra você por um amigo. Se você recebeu um, cole ou digite e continue para contratação.',
  },

  indicacaoElegivel: {
    title: 'Parabéns, você foi indicado!',
    text: 'Se você concluir a contratação deste empréstimo consignado vocês dois ganham R$ 150!',
    image: <GiftIcon />,
  },
  indicacaoNaoElegivel: {
    title: 'Você não é elegível ao indique e ganhe',
    text: 'Código inválido ou você já possui um contrato de consignado conosco!',
  },
  informarOutroCelular: {
    title: 'Informar outro celular',
    text: 'Se você não possui acesso ao celular informado, informe outro número de celular no cadastro',
  },
  margemMenor: {
    title: 'Escolha um valor disponível para o seu empréstimo',
    text: 'Por conta da sua margem liberada, o valor solicitado não está disponível.  Você pode escolher um empréstimo entre',
  },
  margemMaior: {
    title: 'Escolha um valor disponível para o seu empréstimo',
    text: 'Notícia boa! Você tem mais margem liberada.  Você pode escolher um empréstimo entre',
  },
  margemMinima: {
    title: 'Escolha um valor disponível para o seu empréstimo',
    text: 'Vamos realizar a simulação. Escolha um empréstimo entre ',
  },
  margemConvenioSemApi: {
    title: 'Escolha um valor disponível para o seu empréstimo',
    text: 'Escolha o valor do empréstimo que deseja, lembrando que a liberação do valor irá depender de análise do Inter.  Você pode escolher um empréstimo entre',
  },
  margemConvenioComApiSemAutorizacao: {
    title: 'Escolha um valor disponível para o seu empréstimo',
    text: 'Escolha o valor desejado, lembrando que para uma simulação exata precisamos de sua aprovação para consulta de margem.  Você pode escolher um empréstimo entre',
  },
  margemRiscoCpf: {
    title: 'Atualize o valor do empréstimo',
    text: 'Atualmente, o valor de empréstimo disponível pra você é ',
    afterText: '. Pra continuar, atualize o valor de acordo com esse limite',
  },
}
export interface Variant {
  title: string
  text: string
  afterText?: string
  image?: JSX.Element
}
