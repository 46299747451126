import styled from 'styled-components'

import { Colors } from '@utils/Colors'

export const Box = styled.button<{
  actived: boolean
}>`
  margin: 20px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 16px;
  width: 100%;
  min-height: 76px;
  border-radius: 8px;
  border: ${(props) =>
    props.actived ? `1px solid ${Colors.PRIMARY500}` : `1px solid ${Colors.GRAY300}`};
`
