import { put, select } from 'typed-redux-saga'

import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'

import { scheduleSimulationUpdate } from '../actions'

export default function* postScheduleSimulationSaga() {
  const novoState = yield* select((state: ApplicationState) => state.novo)
  const globalState = yield* select((state: ApplicationState) => state.globalApi)

  const modelBody = {
    cidade:
      globalState?.cidades?.find(
        (cidade) => cidade.codigo === String(novoState.fontePagamento?.cidadeSelecionada),
      )?.nome || '',
    uf: String(novoState.fontePagamento?.estadoSelecionado) || 'MG',
    idSimulacao: Number(novoState.simulacao.idSimulacao),
  }

  try {
    yield services.putScheduleSimulation(
      modelBody,
      Number(novoState.beneficiarioInss.scheduledSimulationId),
    )
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao enviar dados para simulação',
        message: 'Ocorreu um erro ao enviar dados para simulação',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => scheduleSimulationUpdate(),
      }),
    )
  }
}
