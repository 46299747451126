import { action, Action } from 'typesafe-actions'

import { CodigoMgmTypes } from '@services/postSalvarLeadNovo'

import {
  CodIndicacaoContatoTypes,
  DataLakeContatoRequestPayload,
  ValidaIndicacaoActionPayload,
} from './types'

export const setCodigoIndicacao = (payload: string): Action =>
  action(CodIndicacaoContatoTypes.SET_CODIGO_INDICACAO, payload)
export const setTelefone = (payload: string): Action =>
  action(CodIndicacaoContatoTypes.SET_TELEFONE, payload)
export const setEmail = (payload: string): Action =>
  action(CodIndicacaoContatoTypes.SET_EMAIL, payload)
export const setCheckAutorizacao = (payload: boolean): Action =>
  action(CodIndicacaoContatoTypes.SET_CHECK_AUTORIZACAO, payload)
export const setValidacaoCodigoMgm = (payload: CodigoMgmTypes): Action =>
  action(CodIndicacaoContatoTypes.SET_VALIDACAO_CODIGO_MGM, payload)
export const validaIndicacaoRequest = (payload: ValidaIndicacaoActionPayload): Action =>
  action(CodIndicacaoContatoTypes.VALIDA_INDICACAO_REQUEST, payload)
export const validaIndicacaoError = (): Action =>
  action(CodIndicacaoContatoTypes.VALIDA_INDICACAO_ERROR)
export const validaIndicacaoSuccess = (): Action =>
  action(CodIndicacaoContatoTypes.VALIDA_INDICACAO_SUCCESS)
export const resetCodigoIndicacao = (): Action => action(CodIndicacaoContatoTypes.RESET_INDICACAO)

export const dataLakeContatoRequest = (payload: DataLakeContatoRequestPayload): Action =>
  action(CodIndicacaoContatoTypes.DATA_LAKE_CONTATO_REQUEST, payload)
export const dataLakeContatoSuccess = (): Action =>
  action(CodIndicacaoContatoTypes.DATA_LAKE_CONTATO_SUCCESS)
export const dataLakeContatoError = (): Action =>
  action(CodIndicacaoContatoTypes.DATA_LAKE_CONTATO_ERROR)
export const setFlagInfoCredito = (payload: boolean): Action =>
  action(CodIndicacaoContatoTypes.SET_FLAG_INFO_CREDITO, payload)
export const setEditarCodigo = (payload: boolean): Action =>
  action(CodIndicacaoContatoTypes.SET_EDITAR_CODIGO, payload)
export const resetCodigoIndicacaoApp = (): Action =>
  action(CodIndicacaoContatoTypes.RESET_INDICACAO_APP)
