import { BaseServices, HttpMethod } from '@services/base'

export default (data: SchedulePatchRequest, scheduledSimulationId: number) => {
  const pathname = process.env.REACT_APP_SCHEDULE_SIMULATION
  const method = HttpMethod.PUT
  const host = process.env.REACT_APP_API_HOST

  const headers = {
    scheduledSimulationId: String(scheduledSimulationId),
  }

  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })

  return response
}

export interface SchedulePatchRequest {
  cidade: string
  uf: string
  idSimulacao: number
}
