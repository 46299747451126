import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import postSalvarLeadNovo, { SalvarLeadRequest } from '@services/postSalvarLeadNovo'
import { ResponseSalvarSimulacao, SalvarSimulacaoRequest } from '@services/postSalvarSimulacaoNovo'
import { ApplicationState } from '@store/types'
import {
  salvarLeadSuccess,
  salvarLeadError,
  salvarLeadRequest,
  setIdSimulacao,
} from '@store/novo/simulacao/actions'
import { showError } from '@store/ui/error/actions'
import { ConveniosCodes, ErrorCodes, PendenciaEnum } from '@utils/enums'
import services from '@services'
import { setLoading } from '@store/novo/valida/actions'
import { putScheduleSimulationSaga } from '@store/novo/consultasMargem/consultaBeneficiarioInss/sagas'

import { SalvarLeadRequestPayload } from '../types'
import postCloudMarketRemoveSaga from './postCloudMarketRemoveSaga'
import { buildSalvarSimulacao } from './models/modelSimulacao'
import { buildPostLead } from './models/modelLead'

export default function* postSalvarLead(action: AnyAction) {
  const payload = action.payload as SalvarLeadRequestPayload
  try {
    const { novo, session, ui, globalApi } = yield* select((s: ApplicationState) => s)
    const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)
    const familiaTipo = novo.simulador.familia

    const bodySalvarSimulacao: SalvarSimulacaoRequest = buildSalvarSimulacao(
      novo,
      session,
      ui,
      familiaTipo,
    )
    const response: ResponseSalvarSimulacao = yield services.postSalvarSimulacaoNovo(
      bodySalvarSimulacao,
      newSimulatorApi,
    )
    if (response.data.idSimulacao) {
      yield put(setIdSimulacao(response.data.idSimulacao))
    }
    yield postCloudMarketRemoveSaga()

    if (
      novo.beneficiarioInss?.scheduledSimulationId &&
      Number(novo.fontePagamento.convenioSelecionado) === ConveniosCodes.INSS
    ) {
      yield putScheduleSimulationSaga()
    } else if (payload?.pendencia !== PendenciaEnum.SEM_LEAD_COM_CONTRATACAO_DIGITAL) {
      const lead: SalvarLeadRequest = buildPostLead(
        novo,
        session,
        ui,
        familiaTipo,
        globalApi,
        String(response.data.idSimulacao),
        payload?.pendencia,
        payload?.observacao,
      )

      yield* call(postSalvarLeadNovo, lead, newSimulatorApi)
    }
    yield put(salvarLeadSuccess())
    yield put(setLoading(false))
    yield payload.callback(response.data.idSimulacao)
  } catch (error) {
    yield put(salvarLeadError())
    yield put(setLoading(false))
    yield put(
      showError({
        title: 'Erro ao salvar',
        message: 'Ocorreu um erro ao salvar suas informações',
        actionTitle: 'Tentar Novamente',
        block: true,
        code: ErrorCodes.N0_006_SALVAR_LEAD,
        actionCallback: () => salvarLeadRequest(payload),
      }),
    )
  }
}
