import { ConsultaMargemGeralRequest } from '@services/postConsultaMargemGeral'
import { NovoState } from '@store/novo'
import { ConveniosCodes } from '@utils/enums'
import { isConveniosCip } from '@utils/functions'
import { datePtToEn } from '@utils/simuladorUtils'

export const modelMargemGeral = (novo: NovoState) => {
  let bodyRequest: ConsultaMargemGeralRequest = {
    documento: novo.dadosPessoais?.cpf,
    origem: 'NOVO',
    codigoConvenio: Number(novo.fontePagamento.convenioSelecionado),
    codigoOrgao: Number(novo.fontePagamento.orgaoSelecionado),
  }

  if (ConveniosCodes.ESTADO_RJ === Number(novo.fontePagamento.convenioSelecionado)) {
    bodyRequest = {
      ...bodyRequest,
      numeroPensionista: novo.consultaMargemRio.numeroPensionista,
      vinculo: novo.consultaMargemRio.vinculo,
      idFuncional: novo.consultaMargemRio.idFuncional,
      senhaServidor: novo.consultaMargemRio.senhaAverbacaoRio,
      matriculaMultipla: true,
    }
  } else if (ConveniosCodes.INSS === Number(novo.fontePagamento.convenioSelecionado)) {
    bodyRequest = {
      ...bodyRequest,
      matricula: String(novo.situacaoFuncional?.matricula),
    }
  } else if (isConveniosCip(novo.fontePagamento.convenioSelecionado)) {
    bodyRequest = {
      ...bodyRequest,
      matricula: String(novo.situacaoFuncional?.matricula),
    }
  } else if (ConveniosCodes.EXERCITO_BR === Number(novo.fontePagamento.convenioSelecionado)) {
    bodyRequest = {
      ...bodyRequest,
      matricula: String(novo.situacaoFuncional?.matricula),
      senhaServidor: novo.consultaMargemExercito.senhaAverbacaoExercito,
      matriculaMultipla: false,
    }
  } else if (
    ConveniosCodes.SIAPE_SERVIDOR_FEDERAL === Number(novo.fontePagamento.convenioSelecionado)
  ) {
    bodyRequest = {
      ...bodyRequest,
      matricula: String(novo.situacaoFuncional?.matricula),
      dataNascimento: datePtToEn(novo.dadosPessoais.dataNascimento),
    }
  } else if (ConveniosCodes.PREF_BH === Number(novo.fontePagamento.convenioSelecionado)) {
    bodyRequest = {
      ...bodyRequest,
      senhaServidor: novo.dadosPessoais.senhaServidor,
    }
  }

  return bodyRequest
}
