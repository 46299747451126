import styled from 'styled-components'

export const ContainerPrice = styled.div`
  width: 205px;
  margin: 0 auto 14px auto;
`
export const ContainerText = styled.div`
  display: flex;
  max-width: 390px;
  flex-direction: row;
  justify-content: space-between;
  margin: auto auto 24px auto;
`
