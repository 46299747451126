import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import sendToken from './sagas/sendToken'
import validateToken from './sagas/validateToken'
import { TokenTypes } from './types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(TokenTypes.SEND_TOKEN_REQUEST, sendToken)
  yield* takeLatest(TokenTypes.VALIDATE_TOKEN_REQUEST, validateToken)
}
