import { call, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import trackingSimulacaoService, { TrackingSimulacaoRequest } from '@services/postTrackingSimulacao'
import { StepNRNovo } from '@utils/enums'

import { buildTrackingSimulacao } from './models/modelNewRelicTracking'

export default function* postTrackingSimulacao(action: AnyAction) {
  const payload = action.payload as StepNRNovo
  try {
    const { ui, session, novo } = yield* select((s: ApplicationState) => s)
    const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)

    const trackingRequest: TrackingSimulacaoRequest = buildTrackingSimulacao(
      novo,
      session,
      ui,
      payload,
    )
    yield* call(trackingSimulacaoService, trackingRequest, newSimulatorApi)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('​error', error)
  }
}
