import { ParcelasTypes, FamiliaType } from '@utils/simuladorUtils'
/**
 * Actions Types
 */

export enum SimuladorTypes {
  SET_VALOR_TOTAL = '@novo/simulador/SET_VALOR_TOTAL',
  SET_NOVO_VALOR_TOTAL = '@novo/simulador/SET_NOVO_VALOR_TOTAL',
  SET_PARCELAS = '@novo/simulador/SET_PARCELAS',
  SET_IS_TOTAL = '@novo/simulador/SET_IS_TOTAL',
  SET_VALOR_SIMULADO = '@novo/simulador/SET_VALOR_SIMULADO',
  SET_FAMILIA_SIMULACAO = '@novo/simulador/SET_FAMILIA_SIMULACAO',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface SimuladorState {
  readonly valorTotal: number
  readonly novoValorTotal: number
  readonly parcelas: ParcelasTypes
  readonly isTotal: boolean
  readonly valorSimulado: string
  readonly familia: FamiliaType
}
