import { BaseServices, HttpMethod } from '@services/base'

export default (data: MargemRioRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_POST_MARGEM_RIO
    : process.env.REACT_APP_POST_MARGEM_RIO_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<MargemRioResponse>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface MargemRioRequest {
  cpf: string
  numeroPensionista: string
  vinculo: string
  idFuncional: string
  senhaServidor: string
  matriculaMultipla: boolean
}

export interface MargemRioResponse {
  tentativas?: number
  situacaoFuncional?: string
  indicativo?: string
  dataIngresso?: string
  pendencia: string
  valorMargem: number
}
