import { BaseServices, HttpMethod } from '@services/base'

export default (cpf: string) => {
  const pathname = process.env.REACT_APP_INSS_ASYNC_RESULT
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers = {
    socialDocument: String(cpf),
  }

  return BaseServices.request({
    headers,
    pathname,
    method,
    host,
  })
}
