import { action, Action } from 'typesafe-actions'

import { PageMargemCipEnum } from '@utils/enums'

import { ConsultaMargemCipPayload, ConsultaMargemCipTypes } from './types'

export const setPageAtualConsultaMargem = (payload: PageMargemCipEnum): Action =>
  action(ConsultaMargemCipTypes.SET_PAGES_CONSULTA_MARGEM_CIP, payload)

export const setCheckConsultaMargem = (payload: boolean): Action =>
  action(ConsultaMargemCipTypes.SET_CHECK_CONSULTA_MARGEM_CIP, payload)

export const consultaMargemCipRequest = (payload: ConsultaMargemCipPayload): Action =>
  action(ConsultaMargemCipTypes.CONSULTA_MARGEM_CIP_REQUEST, payload)

export const consultaMargemCipSuccess = (): Action =>
  action(ConsultaMargemCipTypes.CONSULTA_MARGEM_CIP_SUCCESS)

export const consultaMargemCipSuccessCallback = (): Action =>
  action(ConsultaMargemCipTypes.CONSULTA_MARGEM_CIP_SUCCESS_CALLBACK)

export const consultaMargemCipError = (): Action =>
  action(ConsultaMargemCipTypes.CONSULTA_MARGEM_CIP_ERROR)

export const setValorMargem = (payload: number): Action =>
  action(ConsultaMargemCipTypes.SET_VALOR_MARGEM, payload)
