/**
 * Actions Types
 */
export enum ValidaTypes {
  VALIDA_REQUEST = '@novo/valida/VALIDAR_REQUEST',
  VALIDA_SET_LOADING = '@novo/valida/VALIDA_SET_LOADING',
  VALIDA_PENDENCIA_REQUEST = '@novo/valida/VALIDA_PENDENCIA_REQUEST',
  VALIDA_REFORMA_REQUEST = '@novo/valida/VALIDA_REFORMA_REQUEST',
  VALIDA_REFORMA_SET_LOADING = '@novo/valida/VALIDA_REFORMA_SET_LOADING',

  RESET = 'RESET',
}

export interface CallBackContatoType {
  readonly contato: boolean
  readonly riscoCpf: number
  readonly pendencia: string
  readonly descricaoPendenciaRisco: string
}
export interface CallBackValidaType {
  readonly tipoPendencia: string
  readonly descricaoPendencia: string
  readonly observacao: string
}

export type ValidaRequestPayload = {
  callback: (params: CallBackContatoType) => void
}

export type ValidaPendenciaRequestPayload = {
  riscoCpf: number
  callback: (params: CallBackValidaType) => void
}
export interface ValidaState {
  readonly loading: boolean
  readonly loadingReforma: boolean
}
