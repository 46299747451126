import React from 'react'

import { InputPrice } from '@atoms'
import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'
import { InputRange } from '@interco/inter-ui/components/InputRange'

import { ContainerPrice, ContainerSlider } from './styles'

interface Props {
  min: number
  max: number
  valorTotal: number
  isTotal: boolean
  hasTitle?: boolean
  onChange: (e: number | number[]) => void
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SimulatorSlider = ({
  min,
  max,
  valorTotal,
  isTotal,
  onChange,
  onChangeInput,
  handleBlur,
  hasTitle = true,
}: Props) => (
  <>
    {hasTitle && (
      <Paragraph
        variant="inter"
        fontSize="14px"
        fontWeight="700"
        lineHeight="17px"
        color={Colors.GRAY400}
        textAlign="center"
        style={{ marginTop: '8px', paddingBottom: '24px' }}
      >
        {isTotal ? 'De quanto você precisa?' : 'Quanto gostaria de valor de parcela?'}
      </Paragraph>
    )}
    <ContainerPrice>
      <InputPrice value={valorTotal} onChangeInput={onChangeInput} onBlur={handleBlur} />
    </ContainerPrice>
    <ContainerSlider>
      <InputRange max={max} min={min} value={valorTotal} onChange={onChange} />
    </ContainerSlider>
  </>
)
SimulatorSlider.defaultProps = {
  hasTitle: true,
}

export default SimulatorSlider
