import { select, call, put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import postConsultaMargemCip, { ConsultaMargemCipRequest } from '@services/postConsultaMargemCip'
import { showError } from '@store/ui/error/actions'
import { ConveniosCodes, ErrorCodes } from '@utils/enums'
import { setPendencia } from '@store/novo/pendencia/actions'
import { setNovoValorTotal, setValorTotal } from '@store/novo/simulador/actions'

import {
  consultaMargemCipError,
  consultaMargemCipRequest,
  consultaMargemCipSuccess,
  consultaMargemCipSuccessCallback,
  setValorMargem,
} from '../actions'
import { ConsultaMargemCipPayload } from '../types'

export default function* postConsultaMargemCipSaga(action: AnyAction) {
  const payload = action.payload as ConsultaMargemCipPayload
  const { novo } = yield* select((s: ApplicationState) => s)
  const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)
  const bodyRequest: ConsultaMargemCipRequest = {
    cpf: novo.dadosPessoais?.cpf,
    matricula: String(novo.situacaoFuncional?.matricula),
    convenio: Number(novo.fontePagamento.convenioSelecionado),
    orgao: Number(novo.fontePagamento.orgaoSelecionado),
  }
  yield put(setPendencia(''))

  try {
    const response = yield* call(postConsultaMargemCip, bodyRequest, newSimulatorApi)

    if (response.data.pendencia) {
      yield put(setPendencia(response.data?.pendencia))
    }
    if (response.data.margem) {
      yield put(consultaMargemCipSuccess())
      if (Number(novo.fontePagamento.convenioSelecionado) === ConveniosCodes.PREF_SP) {
        yield put(setNovoValorTotal(response.data.margem))
        yield put(setValorTotal(response.data.margem))
        yield put(setValorMargem(response.data.margem))
      }
    } else {
      yield put(consultaMargemCipSuccessCallback())
    }
    yield payload.callback(response.data.tentativas, response.data.margem, response.data.pendencia)
  } catch (error) {
    yield put(consultaMargemCipError())
    yield put(
      showError({
        title: 'Erro ao consultar margem',
        message: 'Ocorreu um erro ao consultar margem.',
        actionTitle: 'Tentar Novamente',
        block: true,
        code: ErrorCodes.NO_010_CONSULTAR_MARGEM_CIP,
        actionCallback: () => consultaMargemCipRequest(payload),
      }),
    )
  }
}
