/**
 * Actions Types
 */

export enum SimulacaoTypes {
  VERIFICA_PENDENCIA_LEAD_REQUEST = '@portabilidade/simulacao/VERIFICA_PENDENCIA_LEAD_REQUEST',
  VERIFICA_PENDENCIA_PORTABILIDADE_REQUEST = '@portabilidade/simulacao/VERIFICA_PENDENCIA_PORTABILIDADE_REQUEST',
  VERIFICA_PENDENCIA_LEAD_LOADING = '@portabilidade/simulacao/VERIFICA_PENDENCIA_LEAD_LOADING',
}

/**
 * State Type
 */

export interface SimulacaoState {
  readonly verificaPendenciaLead: boolean
  readonly verificaPendenciaPortabilidade: boolean
  readonly verificaPendenciaLeadLoading: boolean
}

/**
 * Actions Payload Types
 */

export type VerificaPendenciaLeadPayload = {
  callback: () => void
}
export type VerificaPendenciaPortabilidadePayload = {
  callback: () => void
}
