import { combineReducers } from 'redux'

import navigation from './navigation'
import error from './error'
import steps from './steps'
import { ErrorState } from './error/types'
import { NavigationState } from './navigation/types'
import { StepState } from './steps/types'

export interface UiState {
  navigation: NavigationState
  error: ErrorState
  steps: StepState
}

export default combineReducers({
  navigation,
  error,
  steps,
})
