import { AnyAction } from 'redux'
import { call } from 'typed-redux-saga'

import postAutorizacaoISafe from '@services/postAutorizacaoISafe'

export default function* postContratacaoSaga(action: AnyAction) {
  const payload = action?.payload as PayloadAutorizacao
  try {
    yield* call(postAutorizacaoISafe, {
      tipoAutenticacao: payload.tipoAutenticacao,
      token: payload.token,
      valor: payload.valor,
    })
    payload.callback()
  } catch (error) {
    yield payload?.erro()
  }
}

export interface PayloadAutorizacao {
  callback: () => void
  erro: () => void
  token: string
  tipoAutenticacao: string
  valor: string
}
