import React, { lazy, Suspense } from 'react'

import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import FallbackPage from '@components/organims/FallbackPage'
import { caching } from '@utils/functions'
import { ApplicationState } from '@store/types'

import routes from './routes'

const RootPage = lazy(() => import('@components/pages/Privado/RootPage'))
const Home = lazy(() => import('@components/pages/SimuladorV3/HomeConsignado'))
const Faq = lazy(() => import('@components/pages/SimuladorV3/HomeConsignado/Faq'))
const AutorizacaoInss = lazy(
  () => import('@components/pages/SimuladorV3/Grupos/Inss/AutorizacaoConsultaDocumento'),
)
const NumeroBeneficio = lazy(
  () => import('@components/pages/SimuladorV3/Grupos/Inss/NumeroBeneficio'),
)
const CarregamentoDados = lazy(() => import('@components/pages/SimuladorV3/CarregamentoDados'))
const Desqualificacao = lazy(() => import('@components/pages/SimuladorV3/Desqualificacao'))
const OfertaPortabilidade = lazy(() => import('@components/pages/SimuladorV3/OfertaPortabilidade'))
const ErroSistemico = lazy(() => import('@components/pages/SimuladorV3/ErroSistemico'))
const DadosBasicos = lazy(() => import('@components/pages/SimuladorV3/DadosBasicos'))
const ValidaEmail = lazy(() => import('@components/pages/SimuladorV3/Validacao/ValidaEmail'))
const ValidaTelefone = lazy(() => import('@components/pages/SimuladorV3/Validacao/ValidaTelefone'))
const ResultadoSimulacao = lazy(() => import('@components/pages/SimuladorV3/ResultadoSimulacao'))
const DadosRecebidos = lazy(() => import('@components/pages/SimuladorV3/DadosRecebidos'))
const Resumo = lazy(() => import('@components/pages/SimuladorV3/Resumo'))
const OutrosConvenios = lazy(
  () => import('@components/pages/SimuladorV3/HomeConsignado/components/OutrosConvenios'),
)
const ConsultaMargemSiape = lazy(
  () => import('@components/pages/SimuladorV3/Grupos/Siape/ConsultaMargem'),
)
const DadosManuaisSiape = lazy(
  () => import('@components/pages/SimuladorV3/Grupos/Siape/DadosManuais'),
)
const Teimosinha = lazy(() => import('@components/pages/SimuladorV3/Grupos/Inss/Teimosinha'))
const FeedbackResultadoMargem = lazy(
  () => import('@components/pages/SimuladorV3/Grupos/Siape/ConsultaMargem/FeedbackResultadoMargem'),
)
const InssIndisponivel = lazy(
  () => import('@components/pages/SimuladorV3/Grupos/Inss/InssIndisponivel'),
)

const Routes: React.FC = () => {
  const sessionStore = useSelector((state: ApplicationState) => state.session)

  caching(sessionStore)
  return (
    <Switch>
      <Suspense fallback={<FallbackPage />}>
        <Route exact path={routes.RESUMO} component={Resumo} />
        <Route exact path={routes.PROPOSTA_RECEBIDA} component={Teimosinha} />
        <Route exact path={routes.DADOS_RECEBIDOS} component={DadosRecebidos} />
        <Route exact path={routes.RESULTADO_SIMULACAO} component={ResultadoSimulacao} />
        <Route exact path={routes.VALIDA_TELEFONE} component={ValidaTelefone} />
        <Route exact path={routes.VALIDA_EMAIL} component={ValidaEmail} />
        <Route exact path={routes.DADOS_BASICOS} component={DadosBasicos} />
        <Route exact path={routes.ERRO_SISTEMICO} component={ErroSistemico} />
        <Route exact path={routes.NAO_LIBERADO} component={Desqualificacao} />
        <Route exact path={routes.OFERTA_PORTABILIDADE} component={OfertaPortabilidade} />
        <Route exact path={routes.CARREGAMENTO_DADOS} component={CarregamentoDados} />
        <Route exact path={routes.NUMERO_BENEFICIO} component={NumeroBeneficio} />
        <Route exact path={routes.AUTORIZACAO_CONSULTA_INSS} component={AutorizacaoInss} />
        <Route exact path={routes.OUTROS_CONVENIOS} component={OutrosConvenios} />
        <Route exact path={routes.CONSULTA_MARGEM_SIAPE} component={ConsultaMargemSiape} />
        <Route exact path={routes.DADOS_MANUAIS_SIAPE} component={DadosManuaisSiape} />
        <Route exact path={routes.INSS_INDISPONIVEL} component={InssIndisponivel} />
        <Route
          exact
          path={routes.FEEDBACK_RESULTADO_MANUAL_SIAPE}
          component={FeedbackResultadoMargem}
        />
        <Route exact path={routes.HOME} component={Home} />
        <Route exact path={routes.FAQ} component={Faq} />
        <Route exact path={routes.ROOT} component={RootPage} />
      </Suspense>
    </Switch>
  )
}
export default Routes
