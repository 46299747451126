import { useCallback } from 'react'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const useGetRecaptchaToken = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const getRecaptchaToken = useCallback(async () => {
    if (executeRecaptcha) {
      return executeRecaptcha('validacao_simulador')
    }
    return ''
  }, [executeRecaptcha])

  return { getRecaptchaToken }
}
export default useGetRecaptchaToken
