import { BaseServices, HttpMethod } from '@services/base'
import { PropostaApi } from '@store/globalApi/types'

export default (cpf: string) => {
  const pathname = process.env.REACT_APP_PROPOSTA_SANGUE_LARANJA_BFF
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request<PropostaApi>({
    headers: {
      'X-Inter-User-Social-Id': cpf,
    },
    pathname,
    method,
    host,
  })
}
