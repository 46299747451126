import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import postConsultaMargemRio from '@services/postConsultaMargemRio'
import { removeMask } from '@utils/masks'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { setPendencia } from '@store/novo/pendencia/actions'
import {
  setDataIngresso,
  setIndicativo,
  setIsMatriculaValida,
  setMatricula,
  setSituacao,
} from '@store/novo/situacaoFuncional/actions'

import {
  postValidaSenhaRio,
  setAverbacaoValidaRio,
  setConsultaRealizada,
  setLoadingRio,
  setTentativasRio,
  setValorMargem,
} from '../actions'

interface ErrorType {
  status: number
  data: {
    message: string
  }
}

export default function* postValidaSenhaRioSaga({ payload }: AnyAction) {
  const { senhaAverbacaoRio, idFuncional, vinculo, numeroPensionista } = yield* select(
    (state: ApplicationState) => state.novo.consultaMargemRio,
  )
  const { cpf } = yield* select((state: ApplicationState) => state.novo.dadosPessoais)

  const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)

  try {
    yield put(setLoadingRio(true))
    yield put(setIsMatriculaValida(true))
    const { data } = yield* call(
      postConsultaMargemRio,
      {
        cpf,
        numeroPensionista,
        vinculo,
        idFuncional: removeMask(String(idFuncional)),
        senhaServidor: senhaAverbacaoRio,
        matriculaMultipla: true,
      },
      newSimulatorApi,
    )
    yield put(setPendencia(data?.pendencia))
    yield put(setConsultaRealizada(true))

    if (data.tentativas) {
      yield put(setAverbacaoValidaRio(false))
      yield put(setTentativasRio(data.tentativas))
      yield payload.callback(false, data.tentativas)
    } else {
      yield put(setMatricula(`${removeMask(String(idFuncional))}${vinculo}${numeroPensionista}`))
      yield put(setSituacao(data.situacaoFuncional))
      yield put(setIndicativo(data.indicativo))
      yield put(setDataIngresso(data.dataIngresso))
      yield put(setValorMargem(data.valorMargem))

      yield put(setAverbacaoValidaRio(true))
      yield put(setTentativasRio(0))
      yield payload.callback(true, 0)
    }
    yield put(setLoadingRio(false))
  } catch (error) {
    const erroTyped = error as ErrorType
    if (
      erroTyped &&
      erroTyped?.status === 400 &&
      erroTyped?.data?.message === 'Matrícula invalida'
    ) {
      yield put(setIsMatriculaValida(false))
    } else {
      yield put(
        showError({
          title: 'Erro ao consultar margem',
          message: 'Ocorreu um erro ao consultar margem.',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.NO_0010_CONSULTA_MARGEM_EXERCITO,
          actionCallback: () => postValidaSenhaRio(payload),
        }),
      )
    }

    yield put(setLoadingRio(false))
  }
}
