import styled from 'styled-components'

import { SelectBottomSheet } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet'
import { Select } from '@interco/inter-ui/components/Select'
import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'
import { Input } from '@interco/inter-ui/components/Input'

export const CustomBottomSheet = styled(SelectBottomSheet)`
  display: flex;
  width: 100%;
  margin-top: -10px;
  & input {
    color: ${Colors.GRAY500};
  }
`
export const SaibaMaisComponent = styled(Paragraph)`
  display: flex;
  width: 100%;
  align-self: end;
  justify-content: flex-end;
  margin: 0;
  cursor: pointer;
  z-index: 10;
`
export const CustomInput = styled(Input)<{
  marginRightDisabled?: boolean
}>`
  ${(props) =>
    props.marginRightDisabled &&
    `& label {
    margin-right: 0;
  }`}

  & input {
    color: ${Colors.GRAY500};
  }
`
export const CustomSelect = styled(Select)``
