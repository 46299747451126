import { BaseServices, HttpMethod } from '@services/base'

export default (data: ScheduleSimulationRequest) => {
  const pathname = process.env.REACT_APP_SCHEDULE_SIMULATION
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ScheduleSimulationResponse>({
    pathname,
    method,
    host,
    data,
  })

  return response
}

export interface ScheduleSimulationRequest {
  origem: string
  beneficio: string
  documento: string
  idConvenio: number
  convenio: string
  idOrgao: number
  orgao: string
  email: string
  telefone: string
}

export interface ScheduleSimulationResponse {
  id: number
}
