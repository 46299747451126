import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import postValidaMatricula, { ValidaMatriculaRequest } from '@services/postValidaMatricula'
import { ApplicationState } from '@store/types'
import { setLoading } from '@store/novo/fontePagamento/actions'

import { setIsMatriculaValida } from '../actions'
import { ValidarMatriculaPayload } from '../types'

export default function* postValidarMatriculaSaga(action: AnyAction) {
  const payload = action.payload as ValidarMatriculaPayload
  try {
    yield put(setLoading(true))

    const novoState = yield* select((state: ApplicationState) => state.novo)
    const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)
    const buildPostValidarMatricula: ValidaMatriculaRequest = {
      convenio: Number(novoState.fontePagamento.convenioSelecionado),
      cpf: novoState.dadosPessoais.cpf,
      matricula: novoState.situacaoFuncional.matricula || '',
      indicativo: Number(novoState.situacaoFuncional.indicativo) || 0,
      categoria: Number(novoState.situacaoFuncional.situacaoFuncional) || 0,
      orgao: Number(novoState.fontePagamento.orgaoSelecionado),
    }

    const { data } = yield* call(postValidaMatricula, buildPostValidarMatricula, newSimulatorApi)
    yield put(setIsMatriculaValida(data.matriculaValida))
    yield payload.callback(data.matriculaValida)

    yield put(setLoading(false))
  } catch (error) {
    yield put(setLoading(false))
    yield put(setIsMatriculaValida(false))
  }
}
