import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet/types'
import { bottomSheetVariants, Variant } from '@utils/bottomSheetVariants'
import { Colors } from '@utils/Colors'
import Select from '@atoms/SelectNovo'
import { ApplicationState } from '@store/types'
import Paragraph from '@atoms/Paragraph'

import { CustomBottomSheet, SaibaMaisComponent } from './styles'

interface OwnProps {
  id: string
  situacoesFuncionais: Option[]
  setVariant(variant: Variant): void
  setOpen(isOpen: boolean): void
  situacaoFuncional: Option
  onChange: (value: Option) => void
}

const SituacaoFuncionalDropdownNovo = ({
  id,
  situacoesFuncionais,
  situacaoFuncional,
  onChange,
  setVariant,
  setOpen,
}: OwnProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp) {
    return (
      <Select
        label="Qual é sua situação funcional?"
        name="dropdown"
        margin="0 0 20px 0"
        value={situacaoFuncional}
        hideSearch={false}
        options={situacoesFuncionais || []}
        placeholder="Selecione o tipo"
        onChange={(value) => {
          onChange(value)
        }}
        helperButton={
          <Paragraph color={Colors.PRIMARY500} fontSize="12px">
            Saiba mais
          </Paragraph>
        }
        onClickHelper={() => {
          setVariant(bottomSheetVariants.situacao)
          setOpen(true)
        }}
      />
    )
  }
  return (
    <>
      <SaibaMaisComponent
        color={Colors.PRIMARY500}
        fontSize="12px"
        onClick={() => {
          setVariant(bottomSheetVariants.situacao)
          setOpen(true)
        }}
      >
        Saiba mais
      </SaibaMaisComponent>
      <CustomBottomSheet
        id={id}
        data-testid={id}
        value={situacaoFuncional}
        isUsingFilter
        label="Qual é sua situação funcional?"
        name="dropdown"
        onChange={(ev) => onChange(ev)}
        options={situacoesFuncionais || []}
        placeholder="Selecione o tipo"
      />
    </>
  )
}
export default memo(SituacaoFuncionalDropdownNovo)
