import { DataLakeContatoRequest } from '@services/postDataLakeContato'
import { NovoState } from '@store/novo'

export const buildDataLakeContato = (novoState: NovoState) => {
  const objetoDataLake: DataLakeContatoRequest = {
    nome: novoState.dadosPessoais.nome,
    cpf: novoState.dadosPessoais.cpf,
    dataNascimento: novoState.dadosPessoais.dataNascimento,
    telefone: novoState.codIndicacaoContato.telefone,
    email: novoState.codIndicacaoContato.email,
    gerouLead: false,
  }
  return objetoDataLake
}
