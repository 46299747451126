import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import getValidaEmpresaNovo from '@services/getValidaEmpresaNovo'

import { getValidaEmpresa, setIsEmpresaValida, setNomeEmpresa } from '../actions'
import { RequestEmpresaPayload } from '../types'

export default function* getValidaEmpresaSaga(action: AnyAction) {
  const payload = action.payload as RequestEmpresaPayload
  try {
    const novoState = yield* select((state: ApplicationState) => state.novo)

    const { data } = yield* call(getValidaEmpresaNovo, novoState.dadosProfissionais.nomeEmpresa)
    if (data?.success) {
      yield put(setNomeEmpresa(data?.nome))
      yield put(setIsEmpresaValida(true))
      yield payload.callback({
        naoLiberado: false,
      })
    } else {
      yield put(setIsEmpresaValida(false))
      yield payload.callback({
        naoLiberado: true,
      })
    }
  } catch (error) {
    yield put(setIsEmpresaValida(false))
    yield* put(
      showError({
        title: 'Erro ao consultar',
        message: 'Ocorreu um erro ao consultar empresa',
        actionTitle: 'Tentar Novamente',
        block: true,
        code: ErrorCodes.N0_007_VALIDAR_EMPRESA,
        actionCallback: () => getValidaEmpresa(payload),
      }),
    )
  }
}
