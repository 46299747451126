import { BaseServices, HttpMethod } from '@services/base'

export default (
  data: VerificaPendenciaPortabilidadeRequest,
  cpf: string,
  newSimulatorApi: boolean,
) => {
  const pathname = !newSimulatorApi
    ? `${process.env.REACT_APP_VERIFICA_PENDENCIA_PORTABILIDADE}?cpf=${cpf}`
    : `${process.env.REACT_APP_VERIFICA_PENDENCIA_PORTABILIDADE_BFF}`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request({ pathname, method, host, data })
  return response
}

export interface VerificaPendenciaPortabilidadeRequest {
  dataNascimento: string
  cpf: string
  email: string
  primeiroNome: string
  segundoNome: string
  utmCampaign: string
  utmContent: string
  utmMedium: string
  utmSource: string
  clienteId: string
  gaClid: string
  gaClientId: string
  facebookId: string
  upag: string
  matricula: string
  origem: string
  familiaId: number
  telefoneCelular: string
  salarioBruto: number
  valorEconomia: number
  saldoDevedor: number
  valorParcelaNovo: number
  taxaNominalMensal: number
  taxaNominalMensalNovo: number
  valorSolicitado: number
  valorFinanciado: number
  valorParcela: number
  bancoContratoAtual: string
  fontePagamento: string
  convenioDesc: string
  numeroContrato: string
  situacaoFuncional: string
  convenioEstado: string
  convenioCidade: string
  orgaoDescricao: string
  familiaDescricao: string
  formaContato: string
  patente: string
  indicativo: string
  dataIngressoExercito: string
  convenioId: number
  orgaoId: number
  quantidadeParcelasFalta: number
  pendencia: string
  observacao: string
  origemSimulacao: string
}
