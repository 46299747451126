import styled from 'styled-components'

import { Colors } from '@utils/Colors'

/**
 * Botão pequeno das parcelas
 */
export const Button = styled.button<{
  isActive: boolean
  disabled?: boolean
}>`
  width: 56px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.PRIMARY400};
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${(props) => (props.isActive ? Colors.PRIMARY500 : Colors.WHITE)};
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => (props.isActive ? Colors.WHITE : Colors.PRIMARY500)};
`
