import { Reducer } from 'redux'

import { SimulacaoState, SimulacaoTypes } from './types'

const INITIAL_STATE: SimulacaoState = {
  verificaPendenciaLead: false,
  verificaPendenciaPortabilidade: false,
  verificaPendenciaLeadLoading: false,
}

const reducer: Reducer<SimulacaoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SimulacaoTypes.VERIFICA_PENDENCIA_LEAD_REQUEST:
      return {
        ...state,
        loading: true,
        verificaPendenciaLeadLoading: true,
        verificaPendenciaLead: false,
      }
    case SimulacaoTypes.VERIFICA_PENDENCIA_PORTABILIDADE_REQUEST:
      return {
        ...state,
        loading: true,
        verificaPendenciaLeadLoading: true,
        verificaPendenciaPortabilidade: false,
      }
    default:
      return state
  }
}

export default reducer
