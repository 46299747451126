import { BaseServices, HttpMethod } from '@services/base'

export default (nomeEmpresa: string) => {
  const pathname = `${process.env.REACT_APP_GET_EMPRESA}?nomeEmpresa=${nomeEmpresa}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ValidaEmpresaResponse>({
    pathname,
    method,
    host,
  })
  return response
}

export interface ValidaEmpresaResponse {
  success: boolean
  nome: string
}
