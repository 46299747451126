import styled from 'styled-components'

import { Colors } from '@utils/Colors'

export const ContainerPrice = styled.div`
  width: 205px;
  margin-bottom: 24px;
`

export const ContainerSlider = styled.div`
  width: 230px;
  margin-bottom: 16px;
  --input-range-track-color: ${Colors.GRAY200};
`
