import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'

import { cidadesSuccess, cidadesError, cidadesLoading, cidadesRequest } from '../actions'
import { Cidade, CidadesAPI } from '../types'

const cidadesBuild = (data: CidadesAPI): Cidade[] =>
  data && data?.cidades.map((item) => ({ codigo: item.codigo, nome: item.nome }))

export default function* getCidades({ payload }: AnyAction) {
  try {
    yield* put(cidadesLoading())
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const response = yield* call(services.getCidades, payload, newSimulatorApi)
    yield* put(cidadesSuccess(cidadesBuild(response.data)))
  } catch (error) {
    yield* put(cidadesError())
    yield* put(
      showError({
        title: 'Erro ao buscar as cidades',
        message: 'Ocorreu um erro listagem de cidades.',
        actionTitle: 'Tentar Novamente',
        code: ErrorCodes.P0_004_CIDADES,
        actionCallback: () => cidadesRequest(payload),
      }),
    )
  }
}
