import { combineReducers } from 'redux'

import simulacao from './simulacao'
import { SimulacaoState } from './simulacao/types'

export interface PortabilidadeState {
  simulacao: SimulacaoState
}

export default combineReducers({
  simulacao,
})
