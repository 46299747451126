import { put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'

import {
  getDadosUsuarioPropostaSangueLaranja,
  setDadosUsuarioPropostaSangueLaranja,
} from '../actions'

export default function* getDadosUsuarioPropostaSangueLaranjaSaga(action: AnyAction) {
  const { payload } = action

  try {
    const { data } = yield services.getDadosUsuarioPropostaSangueLaranja(payload)

    yield put(setDadosUsuarioPropostaSangueLaranja(data))
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao consultar os dados do usuário',
        message: 'Ocorreu um erro ao consultar os dados do usuário da proposta.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => getDadosUsuarioPropostaSangueLaranja(action.payload),
      }),
    )
  }
}
