enum routes {
  RESUMO = '/simulador/resumo',
  DADOS_RECEBIDOS = '/simulador/dados-recebidos',
  ERRO_SISTEMICO = '/simulador/erro',
  VALIDA_TELEFONE = '/simulador/valida-telefone',
  VALIDA_EMAIL = '/simulador/valida-email',
  DADOS_BASICOS = '/simulador/dados-basicos',
  NAO_LIBERADO = '/simulador/desqualificacao',
  RESULTADO_SIMULACAO = '/simulador/resultado-simulacao',
  CARREGAMENTO_DADOS = '/simulador/carregamento',
  NUMERO_BENEFICIO = '/simulador/inss/numero-beneficio',
  AUTORIZACAO_CONSULTA_INSS = '/simulador/inss/autorizacao-consulta-inss',
  PROPOSTA_RECEBIDA = '/simulador/inss/proposta-recebida',
  OUTROS_CONVENIOS = '/simulador/outros-convenios',
  CONSULTA_MARGEM_SIAPE = '/simulador/consulta-margem-siape',
  DADOS_MANUAIS_SIAPE = '/simulador/dados-manuais-siape',
  FEEDBACK_RESULTADO_MANUAL_SIAPE = '/simulador/resultado-siape-manual',
  OFERTA_PORTABILIDADE = '/simulador/alerta-portabilidade',
  HOME = '/simulador/home',
  FAQ = '/simulador/faq',
  ROOT = '/simulador',
  INSS_INDISPONIVEL = '/simulador/inss/inss-indisponivel',
}
export default routes
