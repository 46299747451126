import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { P, Colors } from '@interco/cp-react-ui-lib'
import { InterUIProgressBar } from '@interco/inter-ui-react-lib'
import { ApplicationState } from '@store/types'
import { setStepVisible } from '@store/ui/steps/actions'

const ProgressBarContainer = styled.div<{
  isApp: boolean
}>`
  ${({ isApp }) =>
    isApp
      ? 'margin-top: 0;'
      : `
    margin-top: 0;
    @media (min-width: 991px){
      margin-top: 0;
    }
  `};
`

const ProgressBarSteps = () => {
  const step = useSelector((state: ApplicationState) => state.ui.steps)
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (step.routeStep !== location.pathname) {
      dispatch(setStepVisible(false))
    }
  }, [location.pathname, dispatch, step.routeStep])

  return step.visible ? (
    <ProgressBarContainer isApp={isApp}>
      <InterUIProgressBar progress={`${step.progress}%`} />
      <P
        variant="inter"
        fontWeight="bold"
        fontSize="12px"
        lineHeight="15px"
        margin="24px 24px 0"
        color={Colors.GRAY400}
      >
        {`${step.stepDescription} ${step.currentStep}/${step.totalSteps}`}
      </P>
    </ProgressBarContainer>
  ) : (
    <></>
  )
}

export default ProgressBarSteps
