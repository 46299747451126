import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { removeMask } from '@utils/masks'

import { Callback, getDadosUsuarioAction, setDataNascimento, setNome } from '../actions'

export default function* getDadosUsuarioSaga(action: AnyAction) {
  const payload = action.payload as Callback
  const cpf = yield* select((state: ApplicationState) => state.novo.dadosPessoais.cpf)

  try {
    const { data } = yield services.getDadosUsuario(removeMask(cpf))

    yield put(setNome(data?.people[0]?.name || ''))
    yield put(setDataNascimento(data?.people[0]?.birthDate || ''))

    if (payload.callback) {
      yield payload.callback(data?.people[0]?.birthDate || '')
    }
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao consultar os dados do usuário',
        message: 'Ocorreu um erro ao consultar os dados do usuário.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => getDadosUsuarioAction(),
      }),
    )
  }
}
