import React from 'react'

interface Props {
  style?: React.CSSProperties | undefined
}

const CheckStepIcon = ({ style }: Props) => (
  <svg
    width="24"
    height="24"
    style={style}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="var(--success500)" />
    <path
      d="M15.0001 10L11 13.9999L9.00012 12"
      stroke="var(--neutral-theme)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

CheckStepIcon.defaultProps = {
  style: undefined,
}

export default CheckStepIcon
