import { ConsultaMargemSiapeApiRequest } from '@services/postConsultaMargemSiape'
import { NovoState } from '@store/novo'

export const buildConsultaMargemSiape = (novoState: NovoState) => {
  const cpf = process.env.REACT_APP_MOCK_CPF_SIAPE || novoState.dadosPessoais?.cpf
  const matricula =
    process.env.REACT_APP_MOCK_MATRICULA_SIAPE || String(novoState.situacaoFuncional?.matricula)

  const objetoConsulta: ConsultaMargemSiapeApiRequest = {
    cpf,
    matricula,
  }

  return objetoConsulta
}
