import styled from 'styled-components'

import { Colors } from '@utils/Colors'

/**
 * Div principal.
 */
export const Container = styled.div<{
  hasEditIcon: boolean
}>`
  padding: 0 2px;
  border-bottom: 1px solid ${Colors.GRAY200};
  ${(props) =>
    props.hasEditIcon &&
    `
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
    `}
`
/**
 * Label do R$.
 */
export const Label = styled.label<{
  colorLabel?: string
  fontSize?: string
  fontWeight?: string
}>`
  text-align: center;
  font-family: Sora;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  font-size: ${({ fontSize }) => fontSize || '28px'};
  color: ${({ colorLabel }) => colorLabel || Colors.GRAY300};
  position: absolute;
`
/**
 * Input de valor.
 */
export const Input = styled.input<{
  colorInput?: string
  fontSize?: string
  fontWeight?: string
}>`
  width: 100%;
  text-align: right;
  font-family: Sora;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  font-size: ${({ fontSize }) => fontSize || '28px'};
  color: ${({ colorInput }) => colorInput || Colors.GRAY500};
  appearance: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  align-self: center;
  letter-spacing: -0.07em;
`
