import { BaseServices, HttpMethod } from '@services/base'

export default (data: ValidaRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_VALIDA_PENDENCIA
    : process.env.REACT_APP_VALIDA_PENDENCIA_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ValidaResponse>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface ValidaRequest {
  cpf: string
  matricula: string
  convenio: number
  consultaMargemAutorizada: boolean
  riscoCpf: number
  limiteAutorizado: number
}

export interface ValidaResponse {
  tipoPendencia: string
  descricaoPendencia: string
  observacao: string
}
