export enum StepTypes {
  SET_STEP = '@steps/SET_STEP',
  SET_STEP_VISIBLE = '@steps/SET_STEP_VISIBLE',
  SET_STEP_DESCRIPTION = '@steps/SET_STEP_DESCRIPTION',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface StepState {
  readonly visible: boolean
  readonly progress?: number
  readonly currentStep?: number
  readonly totalSteps?: number
  readonly routeStep?: string
  readonly stepDescription?: string
}

export interface PayloadStepType {
  readonly currentStep: number
  readonly totalSteps: number
  readonly visible: boolean
  readonly routeStep?: string
}
