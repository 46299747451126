import { createStyles } from '@material-ui/core'

const styles = createStyles({
  root: {
    width: '100%',
  },
  fullWidth: {
    width: '100%',
    margin: 'auto',
    maxWidth: '100%',
  },
})

export default styles
