import { action, Action } from 'typesafe-actions'

import { ValidaTypes, ValidaRequestPayload, ValidaPendenciaRequestPayload } from './types'

export const validaRequest = (payload: ValidaRequestPayload): Action =>
  action(ValidaTypes.VALIDA_REQUEST, payload)

export const setLoading = (payload: boolean): Action =>
  action(ValidaTypes.VALIDA_SET_LOADING, payload)

export const validaPendenciaRequest = (payload: ValidaPendenciaRequestPayload): Action =>
  action(ValidaTypes.VALIDA_PENDENCIA_REQUEST, payload)

export const validaReformaRequest = (payload: ValidaRequestPayload): Action =>
  action(ValidaTypes.VALIDA_REFORMA_REQUEST, payload)

export const setReformaLoading = (payload: boolean): Action =>
  action(ValidaTypes.VALIDA_REFORMA_SET_LOADING, payload)
