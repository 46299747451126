import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import postValidaNovo, { ValidaRequest } from '@services/postValidaNovo'
import { ApplicationState } from '@store/types'
import { FamiliaEnum, findUpag, datePtToEn } from '@utils/simuladorUtils'
import { TipoEmprestimo } from '@utils/enums'

import { ValidaRequestPayload } from '../types'
import { setLoading } from '../actions'

export default function* postValida(action: AnyAction) {
  const payload = action.payload as ValidaRequestPayload
  try {
    yield put(setLoading(true))
    const { dadosPessoais, situacaoFuncional, fontePagamento, simulador, simulacao } = yield select(
      (state: ApplicationState) => state.novo,
    )

    const globalApi = yield* select((state: ApplicationState) => state.globalApi)
    const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)
    const { familia } = simulador
    const isFamiliaPrivada = familia === FamiliaEnum.EMPRESA

    const cidadeSelecionada = globalApi.cidades?.find(
      (item) => item.codigo === fontePagamento?.cidadeSelecionada,
    )
    const tipoSimulacao = simulacao?.simulacaoSelecionada
    const tipoSimulacoes = simulacao?.simulacoesSelecionada
    const simulationData = simulacao[tipoSimulacao]

    const verificarCreditoProtegido = () => {
      if (newSimulatorApi) {
        return tipoSimulacoes === TipoEmprestimo.CREDITO_PROTEGIDO_ARRAY
      }
      return tipoSimulacao === TipoEmprestimo.CREDITO_PROTEGIDO
    }

    const validarCPF: ValidaRequest = {
      cpf: dadosPessoais?.cpf,
      upag: findUpag(globalApi?.upags, String(situacaoFuncional?.upag)) || '',
      estado: fontePagamento?.estadoSelecionado,
      cidade: cidadeSelecionada?.nome || '',
      idConvenio: isFamiliaPrivada ? 47 : Number(fontePagamento.convenioSelecionado) || 0,
      valorSolicitado: newSimulatorApi
        ? simulacao.parcelaAtual.valorSolicitado
        : simulationData.valorSolicitado,
      dataUltimoVencimento: newSimulatorApi
        ? simulacao.parcelaAtual.dataUltimoVencimento
        : simulationData.dataUltimoVencimento,
      dataNascimento: datePtToEn(dadosPessoais?.dataNascimento),
      simulacaoComSeguro: verificarCreditoProtegido(),
    }

    const response = yield* call(postValidaNovo, validarCPF, newSimulatorApi)
    yield payload.callback({
      contato: response?.data?.contato,
      riscoCpf: response.data?.riscoCpf,
      pendencia: response.data?.pendencia,
      descricaoPendenciaRisco: response.data?.descricaoPendencia,
    })
  } catch (error) {
    yield put(setLoading(false))
    yield payload.callback({
      contato: false,
      riscoCpf: 0,
      pendencia: '',
      descricaoPendenciaRisco: '',
    })
  }
}
