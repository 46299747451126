import { BaseServices, HttpMethod } from '@services/base'

export default (cpf: string | undefined) => {
  const pathname = process.env.REACT_APP_INFO_USER
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers = {
    socialDocument: String(cpf),
  }

  return BaseServices.request<ResponseDadosUsuario>({
    headers,
    pathname,
    method,
    host,
  })
}

export interface ResponseDadosUsuario {
  people: {
    cpf: string
    name: string
    birthDate: string
  }[]
}
