import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { ApplicationState } from '@store/types'
import postValidaPendencia, { ValidaRequest } from '@services/postValidaPendencia'
import { convenioMargemAutorizada, FamiliaEnum } from '@utils/simuladorUtils'
import { NovoState } from '@store/novo'

import { setLoading } from '../actions'
import { ValidaPendenciaRequestPayload } from '../types'

export default function* postValidaPendenciaSaga(action: AnyAction) {
  const payload = action.payload as ValidaPendenciaRequestPayload
  try {
    const { dadosPessoais, situacaoFuncional, fontePagamento, simulador } = yield select(
      (state: ApplicationState) => state.novo,
    )
    const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)
    const storeNovo: NovoState = yield select((state: ApplicationState) => state.novo)

    const { familia } = simulador
    const isFamiliaPrivada = familia === FamiliaEnum.EMPRESA

    const validaPendencia: ValidaRequest = {
      cpf: dadosPessoais?.cpf,
      matricula: situacaoFuncional?.matricula,
      convenio: isFamiliaPrivada ? 47 : Number(fontePagamento.convenioSelecionado) || 0,
      consultaMargemAutorizada: convenioMargemAutorizada(storeNovo),
      riscoCpf: payload.riscoCpf,
      limiteAutorizado: (payload.riscoCpf / 100) * 140,
    }

    const response = yield* call(postValidaPendencia, validaPendencia, newSimulatorApi)

    yield payload.callback({
      tipoPendencia: response.data?.tipoPendencia,
      descricaoPendencia: response.data?.descricaoPendencia,
      observacao: response.data?.observacao,
    })
  } catch (error) {
    yield put(setLoading(false))
    yield payload.callback({ tipoPendencia: '', descricaoPendencia: '', observacao: '' })
  }
}
