import { put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'

import { atualizarCacheSiapeRequest } from '../actions'

export default function* postAtualizarCacheSiapeSaga(action: AnyAction) {
  const payload = action.payload as PayloadAtualizarCacheSiape

  try {
    yield services.postAtualizarCacheSiape(
      payload.socialNumber,
      payload.salary,
      payload.authorizedMargin,
    )

    yield payload.callback()
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao atualizar o cache do siape',
        message: 'Ocorreu um erro ao atualizar o cache do siape.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => atualizarCacheSiapeRequest(action.payload),
      }),
    )
  }
}

export interface PayloadAtualizarCacheSiape {
  callback: () => void
  socialNumber: string
  salary: string
  authorizedMargin: boolean
}
