import { BaseServices, HttpMethod } from '@services/base'

export default (data: MargemExercitoRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_POST_MARGEM_EXERCITO
    : process.env.REACT_APP_POST_MARGEM_EXERCITO_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<MargemExercitoResponse>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface MargemExercitoRequest {
  cpf: string
  matricula: string
  senhaServidor: string
  matriculaMultipla: boolean
}

export interface MargemExercitoResponse {
  tentativas?: number
  situacaoFuncional?: string
  indicativo?: string
  dataIngresso?: string
  pendencia: string
}
