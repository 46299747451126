import { put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'

import {
  putAtualizarContratoSangueLaranja,
  putAtualizarContratoSangueLaranjaSuccess,
  putAtualizarContratoSangueLaranjaError,
} from '../actions'

export default function* putAtualizarPropostaSaga(action: AnyAction) {
  const payload = action.payload as PayloadSalvarContrato

  try {
    yield services.putAtualizarProposta(payload.codigoProposta)

    yield put(putAtualizarContratoSangueLaranjaSuccess())
    yield payload.callback()
  } catch (error) {
    yield put(putAtualizarContratoSangueLaranjaError())
    yield put(
      showError({
        title: 'Erro ao atualizar status da proposta sangue laranja',
        message: 'Ocorreu um erro ao atualizar status da proposta sangue laranja.',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => putAtualizarContratoSangueLaranja(action.payload),
      }),
    )
  }
}

export interface PayloadSalvarContrato {
  callback: () => void
  codigoProposta: string
}
