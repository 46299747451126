import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import postVerificaPendenciaPortabilidade, {
  VerificaPendenciaPortabilidadeRequest,
} from '@services/postVerificaPendenciaPortabilidade'
import { ApplicationState } from '@store/types'
import { verificaPendenciaPortabilidadeRequest } from '@store/portabilidade/simulacao/actions'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'

import { buildVerificaPendenciaPortabilidadeRequest } from './models'
import { VerificaPendenciaPortabilidadePayload } from '../types'

export default function* postVerificaPendenciaPortabilidadeSaga(action: AnyAction) {
  const payload = action.payload as VerificaPendenciaPortabilidadePayload
  try {
    const state = yield* select((s: ApplicationState) => s)
    const { newSimulatorApi } = yield* select((s: ApplicationState) => s.ui.navigation)
    const lead: VerificaPendenciaPortabilidadeRequest =
      buildVerificaPendenciaPortabilidadeRequest(state)
    yield* call(
      postVerificaPendenciaPortabilidade,
      lead,
      state.novo.dadosPessoais.cpf,
      newSimulatorApi,
    )
    yield payload.callback()
  } catch (error) {
    yield* put(
      showError({
        title: 'Erro ao salvar',
        message: 'Ocorreu um erro ao salvar suas informações',
        actionTitle: 'Tentar Novamente',
        block: true,
        code: ErrorCodes.P0_010_VERIFICA_PENDENCIA_LEAD,
        actionCallback: () => verificaPendenciaPortabilidadeRequest(payload),
      }),
    )
  }
}
