import styled from 'styled-components'

import { InterUIBottomSheet } from '@interco/inter-ui-react-lib'
import { FontFamily } from '@interco/cp-react-ui-lib'
import { DialogContent } from '@material-ui/core'

export const DialogTitleStyled = styled(DialogContent)`
  padding: 0 24px;
  font-family: ${FontFamily.Sora};
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
`

export const DialogContentStyled = styled(DialogContent)`
  padding: 24px;
  &:first-child {
    padding-top: 24px;
  }
`

export const BottomSheetStyled = styled(InterUIBottomSheet)`
  margin: 0 auto;
`
