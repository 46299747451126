import { Reducer } from 'redux'

import { DadosProfissionaisState, DadosProfissionaisTypes } from './types'

const INITIAL_STATE: DadosProfissionaisState = {
  nomeEmpresa: '',
  salario: 0,
  tempoEmpresaSelecionado: '',
  error: false,
  loading: false,
  isEmpresaValida: true,
}

const reducer: Reducer<DadosProfissionaisState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DadosProfissionaisTypes.SET_NOME_EMPRESA:
      return { ...state, nomeEmpresa: action.payload }
    case DadosProfissionaisTypes.SET_SALARIO:
      return { ...state, salario: action.payload }
    case DadosProfissionaisTypes.SET_TEMPO_EMPRESA:
      return { ...state, tempoEmpresaSelecionado: action.payload }
    case DadosProfissionaisTypes.REQUEST_VALIDA_EMPRESA:
      return { ...state, error: false, loading: true }
    case DadosProfissionaisTypes.SET_IS_EMPRESA_VALIDA:
      return { ...state, isEmpresaValida: action.payload }
    case DadosProfissionaisTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
