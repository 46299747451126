import { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { Estado } from '@store/globalApi/types'

interface FetchEstados {
  estados: Estado[]
  estadosRequest(): void
  estadosLoading: boolean
}

const useFetchEstados = ({ estados, estadosRequest, estadosLoading }: FetchEstados) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if ((!estados || estados.length === 0) && !estadosLoading) {
      dispatch(estadosRequest())
    }
  }, [estados, estadosRequest, dispatch, estadosLoading])
}

export default useFetchEstados
