import { BaseServices, HttpMethod } from '@services/base'

export default (data: SimulacaoNovoRequest, path: string, newSimulatorApi: boolean) => {
  const pathSimulator = !newSimulatorApi
    ? process.env.REACT_APP_SIMULATE_PATH_NOVO
    : process.env.REACT_APP_SIMULATE_PATH_NOVO_BFF
  const pathname = `${pathSimulator}/${path}`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<SimulacaoNovoResponse>({
    pathname,
    method,
    host,
    data,
  })
  return response
}

/**
 * API Types
 */
export interface SimulacaoNovoRequest {
  codigoAreaAcesso: string
  codigoConvenio: number
  descConvenio: string
  codigoOrgao: number
  descOrgao: string
  cpf: string
  dataNascimento: string
  email: string
  matricula: string | number
  nome: string
  origem: number
  qtdParcelas: number
  salario: number
  telefone: string
  valorSolicitacao?: number
  mostrarTodasParcelas: boolean
}

export interface SimulacaoRequest {
  cETAa: number
  cETAm?: number
  dataUltimoVencimento: string
  qtdeParcelas: number
  taxaNominalAm: number
  tIRAm: number
  tIRAa: number
  valorFinanciado: number
  valorIOF: number
  valorLiberado: number
  valorParcela: number
  valorSolicitado: number
}

export interface SimulacoesSangueLaranjaRequest {
  qtdeParcelas: number
  valorSolicitado: number
  valorParcela: number
  valorLiberado: number
  valorIOF: number
  valorIOFPercentual: number
  valorSeguro: number
  valorSeguroPercentual: number
  tarifaCadastro: number
  tarifaCadastroPercentual: number
  valorFinanciado: number
  taxaTirAm: number
  taxaTirAa: number
  taxaCetAa: number
  taxaCetAm: number
  taxaNominalAm: number
  taxaNomialAa: number
  dataUltimoVencimento: string
  dataPrimeiroVencimento: string
  jurosContrados: number
  jurosContradosPercentual: number
  valorTotalASerPago: number
}

export interface SimulacoesRequest {
  qtdeParcelas: number
  valorSolicitado: number
  valorParcela: number
  valorLiberado: number
  valorIOF: number
  valorIOFPercentual: number
  valorSeguro: number
  valorSeguroPercentual: number
  tarifaCadastro: number
  tarifaCadastroPercentual: number
  valorFinanciado: number
  tIRAm: number
  cETAa: number
  cETAm: number
  tIRAa: number
  taxaNominalAm: number
  taxaNomialAa: number
  dataUltimoVencimento: string
  dataPrimeiroVencimento: string
  jurosContrados: number
  jurosContradosPercentual: number
  valorTotalASerPago: number
}

export interface SimulacaoNovoResponse {
  limiteCartao: number
  margemConsultada: boolean
  simulacao: SimulacaoRequest
  simulacaoComSeguro: SimulacaoRequest
  simulacoes: SimulacoesRequest[]
  simulacoesComSeguro: SimulacoesRequest[]
  valorMaximo: number
  valorMinimo: number
  valorMaximoComSeguro?: number
}
