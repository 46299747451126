import { CidadesAPI } from '@store/globalApi/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (estadoUf: string, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? `${process.env.REACT_APP_GET_CIDADES}/${estadoUf}`
    : `${process.env.REACT_APP_GET_CIDADES_BFF}?state=${estadoUf}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request<CidadesAPI>({
    pathname,
    method,
    host,
  })
}
