/**
 * Actions Types
 */

import { CodigoMgmTypes } from '@services/postSalvarLeadNovo'

export enum CodIndicacaoContatoTypes {
  SET_CODIGO_INDICACAO = '@novo/codIndicacaoContato/SET_CODIGO_INDICACAO',
  SET_TELEFONE = '@novo/codIndicacaoContato/SET_TELEFONE',
  SET_EMAIL = '@novo/codIndicacaoContato/SET_EMAIL',
  SET_CHECK_AUTORIZACAO = '@novo/codIndicacaoContato/SET_CHECK_AUTORIZACAO',
  VALIDA_INDICACAO_REQUEST = '@novo/codIndicacaoContato/VALIDA_INDICACAO_REQUEST',
  VALIDA_INDICACAO_SUCCESS = '@novo/codIndicacaoContato/VALIDA_INDICACAO_SUCCESS',
  VALIDA_INDICACAO_ERROR = '@novo/codIndicacaoContato/VALIDA_INDICACAO_ERROR',
  RESET_INDICACAO = '@novo/codIndicacaoContato/RESET_INDICACAO',
  SET_VALIDACAO_CODIGO_MGM = '@novo/codIndicacaoContato/SET_VALIDACAO_CODIGO_MGM',
  DATA_LAKE_CONTATO_REQUEST = '@novo/codIndicacaoContato/DATA_LAKE_CONTATO_REQUEST',
  DATA_LAKE_CONTATO_SUCCESS = '@novo/codIndicacaoContato/DATA_LAKE_CONTATO_SUCCESS',
  DATA_LAKE_CONTATO_ERROR = '@novo/codIndicacaoContato/DATA_LAKE_CONTATO_ERROR',
  SET_FLAG_INFO_CREDITO = '@novo/codIndicacaoContato/SET_FLAG_INFO_CREDITO',
  SET_EDITAR_CODIGO = '@novo/codIndicacaoContato/SET_EDITAR_CODIGO',
  RESET_INDICACAO_APP = '@novo/codIndicacaoContato/RESET_INDICACAO_APP',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface CodIndicacaoContatoState {
  readonly codigoIndicacao: string
  readonly telefone: string
  readonly email: string
  readonly checkAutorizacao: boolean
  readonly codigoError: boolean
  readonly codigoLoading: boolean
  readonly validacaoCodigoMgm: CodigoMgmTypes
  readonly flagInfoCredito: boolean
  readonly editarCodigo: boolean
}

/**
 * Payloads Types
 */
export interface ValidaIndicacaoActionPayload {
  codigo: string
  success?: () => void
  error?: () => void
}

export interface DataLakeContatoRequestPayload {
  callback: () => void
}
