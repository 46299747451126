import React from 'react'

import { InputPrice } from '@atoms'
import { TextParagraph } from '@atoms/TextParagraph'
import { formatCurrency } from '@utils/masks'
import { Colors } from '@utils/Colors'

import { ContainerPrice, ContainerText } from './styles'

interface Props {
  min: number
  max: number
  valorTotal: number
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SimulatorInput = ({ min, max, valorTotal, onChangeInput, handleBlur }: Props) => (
  <>
    <ContainerPrice>
      <InputPrice
        value={valorTotal}
        onChangeInput={onChangeInput}
        onBlur={handleBlur}
        hasEditIcon
        colorInput={Colors.PRIMARY500}
        colorLabel={Colors.PRIMARY500}
        fontSize="24px"
        fontWeight="600"
      />
    </ContainerPrice>
    <ContainerText>
      <TextParagraph variant="caption-1">Mín. {formatCurrency(min)}</TextParagraph>
      <TextParagraph variant="caption-1">Máx. {formatCurrency(max)}</TextParagraph>
    </ContainerText>
  </>
)

export default SimulatorInput
