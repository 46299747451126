import { action, Action } from 'typesafe-actions'

import { SituacaoFuncionalTypes, ValidarMatriculaPayload } from './types'

export const setMatricula = (payload: string): Action =>
  action(SituacaoFuncionalTypes.SET_MATRICULA, payload)

export const setIsMatriculaValida = (payload: boolean): Action =>
  action(SituacaoFuncionalTypes.SET_MATRICULA_VALIDA, payload)

export const validarMatriculaRequest = (payload: ValidarMatriculaPayload): Action =>
  action(SituacaoFuncionalTypes.VALIDAR_MATRICULA, payload)

export const setSalario = (payload: number): Action =>
  action(SituacaoFuncionalTypes.SET_SALARIO, payload)

export const setSituacao = (payload: string | string[] | number[] | number | undefined): Action =>
  action(SituacaoFuncionalTypes.SET_SITUACAO, payload)

export const setUpag = (payload: string | string[] | number[] | number | undefined): Action =>
  action(SituacaoFuncionalTypes.SET_UPAG, payload)

export const setMotivo = (payload: string | string[] | number[] | number | undefined): Action =>
  action(SituacaoFuncionalTypes.SET_MOTIVO, payload)

export const upagsRequest = () => action(SituacaoFuncionalTypes.UPAGS_REQUESTS)

export const setPatente = (payload: string | string[] | number[] | number | undefined): Action =>
  action(SituacaoFuncionalTypes.SET_PATENTE, payload)

export const setIndicativo = (payload: string | string[] | number[] | number | undefined): Action =>
  action(SituacaoFuncionalTypes.SET_INDICATIVO, payload)

export const setDataIngresso = (payload: string | number | undefined): Action =>
  action(SituacaoFuncionalTypes.SET_DATA_INGRESSO, payload)

export const clearSituacaoFuncional = (): Action =>
  action(SituacaoFuncionalTypes.CLEAR_SITUACAOFUNCIONAL)

export const clearErrorMatricula = (): Action =>
  action(SituacaoFuncionalTypes.CLEAR_ERROR_MATRICULA)
