import React from 'react'

import { GridForIcon } from './styles'

interface OwnProps {
  children: JSX.Element | JSX.Element[]
  margin?: string
  justify?:
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'inherit'
    | 'initial'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
    | undefined
  className?: string
}

const GridStyled = ({ children, justify, className, margin }: OwnProps) => (
  <GridForIcon margin={margin} justifyContent={justify} className={className}>
    {children}
  </GridForIcon>
)

GridStyled.defaultProps = {
  margin: '16px 0 0',
}

export default GridStyled
