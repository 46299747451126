enum routes {
  DADOS_RECEBIDOS = '/simulador-novo/dados-recebidos',
  RESUMO_EMPRESTIMO = '/simulador-novo/resumo-emprestimo',
  VERIFICAR_VALOR_SIMULADO = '/simulador-novo/verificar-valor',
  OFERTA_PORTABILIDADE = '/simulador-novo/oferta-portabilidade',
  NEGATIVA_PARCERIA = '/simulador-novo/negativa-parceria',
  INDICACAO = '/simulador-novo/indicacao',
  CONSULTA_MARGEM_EXERCITO = '/simulador-novo/consulta-margem-exercito',
  CONSULTA_MARGEM_EXERCITO_AVERBACAO = '/simulador-novo/consulta-margem-exercito-averbacao',
  CONSULTA_MARGEM_RIO_MATRICULA = '/simulador-novo/consulta-margem-rio-matricula',
  CONSULTA_MARGEM_RIO = '/simulador-novo/consulta-margem-rio',
  CONSULTA_MARGEM_RIO_AVERBACAO = '/simulador-novo/consulta-margem-rio-averbacao',
  GRUPO_FORCAS_ARMADAS_FONTE_PAGAMENTO = '/simulador-novo/grupo-forcas-armadas-fonte-pagamento',
  GRUPO_FORCAS_ARMADAS_SITUACAO_FUNCIONAL = '/simulador-novo/grupo-forcas-armadas-situacao-funcional',
  GRUPO_FORCAS_ARMADAS = '/simulador-novo/grupo-forcas-armadas',
  GRUPO_TRIBUNAIS = '/simulador-novo/grupo-tribunais',
  CONSULTA_MARGEM_SIAPE = '/simulador-novo/consulta-margem-siape',
  GRUPO_SIAPE = '/simulador-novo/grupo-siape',
  GRUPO_SIAPE_SITUACAO_FUNCIONAL = '/simulador-novo/grupo-siape-situacao-funcional',
  GRUPO_ESTADUAL = '/simulador-novo/grupo-estadual',
  CONSULTA_MARGEM_CIP = '/simulador-novo/consulta-margem-cip',
  GRUPO_MUNICIPAL = '/simulador-novo/grupo-municipal',
  GRUPO_AUTONOMO = '/simulador-novo/grupo-autonomo',
  ONBOARDING = '/simulador-novo/onboarding',
  SELECAO_GRUPO = '/simulador-novo/selecao-grupo',
  TOKEN = '/simulador-novo/token',
  CONTATO = '/simulador-novo/contato',
  NAO_LIBERADO = '/simulador-novo/nao-liberado',
  DADOS_PESSOAIS = '/simulador-novo/dados-pessoais',
  CALCULADORA = '/simulador-novo/calculadora',
  ROOT = '/simulador-novo',
}
export default routes
