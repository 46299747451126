import React from 'react'

import { Button } from '@interco/inter-ui/components/Button'
import GridStyled from '@atoms/GridStyled'
import Paragraph from '@atoms/Paragraph'
import { NovoPage } from '@components/templates'
import { Colors } from '@utils/Colors'
import { ErrorIcon, PendingIcon, SuccessIcon } from '@assets/icons/Signals'

type Feedback = 'alerta' | 'negativa' | 'sucesso'
type Props = {
  title: string
  description: string
  secondDescription?: string
  onClickButton: () => void
  feedbackType?: Feedback
}

const iconType = (feedbackType: Feedback) => {
  if (feedbackType === 'negativa') {
    return <ErrorIcon />
  }
  if (feedbackType === 'alerta') {
    return <PendingIcon />
  }
  return <SuccessIcon />
}

const FeedbackScreen = ({
  title,
  description,
  secondDescription,
  onClickButton,
  feedbackType = 'negativa',
}: Props) => (
  <NovoPage
    id="page-novo-simulador-negativa"
    stickyFooter={
      <Button variant="primary" onClick={onClickButton}>
        Voltar ao início
      </Button>
    }
  >
    <GridStyled justify="space-around" margin="40px 0 0">
      {iconType(feedbackType)}
    </GridStyled>

    <Paragraph
      variant="sora"
      fontSize="24px"
      fontWeight="600"
      lineHeight="28px"
      color={Colors.GRAY500}
      textAlign="left"
      margin="40px 0 8px"
    >
      {title}
    </Paragraph>
    <Paragraph textAlign="left" variant="inter">
      {description}
    </Paragraph>
    {secondDescription ? (
      <Paragraph textAlign="left" variant="inter" margin="10px 0 0">
        {secondDescription}
      </Paragraph>
    ) : (
      <></>
    )}
  </NovoPage>
)

export default FeedbackScreen
