import qs from 'qs'

import { BaseServices, HttpMethod } from '@services/base'

export default (data: ValidaIndicacaoRequest) => {
  const pathname = `${process.env.REACT_APP_VALIDA_CODIGO_INDICACAO}?${qs.stringify(data)}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ValidaIndicacaoResponse>({
    pathname,
    method,
    host,
  })
  return response
}

export interface ValidaIndicacaoRequest {
  codigo: string
  cpfUtilizador: string
  campanha: string
}

export interface ValidaIndicacaoResponse {
  status: string
  valido: string
}
