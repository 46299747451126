import { action, Action } from 'typesafe-actions'

import { ConsultaRioTypes, ValidaSenhaRioPayload } from './types'

export const setTentativasRio = (payload: number): Action =>
  action(ConsultaRioTypes.SET_TENTATIVAS_RIO, payload)

export const setAverbacaoValidaRio = (payload: boolean): Action =>
  action(ConsultaRioTypes.SET_AVERBACAO_VALIDA_RIO, payload)

export const setSenhaAverbacaoRio = (payload: string): Action =>
  action(ConsultaRioTypes.SET_SENHA_AVERBACAO_RIO, payload)

export const postValidaSenhaRio = (payload: ValidaSenhaRioPayload): Action =>
  action(ConsultaRioTypes.POST_VALIDA_SENHA_RIO, payload)

export const setLoadingRio = (payload: boolean): Action =>
  action(ConsultaRioTypes.SET_LOADING_RIO, payload)

export const clearErrorRio = (): Action => action(ConsultaRioTypes.CLEAR_ERROR_RIO)

export const setConsultaRealizada = (payload: boolean): Action =>
  action(ConsultaRioTypes.SET_CONSULTA_REALIZADA, payload)

export const setIdFuncional = (payload: string): Action =>
  action(ConsultaRioTypes.SET_ID_FUNCIONAL, payload)

export const setVinculo = (payload: string): Action => action(ConsultaRioTypes.SET_VINCULO, payload)

export const setNumeroPensionista = (payload: string): Action =>
  action(ConsultaRioTypes.SET_NUMERO_PENSIONISTA, payload)

export const setValorMargem = (payload: number): Action =>
  action(ConsultaRioTypes.SET_VALOR_MARGEM, payload)
