import { action, Action } from 'typesafe-actions'

import { ParcelasTypes, FamiliaType } from '@utils/simuladorUtils'

import { SimuladorTypes } from './types'

export const setValorTotal = (payload: number): Action =>
  action(SimuladorTypes.SET_VALOR_TOTAL, payload)
export const setParcelas = (payload: ParcelasTypes): Action =>
  action(SimuladorTypes.SET_PARCELAS, payload)
export const setIsTotal = (payload: {
  isTotal: boolean
  familia: FamiliaType
  parcelas: ParcelasTypes
}): Action => action(SimuladorTypes.SET_IS_TOTAL, payload)
export const setValorSimulado = (payload: string): Action =>
  action(SimuladorTypes.SET_VALOR_SIMULADO, payload)
export const setFamilia = (payload: string): Action =>
  action(SimuladorTypes.SET_FAMILIA_SIMULACAO, payload)
export const setNovoValorTotal = (payload: number): Action =>
  action(SimuladorTypes.SET_NOVO_VALOR_TOTAL, payload)

export const resetAllCache = (): Action => action(SimuladorTypes.RESET)
