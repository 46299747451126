import React from 'react'

export default () => (
  <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="64" height="64" rx="32" fill="#FFC700" />
    <circle
      cx="31.9998"
      cy="32.4998"
      r="13.3333"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32 25.8335V33.8335L36 36.5002"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
