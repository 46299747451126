import { action, Action } from 'typesafe-actions'

import { PayloadStepType, StepTypes } from './types'

export const setStep = (payload: PayloadStepType): Action => action(StepTypes.SET_STEP, payload)
export const setStepVisible = (payload: boolean): Action =>
  action(StepTypes.SET_STEP_VISIBLE, payload)

export const setStepDescription = (payload: string): Action =>
  action(StepTypes.SET_STEP_DESCRIPTION, payload)
