import { UpagApi } from '@store/globalApi/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_GET_UPAG
    : process.env.REACT_APP_GET_UPAG_BFF
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<UpagApi>({
    pathname,
    method,
    host,
  })
  return response
}
