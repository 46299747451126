/**
 * Actions Types
 */

import { PageMargemCipEnum } from '@utils/enums'

export enum ConsultaMargemCipTypes {
  SET_PAGES_CONSULTA_MARGEM_CIP = '@novo/simulacao/SET_PAGES_CONSULTA_MARGEM_CIP',
  SET_CHECK_CONSULTA_MARGEM_CIP = '@novo/simulacao/SET_CHECK_CONSULTA_MARGEM_CIP',
  CONSULTA_MARGEM_CIP_SUCCESS = '@novo/situacaoFuncional/CONSULTA_MARGEM_CIP_SUCCESS',
  CONSULTA_MARGEM_CIP_SUCCESS_CALLBACK = '@novo/situacaoFuncional/CONSULTA_MARGEM_CIP_SUCCESS_CALLBACK',
  CONSULTA_MARGEM_CIP_ERROR = '@novo/situacaoFuncional/CONSULTA_MARGEM_CIP_ERROR',
  CONSULTA_MARGEM_CIP_REQUEST = '@novo/situacaoFuncional/CONSULTA_MARGEM_CIP_REQUEST',
  SET_VALOR_MARGEM = '@novo/situacaoFuncional/cip/SET_VALOR_MARGEM',

  RESET = 'RESET',
}

/**
 * State Type
 */

export interface ConsultaMargemCipState {
  readonly pageAtual: PageMargemCipEnum
  readonly checkConsultaMargem: boolean
  readonly loading: boolean
  readonly error: boolean
  readonly consultaMargemAutorizada: boolean
  readonly margem: number
}

export type ConsultaMargemCipPayload = {
  callback: (tentativas: number, margem: number, pendencia: string) => void
}
