import { Reducer } from 'redux'

import { StepState, StepTypes } from './types'

const INITIAL_STATE: StepState = {
  visible: false,
  progress: 0,
  currentStep: 0,
  totalSteps: 0,
  stepDescription: 'Passo',
}

const reducer: Reducer<StepState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StepTypes.SET_STEP_VISIBLE:
      return { ...state, visible: action.payload }
    case StepTypes.SET_STEP:
      return {
        ...state,
        progress: (action.payload.currentStep / action.payload.totalSteps) * 100,
        ...action.payload,
      }
    case StepTypes.SET_STEP_DESCRIPTION:
      return { ...state, stepDescription: action.payload }
    case StepTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
