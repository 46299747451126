import React from 'react'

import { withStyles, Slider } from '@material-ui/core'
import { Colors } from '@utils/Colors'

const SliderInput = withStyles({
  root: {
    color: Colors.PRIMARY500,
    height: '7px',
    marginTop: '10px',
  },
  thumb: {
    backgroundColor: Colors.WHITE,
    width: '18.2px',
    height: '18.2px',
    '&:focus,&:hover,&$active': {
      boxShadow:
        '0px 2px 2px rgba(0, 0, 0, 0.1), 0px 3px 3px rgba(0, 0, 0, 0.05), 0px 3px 1px rgba(0, 0, 0, 0.05)',
    },
    border: '0.5px solid rgba(0, 0, 0, 0.1)',
    boxShadow:
      '0px 3px 1px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1)',
    borderRadius: '60px',
  },
  active: {
    background: `radial-gradient(143.29% 154.69% at 50% 50%, ${Colors.WHITE} 23.47%, ${Colors.GRAY500} 100%)`,
  },
  track: {
    height: '7px',
    backgroundColorf: Colors.PRIMARY500,
    borderRadius: '10px',
  },
  rail: {
    height: '7px',
    borderRadius: 10,
    color: Colors.GRAY200,
  },
})(Slider)

interface Props {
  value: number
  onChange: (i: number | number[]) => void
  min: number
  max: number
  step: number
}

const CustomSlider = ({ onChange, value, min, max, step }: Props) => (
  <SliderInput
    value={value}
    min={min || 0}
    max={max || 100}
    step={step || 1}
    onChange={(event, newValue) => onChange(newValue)}
    aria-labelledby="input-slider"
  />
)

export default CustomSlider
