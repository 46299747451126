import React, { memo } from 'react'

import { Grid } from '@material-ui/core'
import { CircularProgress } from '@atoms'

interface Props {
  height?: number | string
  margin?: string
  size?: number
  thickness?: number
}

const InnerLoading = ({ height, margin, size, thickness }: Props) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    style={{ height: height || '100%', margin }}
  >
    <CircularProgress size={size} thickness={thickness} />
  </Grid>
)

InnerLoading.defaultProps = {
  height: '100%',
  margin: '0',
  size: 35,
  thickness: 4,
}

export default memo(InnerLoading)
