import { Reducer } from 'redux'

import { PageMargemCipEnum } from '@utils/enums'

import { ConsultaMargemCipState, ConsultaMargemCipTypes } from './types'

const INITIAL_STATE: ConsultaMargemCipState = {
  pageAtual: PageMargemCipEnum.CONSULTA_MARGEM,
  checkConsultaMargem: false,
  loading: false,
  error: false,
  consultaMargemAutorizada: false,
  margem: 0,
}

const reducer: Reducer<ConsultaMargemCipState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConsultaMargemCipTypes.SET_PAGES_CONSULTA_MARGEM_CIP:
      return { ...state, pageAtual: action.payload }
    case ConsultaMargemCipTypes.SET_CHECK_CONSULTA_MARGEM_CIP:
      return { ...state, checkConsultaMargem: action.payload }
    case ConsultaMargemCipTypes.CONSULTA_MARGEM_CIP_REQUEST:
      return { ...state, consultaMargemAutorizada: false, error: false, loading: true }
    case ConsultaMargemCipTypes.CONSULTA_MARGEM_CIP_SUCCESS:
      return {
        ...state,
        consultaMargemAutorizada: true,
        error: false,
        loading: false,
      }
    case ConsultaMargemCipTypes.CONSULTA_MARGEM_CIP_SUCCESS_CALLBACK:
      return {
        ...state,
        consultaMargemAutorizada: false,
        error: false,
        loading: false,
      }
    case ConsultaMargemCipTypes.CONSULTA_MARGEM_CIP_ERROR:
      return { ...state, error: true, loading: false }
    case ConsultaMargemCipTypes.SET_VALOR_MARGEM:
      return { ...state, margem: action.payload }
    case ConsultaMargemCipTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
