import React from 'react'

import clsx from 'clsx'

import { withStyles, withWidth, Grid } from '@material-ui/core'

import Props from './types'
import styles from './styles'

const DefaultGridItem = ({
  id,
  classes,
  className,
  children,
  style,
  onClick,
  fullWidth,
}: Props) => (
  <Grid
    id={id}
    className={clsx(classes.root, className, fullWidth && classes.fullWidth)}
    item
    xs={12}
    sm={8}
    md={6}
    lg={4}
    xl={4}
    style={style}
    onClick={onClick}
  >
    {children}
  </Grid>
)

DefaultGridItem.defaultProps = {
  className: '',
  id: '',
  style: undefined,
  onClick: undefined,
  fullWidth: false,
}

export default withWidth()(withStyles(styles)(DefaultGridItem))
