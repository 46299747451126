import React from 'react'

import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'

interface Props {
  isTotal: boolean
  valorSimulado: string
}

const SimuladorValue = ({ isTotal, valorSimulado }: Props) => (
  <div id="simulator-value" style={{ margin: '16px auto' }}>
    <Paragraph
      variant="inter"
      color={Colors.GRAY700}
      textAlign="center"
      fontWeight="400"
      fontSize="12px"
      lineHeight="15px"
      margin="0 0 15px 0"
    >
      {isTotal ? 'Valor aproximado da parcela/mês:' : 'Valor aproximado do empréstimo'}
    </Paragraph>
    <Paragraph
      margin="0px"
      variant="sora"
      color={Colors.GRAY500}
      textAlign="center"
      fontSize="28px"
      lineHeight="29px"
      style={{ paddingLeft: '10px' }}
    >
      R$ {valorSimulado}
    </Paragraph>
  </div>
)

export default SimuladorValue
