import { BaseServices, HttpMethod } from '@services/base'

export default (data: DataLakeContatoRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_DATA_LAKE_CONTATO
    : process.env.REACT_APP_DATA_LAKE_CONTATO_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  return BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
}

export interface DataLakeContatoRequest {
  nome: string
  cpf: string
  dataNascimento: string
  telefone: string
  email: string
  gerouLead: boolean
}
