import React, { memo, ReactNode } from 'react'

import { CustomInput } from './styles'

interface OwnProps {
  id: string
  label?: string
  placeholder?: string
  infoButton?: JSX.Element
  matricula: string | number | undefined
  matriculaError?: boolean
  onChange: (ev: string) => void
  onBlur?: () => void
  maxLength?: number
  disabled?: boolean
  invalidText?: string
  marginRight?: boolean
  iconRight?: JSX.Element | ReactNode
}
const MatriculaInputNovo = ({
  id,
  label,
  placeholder,
  infoButton,
  matricula,
  matriculaError = false,
  invalidText,
  onChange,
  onBlur,
  maxLength,
  disabled,
  marginRight,
  iconRight,
}: OwnProps) => (
  <CustomInput
    id={id}
    dataTestId={id}
    label={label}
    placeholder={placeholder}
    value={matricula}
    error={matriculaError}
    infoText={matriculaError ? invalidText : ''}
    onChange={(e) => onChange((e.target as HTMLInputElement).value)}
    onBlur={onBlur}
    helper={infoButton}
    maxLength={maxLength}
    disabled={disabled}
    marginRightDisabled={marginRight}
    iconRight={iconRight}
  />
)

MatriculaInputNovo.defaultProps = {
  label: 'Qual é sua matrícula?',
  placeholder: 'Informe os números',
  matriculaError: false,
  maxLength: undefined,
  onBlur: undefined,
  disabled: false,
  invalidText: 'Matrícula não encontrada',
}

export default memo(MatriculaInputNovo)
