import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet/types'
import { buildOrgaosNovo } from '@utils/simuladorUtils'
import { ApplicationState } from '@store/types'
import Select from '@atoms/SelectNovo'
import { Orgao } from '@store/globalApi/types'

import { CustomBottomSheet } from './styles'

interface OwnProps {
  id: string
  orgaos: Orgao[]
  orgaoSelecionado: Option
  orgaosLoading: boolean
  onChange: (value: Option) => void
  disabled?: boolean
  convenioSelecionado: string | number
}

const OrgaoDropdownNovo = ({
  id,
  orgaos,
  orgaoSelecionado,
  orgaosLoading,
  onChange,
  convenioSelecionado,
  disabled,
}: OwnProps) => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  if (!isApp) {
    return (
      <Select
        label="Em qual órgão você trabalha?"
        margin="0 0 20px 0"
        name="dropdown"
        value={orgaoSelecionado}
        hideSearch={false}
        options={buildOrgaosNovo(orgaos)}
        placeholder="Selecione o órgão"
        onChange={(value) => {
          onChange(value)
        }}
        loading={orgaosLoading}
        disabled={!convenioSelecionado || orgaosLoading || disabled}
      />
    )
  }
  return (
    <CustomBottomSheet
      id={id}
      label="Em qual órgão você trabalha?"
      name="dropdown"
      isUsingFilter
      value={orgaoSelecionado}
      onChange={(value) => {
        onChange(value)
      }}
      options={buildOrgaosNovo(orgaos)}
      placeholder="Selecione o órgão"
      isLoading={orgaosLoading}
      disabled={!convenioSelecionado || orgaosLoading || disabled}
    />
  )
}
export default memo(OrgaoDropdownNovo)
