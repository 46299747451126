import { Reducer } from 'redux'

import { getMaxValue, ParcelasTypes, FamiliaType } from '@utils/simuladorUtils'

import { SimuladorState, SimuladorTypes } from './types'

const INITIAL_STATE: SimuladorState = {
  valorTotal: 2000,
  parcelas: 96,
  isTotal: true,
  valorSimulado: '0',
  familia: 'publico',
  novoValorTotal: 0,
}
interface Props {
  valorTotal: number
  parcelas: ParcelasTypes
  familia: FamiliaType
  isTotal: boolean
}

const handleValorTotal = ({ valorTotal, isTotal, familia }: Props) => {
  const maxValue = getMaxValue({ isTotal, familia })
  return valorTotal > maxValue ? maxValue : valorTotal
}

const reducer: Reducer<SimuladorState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SimuladorTypes.SET_VALOR_TOTAL:
      return { ...state, valorTotal: action.payload }
    case SimuladorTypes.SET_PARCELAS:
      return { ...state, parcelas: action.payload }
    case SimuladorTypes.SET_IS_TOTAL:
      return {
        ...state,
        isTotal: action.payload.isTotal,
        valorTotal: handleValorTotal({
          valorTotal: state.valorTotal,
          isTotal: action.payload.isTotal,
          familia: action.payload.familia,
          parcelas: action.payload.parcelas,
        }),
      }
    case SimuladorTypes.SET_VALOR_SIMULADO:
      return { ...state, valorSimulado: action.payload }
    case SimuladorTypes.SET_NOVO_VALOR_TOTAL:
      return { ...state, novoValorTotal: action.payload }
    case SimuladorTypes.SET_FAMILIA_SIMULACAO:
      return { ...state, familia: action.payload }
    case SimuladorTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
