import React from 'react'

import { Colors } from '@interco/cp-react-ui-lib'
import { CircularProgress as MuiCircularProgress, withStyles } from '@material-ui/core'

import { Props } from './types'
import styles from './styles'

const CircularProgress = ({ classes, size, thickness, color, style }: Props) => (
  <div className={classes.root} style={{ ...style }}>
    <MuiCircularProgress
      variant="determinate"
      size={size}
      style={{
        color,
        opacity: 0.2,
      }}
      thickness={thickness}
      value={100}
    />
    <MuiCircularProgress
      variant="indeterminate"
      disableShrink
      className={classes.top}
      classes={{
        circle: classes.circle,
      }}
      style={{ color }}
      size={size}
      thickness={thickness}
    />
  </div>
)

CircularProgress.defaultProps = {
  size: 25,
  thickness: 4,
  color: Colors.ORANGE500,
  style: {},
}

export default withStyles(styles)(CircularProgress)
