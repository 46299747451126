import React, { ButtonHTMLAttributes } from 'react'

import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'

interface OwnProps {
  text?: string
  fontWeight?: string
  margin?: string
  fontSize?: string
  lineHeight?: string
  textAlign?: string
}

type Props = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>

const InputHelperButton = ({
  text,
  fontWeight,
  margin,
  fontSize,
  lineHeight,
  textAlign,
  onClick,
}: Props) => (
  <button type="button" onClick={onClick}>
    <Paragraph
      color={Colors.PRIMARY500}
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      margin={margin}
      textAlign={textAlign}
    >
      {text}
    </Paragraph>
  </button>
)

InputHelperButton.defaultProps = {
  text: 'Saiba mais',
  fontWeight: '400',
  margin: '0',
  lineHeight: '14px',
  fontSize: '12px',
}

export default InputHelperButton
