import React from 'react'

import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { InterUIContainer } from '@interco/inter-ui-react-lib'
import { withStyles, Grid, GridJustification } from '@material-ui/core'
import { DefaultGridItem } from '@atoms'
import { ApplicationState } from '@store/types'
import { useScrollToTop } from '@hooks'
import ProgressBarSteps from '@components/organims/ProgressBarSteps'

import styles from './styles'
import { Props } from './types'

const Page: React.FC<Props> = ({
  classes,
  className,
  children,
  id,
  justify = 'flex-start',
  stickyFooter,
  dataTestId,
}: Props) => {
  useScrollToTop()

  const { isApp, hideHeader } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { visible: hasProgressStep } = useSelector((state: ApplicationState) => state.ui.steps)

  const showHeader = () => !isApp && !hideHeader

  const handleClassess = () => {
    if (showHeader()) {
      return hasProgressStep ? classes.pageWithHeaderAndProgessStep : classes.pageWithHeader
    }
    return hasProgressStep ? classes.pageWithProgessStep : undefined
  }

  return (
    <section id={`section-${id}`} data-testid={dataTestId}>
      <ProgressBarSteps />
      <InterUIContainer
        fullPage={isApp}
        style={{ height: 'auto' }}
        stickyFooter={
          stickyFooter && (
            <Grid
              container
              direction="column"
              justifyContent={justify}
              id={`container-${id}`}
              alignItems="center"
            >
              <DefaultGridItem>{stickyFooter}</DefaultGridItem>
            </Grid>
          )
        }
        className={clsx(className, classes.pageContainer, handleClassess())}
      >
        <Grid
          container
          direction="column"
          justifyContent={justify}
          id={`container-${id}`}
          alignItems="center"
        >
          <DefaultGridItem>{children}</DefaultGridItem>
        </Grid>
      </InterUIContainer>
    </section>
  )
}

Page.defaultProps = {
  className: '',
  justify: 'flex-start' as GridJustification,
  stickyFooter: undefined,
}

export default withStyles(styles)(Page)
