import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import { postVerificaPendenciaLead } from './simulacao/sagas'
import postVerificaPendenciaPortabilidadeSaga from './simulacao/sagas/postVerificaPendenciaPortabilidade'
import { SimulacaoTypes } from './simulacao/types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(SimulacaoTypes.VERIFICA_PENDENCIA_LEAD_REQUEST, postVerificaPendenciaLead)
  yield* takeLatest(
    SimulacaoTypes.VERIFICA_PENDENCIA_PORTABILIDADE_REQUEST,
    postVerificaPendenciaPortabilidadeSaga,
  )
}
