import { BaseServices, HttpMethod } from '@services/base'

export default (data: SalvarLeadRequest, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? process.env.REACT_APP_ENVIA_LEAD_NOVO
    : process.env.REACT_APP_ENVIA_LEAD_NOVO_BFF
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request({
    pathname,
    method,
    host,
    data,
  })
  return response
}

/**
 * API Types
 */
export interface SalvarLeadRequest {
  tipo: string
  origem: string
  cpf: string
  email: string
  dataNascimento: string
  convenio: string
  convenioId: string
  autarquiaEmpresa: string
  nomeEmpresa: string
  descricao: string
  campanha: string
  celular: string
  matricula: string
  margem: number
  salario: number
  clienteId: string
  ultimoNome: string
  primeiroNome: string
  valorEmprestimo: number
  qtdParcelas: number
  valorIOF: number
  taxa: number
  valorCET: number
  valorCartaoConsignado: number
  creditoProtegido: boolean
  cartaoConsignado: boolean
  gClid: string
  gClientId: string
  cidadeConvenio: string
  ufConvenio: string
  situacaoFuncional?: string | number
  motivoSituacaoFuncional: string
  tipoContratacao: string
  idFacebook: string
  etapaContratacaoDigital: string
  upag: string
  utmCampaign: string
  utmContent: string
  utmMedium: string
  utmSource: string
  dataIngressoExercito: string
  indicativo: string
  patente: string
  codigoIndiqueGanhe: CodigoMgmTypes
  codigoIndicacao: string
  pendencia?: string
  observacao?: string
  convenioOutros?: string
  valorSeguro: number
  margemConsultada: boolean
  fluxo: string
}

export type CodigoMgmTypes = 'VALIDO' | 'INVALIDO' | 'VAZIO'
