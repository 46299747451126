export enum FontesPagamento {
  DEFAULT = 0,
  ESTADUAL = 1,
  MUNICIPAL = 2,
  INSS = 3,
  FEDERAL = 4,
  FORCAS_ARMADAS = 7,
  TRIBUNAIS = 8,
  CLT = 5,
  AUTONOMO = 9,
  SIAPE = 10,
  SANGUE_LARANJA = 12,
}

export const SituacoesEstadualMunicipal = [
  { value: 'Servidor efetivo', label: 'Servidor efetivo' },
  { value: 'Aposentado(a)', label: 'Aposentado(a)' },
  { value: 'Militar efetivo', label: 'Militar efetivo' },
  { value: 'Pensionista', label: 'Pensionista' },
]

export const SituacoesForcasArmadas = [
  { value: 1, label: 'Ativo(a)' },
  { value: 2, label: 'Inativo(a)' },
  { value: 6, label: 'Estabilizado(a)' },
]

export const SituacoesFederalSiape = [
  { value: 'Civil ativo efetivo', label: 'Civil ativo efetivo' },
  {
    value: 'Servidores militares ativos efetivos',
    label: 'Servidores militares ativos efetivos',
  },
  { value: 'Inativo(a)', label: 'Inativo(a)' },
  { value: 'Aposentado(a)', label: 'Aposentado(a)' },
  { value: 'Pensionista vitalício', label: 'Pensionista vitalício' },
  { value: 'Pensão graciosa', label: 'Pensão graciosa' },
  { value: 'Comissionado(a)', label: 'Comissionado(a)' },
  { value: 'Contratado(a)', label: 'Contratado(a)' },
  { value: 'Temporário(a)', label: 'Temporário(a)' },
  { value: 'Analfabeto', label: 'Analfabeto' },
  {
    value: 'Pensionista curatelado(a)/tutelado(a)',
    label: 'Pensionista curatelado(a)/tutelado(a)',
  },
]

export const SituacoesDefault = [
  { value: 'Ativo(a)', label: 'Ativo(a)' },
  { value: 'Inativo(a)', label: 'Inativo(a)' },
]

export const SituacoesInss = [
  { value: 'Aposentado(a)', label: 'Aposentado(a)' },
  { value: 'Pensionista', label: 'Pensionista' },
]

export const MotivosInss = {
  aposentado: [
    { value: 'Idade', label: 'Idade' },
    { value: 'Tempo de contribuição', label: 'Tempo de contribuição' },
    {
      value: 'Encargos Previdenciários da União',
      label: 'Encargos Previdenciários da União',
    },
    { value: 'Invalidez', label: 'Invalidez' },
  ],
  pensionista: [{ value: 'Por morte', label: 'Por morte' }],
}

export enum ErrorCodes {
  // Portabilidade
  P0_001_BANCOS = 'PO.001', // src/store/globalApi/sagas/getBancos.ts
  P0_002_FONTES = 'PO.002', // src/store/globalApi/sagas/getFontes.ts
  P0_003_ESTADOS = 'PO.003', // src/store/globalApi/sagas/getEstados.ts
  P0_004_CIDADES = 'PO.004', // src/store/globalApi/sagas/getCidades.ts
  P0_005_CONVENIOS = 'PO.005', // src/store/portabilidade/fontePagamento/sagas/getConvenio.ts
  P0_006_ORGAOS = 'PO.006', // src/store/portabilidade/fontePagamento/sagas/getOrgaos.ts
  P0_007_UPAGS = 'PO.007', // src/store/globalApi/sagas/getUpags.ts
  P0_008_SIMULACAO = 'PO.008', // src/store/portabilidade/simulacao/sagas/postSimulacao.ts
  P0_009_LEAD = 'PO.009', // src/store/portabilidade/simulacao/sagas/postSalvarLead.ts
  P0_010_VERIFICA_PENDENCIA_LEAD = 'PO.010', // src/store/portabilidade/simulacao/sagas/postVerificaPendenciaLead.ts
  P0_011_CONTRATOS_ATIVOS = 'PO.011', // src/store/globalApi/sagas/getContratosAtivos

  // Refinanciamento
  RE_001_CONTRATOS = 'RE.001', // src/store/refinanciamento/simulacao/sagas/getContratos.ts
  RE_002_SIMULACAO = 'RE.002', // src/store/refinanciamento/simulacao/sagas/postSimulacao.ts
  RE_003_SALVAR_LEAD = 'RE.003', // src/store/refinanciamento/simulacao/sagas/postSalvarLead.ts

  // Novo
  N0_001_CONVENIOS = 'NO.001', // src/store/novo/fontePagamento/sagas/getConvenio.ts
  N0_002_ORGAOS = 'NO.002', // src/store/novo/fontePagamento/sagas/getOrgaos.ts
  N0_003_SIMULACAO = 'NO.003', // src/store/novo/simulacao/sagas/postSimulacao.ts
  N0_004_MATRICULA = 'NO.004', // src/store/novo/simulacao/sagas/postValidarMatriculaSaga.ts
  N0_005_VALIDAR_CPF = 'NO.005', // src/store/novo/validaCPF/index.ts
  N0_006_SALVAR_LEAD = 'NO.006', // src/store/novo/simulacao/sagas/postSalvarLead.ts
  N0_007_VALIDAR_EMPRESA = 'NO.007', // src/store/novo/dadosProfissionais/sagas/getValidaEmpresaSaga.ts
  NO_008_BUSCA_DADOS_INSS = 'NO.008', // src/store/novo/situacaoFuncional/sagas/getDadosBeneficiarioInssSaga.ts
  N0_009_DATA_LAKE_CONTATO = 'NO.009', // src/store/novo/codIndicacaoContato/sagas/postDataLakeContatoSaga.ts
  NO_009_CONSULTAR_MARGEM_SIAPE = 'NO.009', // src/store/novo/siapeAutorizarAnuencia/sagas/postConsultarMargemSiapeSaga.ts
  NO_010_CONSULTAR_MARGEM_CIP = 'NO.010', // src/store/novo/situacaoFuncional/sagas/postConsultaMargemCipSaga.ts
  NO_0010_CONSULTA_MARGEM_EXERCITO = 'NO.0010', // src/store/novo/situacaoFuncional/sagas/postValidaSenhaExercito.ts
  NO_0011_POST_INDICACAO_WHATSAPP = 'NO.0011', // src/store/novo/atendimento/sagas/postIndicacaoWhatsapp.ts
}

export enum ConveniosCodes {
  ESTADO_MG = 1,
  TJMG = 2,
  SIAPE_SERVIDOR_FEDERAL = 3,
  ESTADO_RJ = 4,
  ESTADO_SP = 10,
  ESTADO_BAHIA = 12,
  ESTADO_MS = 14,
  ESTADO_GO = 20,
  INSS = 6,
  MIN_PUB_MG = 43,
  TRE_MG = 45,
  METRO = 46,
  TRE_SP = 55,
  STJ = 56,
  TCE_MG = 62,
  TRT = 75,
  ESTADO_MT = 105,
  MARINHA_BR = 13,
  FORCA_AEREA_BR = 23,
  EXERCITO_BR = 37,
  PREF_BH = 5,
  PREF_SALVADOR = 16,
  PREF_SP = 17,
  PREF_CONTAGEM = 34,
  PREF_CAMPO_GRANDE = 132,
  PREF_BETIM = 129,
  PREF_VITORIA = 117,
  PREF_GOIANIA = 130,
  HOSPITAL_ODILON = 42,
  OUTROS = 999,
}

export const TiposPatentes = [
  { value: 'Soldado EV', label: 'Soldado EV' },
  { value: 'Soldado EP', label: 'Soldado EP' },
  { value: 'Cabo', label: 'Cabo' },
  { value: '3º Sargento', label: '3º Sargento' },
  { value: '2º Sargento', label: '2º Sargento' },
  { value: '1º Sargento', label: '1º Sargento' },
  { value: 'Subtenente', label: 'Subtenente' },
  { value: 'Aspirante a Oficial', label: 'Aspirante a Oficial' },
  { value: '2º Tenente', label: '2º Tenente' },
  { value: '1º Tenente', label: '1º Tenente' },
  { value: 'Capitão', label: 'Capitão' },
  { value: 'Major', label: 'Major' },
  { value: 'Tenente-Coronel', label: 'Tenente-Coronel' },
  { value: 'Coronel', label: 'Coronel' },
  { value: 'General de Brigada', label: 'General de Brigada' },
  { value: 'General de Divisão', label: 'General de Divisão' },
  { value: 'General de Exército', label: 'General de Exército' },
]

export const TiposIndicativos = {
  ativo: [
    {
      value: 1,
      label: 'Militar da ativa de carreira',
    },
    {
      value: 2,
      label: 'Militar da ativa temporário',
    },
    { value: 3, label: 'Militar reintegrado' },
  ],
  inativo: [
    { value: 1, label: 'Militar na inatividade' },
    { value: 2, label: 'Militar reformado' },
    {
      value: 3,
      label: 'Militar reformado por decisão judicial',
    },
    {
      value: 4,
      label: 'Militar reformado por idade limite',
    },
    {
      value: 8,
      label: 'Militar anistiado político',
    },
  ],
  estabilizado: [
    { value: 1, label: 'Pensão julgada pelo TCU' },
    {
      value: 2,
      label: 'Pensão remetida e não julgada pelo TCU 3 - pensão não remetida ao TCU',
    },
    {
      value: 4,
      label: 'Pensão julgada ilegal pelo TCU',
    },
    { value: 5, label: 'Pensão do STM' },
    {
      value: 6,
      label: 'Pensão militar civil nível intermediário',
    },
    {
      value: 7,
      label: 'Pensão militar civil nível superior',
    },
    { value: 8, label: 'Pensão anistiado político' },
  ],
}

export enum TipoEmprestimo {
  CREDITO_PROTEGIDO = 'simulacaoComSeguro',
  SEM_CREDITO_PROTEGIDO = 'simulacao',
  CREDITO_PROTEGIDO_ARRAY = 'simulacoesComSeguro',
  SEM_CREDITO_PROTEGIDO_ARRAY = 'simulacoes',
}

export enum StepNRNovo {
  LANDING_PAGE = 'landing-page',
  DOCUMENTACAO_AUTORIZADA = 'documentacao-autorizada',
  LEITURA_DOCUMENTACAO_AUTORIZACAO = 'leitura-documentacao-autorizacao',
  DADOS_MATRICULA = 'dados-matricula',
  DADOS_BENEFICIO_ERRO = 'dados-beneficio-erro',
  ENVIO_TOKEN_SMS = 'envio-token-sms',
  ENVIO_TOKEN_EMAIL = 'envio-token-email',
  VALIDA_TOKEN_SMS = 'valida-token-sms',
  VALIDA_TOKEN_EMAIL = 'valida-token-email',
  TOKEN_VALIDADO_SMS = 'token-validado-sms',
  TOKEN_VALIDADO_EMAIL = 'token-validado-email',
  VAMOS_COMECAR = 'onboarding-novo',
  VAMOS_COMECAR_INSS = 'onboarding-novo-inss',
  SELECAO_CONVENIO = 'selecao-convenio',
  DADOS_PROFISSIONAIS = 'dados-profissionais-novo',
  SITUACAO_FUNCIONAL = 'situacao-funcional-novo',
  DADOS_PESSOAIS = 'dados-pessoais-novo',
  TEM_INTERESSE = 'tem-interesse-novo',
  SIMULACAO_SOLICITADA = 'simulacao-solicitada-novo',
  SIMULACAO_CALCULADA = 'simulacao-calculada-novo',
  SIMULACAO_SELECIONADA = 'simulacao-selecionada-novo',
  TOKEN_VALIDADO = 'token-validado-novo',
  REENVIAR_TOKEN = 'reenviar-token-novo',
  VALIDAR_TOKEN = 'validar_token',
  FONTE_PAGAMENTO = 'fonte-pagamento-novo',
  INDICACAO_CONTATO = 'indicacao-contato-novo',
  SELECAO_SEGURO = ' selecao-seguro-novo',
  RESUMO_SIMULACAO = 'resumo-simulacao-novo',
  CLIENTE_TOKEN = 'cliente-token-novo',
  ENVIO_TOKEN = 'envio-token-novo',
  DADOS_RECEBIDOS = 'dados-recebidos',
  EMPRESA_NAO_ENCONTRADA = 'empresa-nao-encontrada-novo',
  CONTRATAR_SIMULACAO_NOVO = 'contratar-simulacao-novo',
  NAO_LIBERADO = 'nao-liberado',
  OFERTA_PORTABILIDADE = 'oferta-portabilidade',
  RESULTADO_SIMULACAO = 'resultado-simulacao',
}

export const TempoEmpresa = [
  { value: 'Menos de 1 ano', text: 'Menos de 1 ano' },
  { value: '1 ano', text: '1 ano' },
  { value: '2 anos', text: '2 anos' },
  { value: '3 anos', text: '3 anos' },
  { value: 'Mais de 3 anos', text: 'Mais de 3 anos' },
]

export enum PaginaEnum {
  PAGINA_UM = 1,
  PAGINA_DOIS = 2,
  PAGINA_TRES = 3,
  PAGINA_QUATRO = 4,
  PAGINA_CINCO = 5,
  PAGINA_SEIS = 6,
  PAGINA_SETE = 7,
}

export const SalarioMinimo = 1100

export const SalarioSimulacao = 937

export const MultiplicadorSalarioMinimo = {
  '2salarios': 2 * SalarioMinimo,
  '4salarios': 4 * SalarioMinimo,
  '10salarios': 10 * SalarioMinimo,
  '20salarios': 20 * SalarioMinimo,
  acima20salarios: 20 * SalarioMinimo + 1,
}

export enum TipoConsultaAutorizacaoSiape {
  CONSULTA_AUTORIZADA = 'consultaAutorizada',
  CONSULTA_NAO_AUTORIZADA = 'consultaNaoAutorizada',
}

export enum SiapeAutorizacaoAnuenciaEnum {
  OPCAO_AUTORIZAR_CONSULTA = 'OpcoesAutorizarConsulta',
  AUTORIZACAO_SOU_GOV = 'AutorizacaoSouGov',
  AUTORIZACAO_NAO_IDENTIFICADA = 'AutorizacaoNaoIdentificada',
}

export enum CodigoValidacao {
  VALIDO = 'VALIDO',
  INVALIDO = 'INVALIDO',
  VAZIO = 'VAZIO',
}

export enum PageMargemCipEnum {
  CONSULTA_MARGEM = 'ConsultaMargem',
  CONSULTA_MARGEM_NAO_AUTORIZADA = 'ConsultaMargemNaoAutorizada',
}

export enum PendenciaEnum {
  LEAD_PORTABILIDADE_SEM_PENDENCIA = 'LEAD_PORTABILIDADE_SEM_PENDENCIA',
  LEAD_NOVO_SEM_PENDENCIA_OBSERVACAO = 'LEAD_NOVO_SEM_PENDENCIA_OBSERVACAO',
  LEAD_NOVO_PENDENCIA_REVERSIVEL = 'LEAD_NOVO_PENDENCIA_REVERSIVEL',
  SEM_LEAD_COM_CONTRATACAO_DIGITAL = 'SEM_LEAD_COM_CONTRATACAO_DIGITAL',
  PENDENCIA_REVERSIVEL_LEAD_NOVO = 'PENDENCIA_REVERSIVEL_LEAD_NOVO',
  PENDENCIA_REVERSIVEL_LEAD_PORTABILIDADE = 'PENDENCIA_REVERSIVEL_LEAD_PORTABILIDADE',
  SEM_PENDENCIA_LEAD_PORTABILIDADE = 'SEM_PENDENCIA_LEAD_PORTABILIDADE',
  PENDENCIA_IRREVERSIVEL = 'PENDENCIA_IRREVERSIVEL',
  SEM_PENDENCIA_LEAD_NOVO = 'SEM_PENDENCIA_LEAD_NOVO',
}

export enum PendenciaEnumV3 {
  SEM_PENDENCIA_LEAD_PORTABILIDADE = 'SEM_PENDENCIA_LEAD_PORTABILIDADE',
  PENDENCIA_IRREVERSIVEL = 'PENDENCIA_IRREVERSIVEL',
  SEM_PENDENCIA_LEAD_NOVO = 'SEM_PENDENCIA_LEAD_NOVO',
  PENDENCIA_TEIMOSINHA = 'PENDENCIA_TEIMOSINHA',
  PENDENCIA_VALIDACAO = 'PENDENCIA_VALIDACAO',
}

export enum ObservacaoLead {
  INDISPONIVEL = 'Serviço indisponível',
}

export enum SituacoesFuncionaisSiape {
  COMISSIONADO = 'Comissionado(a)',
  TEMPORARIO = 'Temporário(a)',
  CURATELADO_TUTELADO = 'Pensionista curatelado(a)/tutelado(a)',
  CONTRATADO = 'Contratado(a)',
  ANALFABETO = 'Analfabeto',
}

// ENUMS SIMULADOR NOVO

export const ForcasArmadasConvenios = [
  { value: ConveniosCodes.EXERCITO_BR, label: 'Exército do Brasil' },
  { value: ConveniosCodes.FORCA_AEREA_BR, label: 'Força Aérea do Brasil' },
  { value: ConveniosCodes.MARINHA_BR, label: 'Marinha do Brasil' },
]

export const MotivosInssNovo = {
  aposentado_pensionista: [
    { value: 'Idade', text: 'Idade' },
    { value: 'Tempo de contribuição', text: 'Tempo de contribuição' },
    {
      value: 'Encargos Previdenciários da União',
      text: 'Encargos Previdenciários da União',
    },
    { value: 'Invalidez', text: 'Invalidez' },
    { value: 'Por morte', text: 'Por morte' },
  ],
}

export const SituacoesInssNovo = [
  { value: 'Aposentado(a)', label: 'Aposentado ou Pensionista INSS' },
  { value: 'Cartão Benefício', label: 'Cartão Benefício' },
  { value: 'Auxílio Brasil', label: 'Auxílio Brasil' },
]

export const SituacoesFederalSiapeNovo = [
  { value: 'Civil ativo efetivo', text: 'Civil ativo efetivo' },
  {
    value: 'Servidores militares ativos efetivos',
    text: 'Servidores militares ativos efetivos',
  },
  { value: 'Inativo(a)', text: 'Inativo(a)' },
  { value: 'Aposentado(a)', text: 'Aposentado(a)' },
  { value: 'Pensionista vitalício', text: 'Pensionista vitalício' },
  { value: 'Pensão graciosa', text: 'Pensão graciosa' },
  { value: 'Comissionado(a)', text: 'Comissionado(a)' },
  { value: 'Contratado(a)', text: 'Contratado(a)' },
  { value: 'Temporário(a)', text: 'Temporário(a)' },
  { value: 'Analfabeto', text: 'Analfabeto' },
  {
    value: 'Pensionista curatelado(a)/tutelado(a)',
    text: 'Pensionista curatelado(a)/tutelado(a)',
  },
]

export const SituacoesEstadualMunicipalNovo = [
  { value: 'Servidor efetivo', text: 'Servidor efetivo' },
  { value: 'Aposentado(a)', text: 'Aposentado(a)' },
  { value: 'Militar efetivo', text: 'Militar efetivo' },
  { value: 'Pensionista', text: 'Pensionista' },
]

export const SituacoesForcasArmadasNovo = [
  { value: 1, text: 'Ativo(a)' },
  { value: 2, text: 'Inativo(a)' },
  { value: 6, text: 'Estabilizado(a)' },
]

export const TiposIndicativosNovo = {
  ativo: [
    {
      value: 1,
      text: 'Militar da ativa de carreira',
    },
    {
      value: 2,
      text: 'Militar da ativa temporário',
    },
    { value: 3, text: 'Militar reintegrado' },
  ],
  inativo: [
    { value: 1, text: 'Militar na inatividade' },
    { value: 2, text: 'Militar reformado' },
    {
      value: 3,
      text: 'Militar reformado por decisão judicial',
    },
    {
      value: 4,
      text: 'Militar reformado por idade limite',
    },
    {
      value: 8,
      text: 'Militar anistiado político',
    },
  ],
  estabilizado: [
    { value: 1, text: 'Pensão julgada pelo TCU' },
    {
      value: 2,
      text: 'Pensão remetida e não julgada pelo TCU 3 - pensão não remetida ao TCU',
    },
    {
      value: 4,
      text: 'Pensão julgada ilegal pelo TCU',
    },
    { value: 5, text: 'Pensão do STM' },
    {
      value: 6,
      text: 'Pensão militar civil nível intermediário',
    },
    {
      value: 7,
      text: 'Pensão militar civil nível superior',
    },
    { value: 8, text: 'Pensão anistiado político' },
  ],
}

export type SituacaoFuncionalTypesIndicativo = 1 | 2 | 6
export const indicativoOptionsNovo = (situacaoFuncional: SituacaoFuncionalTypesIndicativo) => {
  const tipos = {
    1: TiposIndicativosNovo.ativo,
    2: TiposIndicativosNovo.inativo,
    6: TiposIndicativosNovo.estabilizado,
  }
  return tipos[situacaoFuncional] || []
}

export const TiposPatentesNovo = [
  { value: 'Soldado EV', text: 'Soldado EV' },
  { value: 'Soldado EP', text: 'Soldado EP' },
  { value: 'Cabo', text: 'Cabo' },
  { value: '3º Sargento', text: '3º Sargento' },
  { value: '2º Sargento', text: '2º Sargento' },
  { value: '1º Sargento', text: '1º Sargento' },
  { value: 'Subtenente', text: 'Subtenente' },
  { value: 'Aspirante a Oficial', text: 'Aspirante a Oficial' },
  { value: '2º Tenente', text: '2º Tenente' },
  { value: '1º Tenente', text: '1º Tenente' },
  { value: 'Capitão', text: 'Capitão' },
  { value: 'Major', text: 'Major' },
  { value: 'Tenente-Coronel', text: 'Tenente-Coronel' },
  { value: 'Coronel', text: 'Coronel' },
  { value: 'General de Brigada', text: 'General de Brigada' },
  { value: 'General de Divisão', text: 'General de Divisão' },
  { value: 'General de Exército', text: 'General de Exército' },
]

export enum TipoContato {
  LIGACAO = '1',
  WHATSAPP = '2',
}

export enum HorarioContato {
  MANHA = '1',
  TARDE = '2',
  NOITE = '3',
  QUALQUER = '4',
}
export enum CampanhaEnum {
  LP_INSS = 'LP_INSS',
  LP_CONSIG = 'LP_CONSIG',
  APP_INSS = 'APP_INSS',
  APP_CONSIG = 'APP_CONSIG',
}
