import { action, Action } from 'typesafe-actions'

import {
  GlobalApiTypes,
  FontePagamento,
  Proposta,
  Cidade,
  Estado,
  Upag,
  ContratosAtivos,
} from './types'

export const fontesRequest = (): Action => action(GlobalApiTypes.FONTES_REQUEST)
export const fontesSuccess = (payload: FontePagamento[] | null): Action =>
  action(GlobalApiTypes.FONTES_SUCCESS, payload)
export const fontesError = (): Action => action(GlobalApiTypes.FONTES_ERROR)
export const fontesLoading = (): Action => action(GlobalApiTypes.FONTES_LOADING)

export const estadosRequest = (): Action => action(GlobalApiTypes.ESTADOS_REQUEST)
export const estadosSuccess = (payload: Estado[] | null): Action =>
  action(GlobalApiTypes.ESTADOS_SUCCESS, payload)
export const estadosError = (): Action => action(GlobalApiTypes.ESTADOS_ERROR)
export const estadosLoading = (): Action => action(GlobalApiTypes.ESTADOS_LOADING)

export const cidadesRequest = (payload: string): Action =>
  action(GlobalApiTypes.CIDADES_REQUEST, payload)
export const cidadesSuccess = (payload: Cidade[] | null): Action =>
  action(GlobalApiTypes.CIDADES_SUCCESS, payload)
export const cidadesError = (): Action => action(GlobalApiTypes.CIDADES_ERROR)
export const cidadesLoading = (): Action => action(GlobalApiTypes.CIDADES_LOADING)

export const upagsRequest = (): Action => action(GlobalApiTypes.UPAGS_REQUESTS)
export const upagsSuccess = (payload: Upag[] | null): Action =>
  action(GlobalApiTypes.UPAGS_SUCCESS, payload)
export const upgasError = (): Action => action(GlobalApiTypes.UPAGS_ERROR)
export const upagsLoading = (): Action => action(GlobalApiTypes.UPAGS_LOADING)

export const propostasRequest = (): Action => action(GlobalApiTypes.PROPOSTAS_REQUEST)
export const propostasSuccess = (payload: Proposta[] | null): Action =>
  action(GlobalApiTypes.PROPOSTAS_SUCCESS, payload)
export const propostasError = (): Action => action(GlobalApiTypes.PROPOSTAS_ERROR)
export const propostasLoading = (): Action => action(GlobalApiTypes.PROPOSTAS_LOADING)

export const contratosAtivosRequest = (): Action => action(GlobalApiTypes.CONTRATOS_ATIVOS_REQUEST)
export const contratosAtivosSuccess = (payload: ContratosAtivos[] | null): Action =>
  action(GlobalApiTypes.CONTRATOS_ATIVOS_SUCCESS, payload)
export const contratosAtivosError = (): Action => action(GlobalApiTypes.CONTRATOS_ATIVOS_ERROR)
export const contratosAtivosLoading = (): Action => action(GlobalApiTypes.CONTRATOS_ATIVOS_LOADING)
