import { Reducer } from 'redux'

import { FontePagamentoState, FontePagamentoTypes } from './types'

const INITIAL_STATE: FontePagamentoState = {
  fonteSelecionada: undefined,
  convenios: [],
  convenioSelecionado: '',
  conveniosLoading: false,
  estadoSelecionado: '',
  cidadeSelecionada: '',
  loading: false,
  error: false,
  orgaoSelecionado: '',
  nomeConvenio: '',

  orgaos: [],
  orgaosError: false,
  orgaosLoading: false,

  inssViaUrl: false,
}

const reducer: Reducer<FontePagamentoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FontePagamentoTypes.CONVENIOS_REQUEST:
      return {
        ...state,
        convenioSelecionado: '',
        orgaos: [],
        orgaoSelecionado: '',
        conveniosLoading: true,
        error: false,
      }
    case FontePagamentoTypes.CONVENIOS_SUCCESS:
      return {
        ...state,
        conveniosLoading: false,
        error: false,
        convenios: action.payload,
      }
    case FontePagamentoTypes.CONVENIOS_ERROR:
      return { ...state, conveniosLoading: false, error: true }
    case FontePagamentoTypes.SET_FONTE_SELECIONADA:
      return { ...state, fonteSelecionada: action.payload }
    case FontePagamentoTypes.SET_CONVENIO_SELECIONADO:
      return { ...state, convenioSelecionado: action.payload }
    case FontePagamentoTypes.SET_ESTADO_SELECIONADO:
      return { ...state, estadoSelecionado: action.payload }
    case FontePagamentoTypes.SET_CIDADE_SELECIONADA:
      return { ...state, cidadeSelecionada: action.payload }
    case FontePagamentoTypes.SET_ORGAO_SELECIONADO:
      return { ...state, orgaoSelecionado: action.payload }

    case FontePagamentoTypes.SET_LOADING:
      return { ...state, error: false, loading: action.payload }
    case FontePagamentoTypes.SET_NOME_CONVENIO:
      return { ...state, nomeConvenio: action.payload }

    // Orgaos
    case FontePagamentoTypes.ORGAOS_SUCCESS:
      return {
        ...state,
        orgaosError: false,
        orgaosLoading: false,
        orgaos: action.payload,
      }
    case FontePagamentoTypes.ORGAOS_REQUEST:
      return { ...state, orgaosError: false, orgaosLoading: true }
    case FontePagamentoTypes.ORGAOS_ERROR:
      return { ...state, orgaosLoading: false, orgaosError: true }
    case FontePagamentoTypes.RESET:
      return { ...INITIAL_STATE }
    case FontePagamentoTypes.INSS_VIA_URL:
      return { ...state, inssViaUrl: action.payload }
    default:
      return state
  }
}

export default reducer
