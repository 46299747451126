import { ValidaCodigoApiRequest } from '@services/getValidaToken'
import { EnviaCodigoApiRequest } from '@services/postEnviaToken'
import { ApplicationState } from '@store/types'

// Build validação codigo
export const buildValidateToken: {
  [stateType: string]: (
    state: ApplicationState,
    codigo: string,
    origem: 'sms' | 'email' | undefined,
  ) => ValidaCodigoApiRequest
} = {
  novo: ({ novo }, codigo, origem) => ({
    id: novo.dadosPessoais.cpf,
    origem: origem === 'sms' ? 'SMS' : 'EMAIL',
    codigo,
  }),
}

// Build criação codigo
export const buildToken: {
  [stateType: string]: (
    state: ApplicationState,
    envio: 'sms' | 'email' | undefined,
  ) => EnviaCodigoApiRequest
} = {
  novo: ({ novo }, envio) => ({
    cpf: novo.dadosPessoais.cpf,
    nome: novo.dadosPessoais.nome,
    email: novo.codIndicacaoContato.email,
    celular: novo.codIndicacaoContato.telefone,
    envio: [envio === 'sms' ? 'SMS' : 'EMAIL'],
  }),
}
