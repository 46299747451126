import { action, Action } from 'typesafe-actions'

import { ResponseDadosUsuarioPropostaSangueLaranja } from '@services/getDadosUsuarioPropostaSangueLaranja'

import { DadosPessoaisTypes } from './types'

export const setNome = (payload: string): Action => action(DadosPessoaisTypes.SET_NOME, payload)
export const setCpf = (payload: string): Action => action(DadosPessoaisTypes.SET_CPF, payload)
export const setDataNascimento = (payload: string): Action =>
  action(DadosPessoaisTypes.SET_DATA_NASCIMENTO, payload)
export const setPerfil = (payload: string): Action => action(DadosPessoaisTypes.SET_PERFIL, payload)
export const setRenda = (payload: number): Action => action(DadosPessoaisTypes.SET_RENDA, payload)
export const setFlagTitularDados = (payload: boolean): Action =>
  action(DadosPessoaisTypes.SET_FLAG_TITULAR_DADOS, payload)
export const setConta = (payload: string): Action => action(DadosPessoaisTypes.SET_CONTA, payload)
export const getDadosUsuarioAction = (payload?: Callback): Action =>
  action(DadosPessoaisTypes.GET_DADOS_USUARIO, payload)
export const setSenhaServidor = (payload: string): Action =>
  action(DadosPessoaisTypes.SET_SENHA_SERVIDOR, payload)
export const setSangueLaranja = (payload: boolean): Action =>
  action(DadosPessoaisTypes.SET_SANGUE_LARANJA, payload)
export const getDadosUsuarioPropostaSangueLaranja = (payload: string): Action =>
  action(DadosPessoaisTypes.GET_DADOS_USUARIO_PROPOSTA_SANGUE_LARANJA, payload)
export const setDadosUsuarioPropostaSangueLaranja = (
  payload: ResponseDadosUsuarioPropostaSangueLaranja,
): Action => action(DadosPessoaisTypes.SET_DADOS_USUARIO_PROPOSTA_SANGUE_LARANJA, payload)
export const setNumeroContrato = (payload: string): Action =>
  action(DadosPessoaisTypes.SET_NUMERO_CONTRATO, payload)
export const setNomeSocial = (payload: string): Action =>
  action(DadosPessoaisTypes.SET_NOME_SOCIAL, payload)

export interface Callback {
  callback?: (dataNascimento: string) => void
}
