import { Reducer } from 'redux'

import { PendenciaState, PendenciaTypes } from './types'

const INITIAL_STATE: PendenciaState = {
  pendencia: '',
}

const reducer: Reducer<PendenciaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PendenciaTypes.SET_PENDENCIA:
      return { ...state, pendencia: action.payload }

    case PendenciaTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
