import { AnyAction } from 'redux'

/**
 * Actions Types
 */
export enum TokenTypes {
  TOKEN_ERROR = '@token/TOKEN_ERROR',
  TOKEN_SUCCESS = '@token/TOKEN_SUCCESS',
  SEND_TOKEN_REQUEST = '@token/SEND_TOKEN_REQUEST',
  VALIDATE_TOKEN_REQUEST = '@token/VALIDATE_TOKEN_REQUEST',
  RESET_TOKEN = '@token/RESET_TOKEN',

  RESET = 'RESET',
}

/**
 * State Type
 */
export interface TokenState {
  readonly error?: string
  readonly loading: boolean
  readonly titleError?: string
  readonly errorCallback?: () => AnyAction
  readonly stepError?: string
}

/**
 * Action Payload Types
 */
export interface SendTokenPayload {
  type: 'sms' | 'email' | undefined
  stateType: string
  recaptchaToken: string
}
export interface ValidateTokenRequestPayload {
  token: string
  success: () => void
  stateType: string
  type: 'sms' | 'email' | undefined
  recaptchaToken: string
}
export interface ErrorPayload {
  title: string
  mensagem: string
  callback?: () => AnyAction
  step: string
}
