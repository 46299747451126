import { AnyAction } from 'redux'
import { call, put, select } from 'typed-redux-saga'

import postDataLakeContato, { DataLakeContatoRequest } from '@services/postDataLakeContato'
import { ApplicationState } from '@store/types'

import { dataLakeContatoError, dataLakeContatoSuccess } from '../actions'
import { buildDataLakeContato } from './models'
import { DataLakeContatoRequestPayload } from '../types'

export default function* postDataLakeContatoSaga(action: AnyAction) {
  const payload = action.payload as DataLakeContatoRequestPayload

  try {
    const { novo } = yield* select((s: ApplicationState) => s)
    const { newSimulatorApi } = yield* select((state: ApplicationState) => state.ui.navigation)
    const body: DataLakeContatoRequest = buildDataLakeContato(novo)

    yield* call(postDataLakeContato, body, newSimulatorApi)
    yield put(dataLakeContatoSuccess())
    yield payload.callback()
  } catch (error) {
    yield put(dataLakeContatoError())
    yield payload.callback()
  }
}
