/**
 * Actions Types
 */

export enum SituacaoFuncionalTypes {
  SET_MATRICULA = '@novo/situacaoFuncional/SET_MATRICULA',
  SET_MATRICULA_VALIDA = '@novo/situacaoFuncional/SET_MATRICULA_VALIDA',
  VALIDAR_MATRICULA = '@novo/situacaoFuncional/VALIDAR_MATRICULA',
  SET_SALARIO = '@novo/situacaoFuncional/SET_SALARIO',
  SET_SITUACAO = '@novo/situacaoFuncional/SET_SITUACAO',
  SET_MOTIVO = '@novo/situacaoFuncional/SET_MOTIVO',
  SET_UPAG = '@novo/situacaoFuncional/SET_UPAG',
  UPAGS_REQUESTS = '@novo/situacaoFuncional/UPAGS_REQUESTS',
  SET_PATENTE = '@novo/situacaoFuncional/SET_PATENTE',
  SET_INDICATIVO = '@novo/situacaoFuncional/SET_INDICATIVO',
  SET_DATA_INGRESSO = '@novo/situacaoFuncional/SET_DATA_INGRESSO',
  CLEAR_SITUACAOFUNCIONAL = '@novo/situacaoFuncional/CLEAR_SITUACAOFUNCIONAL',
  CLEAR_ERROR_MATRICULA = '@novo/situacaoFuncional/CLEAR_ERROR_MATRICULA',

  RESET = 'RESET',
}
/**
 * State Type
 */

export interface SituacaoFuncionalState {
  readonly matricula?: string | number
  readonly salario: number
  readonly situacaoFuncional?: string | number
  readonly motivo?: string
  readonly upag?: string | number
  readonly patente?: string
  readonly indicativo?: string | number
  readonly dataIngresso: string
  readonly matriculaValida: boolean
  readonly loading: boolean
}

export type ValidarMatriculaPayload = {
  callback: (isValido: boolean | undefined) => void
}
