import { action, Action } from 'typesafe-actions'

import { ConsultaMargemGeralPayload, ConsultaMargemGeralTypes } from './types'

export const setCheckConsultaMargem = (payload: boolean): Action =>
  action(ConsultaMargemGeralTypes.SET_CHECK_CONSULTA_MARGEM_GERAL, payload)

export const consultaMargemGeralRequest = (payload: ConsultaMargemGeralPayload): Action =>
  action(ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_REQUEST, payload)

export const consultaMargemGeralSuccess = (): Action =>
  action(ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_SUCCESS)

export const consultaMargemGeralSuccessCallback = (): Action =>
  action(ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_SUCCESS_CALLBACK)

export const consultaMargemGeralError = (): Action =>
  action(ConsultaMargemGeralTypes.CONSULTA_MARGEM_GERAL_ERROR)

export const setValorMargem = (payload: number): Action =>
  action(ConsultaMargemGeralTypes.SET_VALOR_MARGEM, payload)

export const setDataValidadeAutorizacao = (payload: string): Action =>
  action(ConsultaMargemGeralTypes.SET_DATA_VALIDADE_AUTORIZACAO, payload)
