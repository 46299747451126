import { action, Action } from 'typesafe-actions'

import { DadosProfissionaisTypes, RequestEmpresaPayload } from './types'

export const setNomeEmpresa = (payload: string): Action =>
  action(DadosProfissionaisTypes.SET_NOME_EMPRESA, payload)
export const setTempoEmpresa = (
  payload: string | string[] | number[] | number | undefined,
): Action => action(DadosProfissionaisTypes.SET_TEMPO_EMPRESA, payload)
export const setSalario = (payload: number): Action =>
  action(DadosProfissionaisTypes.SET_SALARIO, payload)
export const getValidaEmpresa = (payload: RequestEmpresaPayload): Action =>
  action(DadosProfissionaisTypes.REQUEST_VALIDA_EMPRESA, payload)
export const setIsEmpresaValida = (payload: boolean): Action =>
  action(DadosProfissionaisTypes.SET_IS_EMPRESA_VALIDA, payload)
