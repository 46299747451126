import shajs from 'sha.js'

export const ocultarTelefone = (telefone: string) =>
  telefone ? `*****${telefone.substr(telefone.length - 4)}` : '*****'

export const ocultarEmail = (fullEmail: string) => {
  if (!fullEmail) return '****'
  const [email, domain] = fullEmail.split('@')
  return `${email.substr(0, Math.ceil(email.length * 0.4))}*****@${domain}`
}

export const hashString = (text: string) => shajs('sha256').update(text).digest('hex')
