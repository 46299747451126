import getFontesPagamentos from './getFontesPagamentos'
import getConvenios from './getConvenios'
import getCidades from './getCidades'
import getEstados from './getEstados'
import getOrgaos from './getOrgaos'
import getUpags from './getUpags'
import getCPF from './getCPF'
import getValidaCliente from './getValidaCliente'
import postSimulacaoNovo from './postSimulacaoNovo'
import postValidaMatricula from './postValidaMatricula'
import postTrackingSimulacao from './postTrackingSimulacao'
import postSalvarLeadNovo from './postSalvarLeadNovo'
import postCloudMarketRemove from './postCloudMarketRemove'
import postSalvarSimulacaoNovo from './postSalvarSimulacaoNovo'
import postSalvarSimulacaoSangueLaranja from './postSalvarSimulacaoSangueLaranja'
import getValidaEmpresaNovo from './getValidaEmpresaNovo'
import getValidaIndicacao from './getValidaIndicacao'
import getValidaToken from './getValidaToken'
import postEnviaToken from './postEnviaToken'
import getDadosBeneficiarioInss from './getDadosBeneficiarioInss'
import postConsultaMargemExercito from './postConsultaMargemExercito'
import postVerificaPendenciaLead from './postVerificaPendenciaLead'
import postDataLakeContato from './postDataLakeContato'
import postVerificaPendenciaPortabilidade from './postVerificaPendenciaPortabilidade'
import postValidaPendencia from './postValidaPendencia'
import postValidaNovoReforma from './postValidaNovoReforma'
import postConsultaMargemRio from './postConsultaMargemRio'
import postIndicacaoWhatsapp from './postIndicacaoWhatsapp'
import getDadosUsuario from './getDadosUsuario'
import putScheduleSimulation from './putScheduleSimulation'
import postScheduleSimulation from './postScheduleSimulation'
import getSimulacaoSangueLaranja from './getSimulacaoSangueLaranja'
import getDadosUsuarioPropostaSangueLaranja from './getDadosUsuarioPropostaSangueLaranja'
import getPropostas from './getPropostas'
import postSalvarContratoSangueLaranja from './postSalvarContratoSangueLaranja'
import putAtualizarProposta from './putAtualizarProposta'
import getParcelaSimulacaoSangueLaranja from './getParcelaSimulacaoSangueLaranja'
import getContratosAtivos from './getContratosAtivos'
import getDadosInssResult from './getDadosInssResult'
import postInssAsyncStart from './postInssAsyncStart'
import postAtualizarCacheSiape from './postAtualizaCacheSiape'
import getCacheSiape from './getCacheSiape'

export default {
  getFontesPagamentos,
  getConvenios,
  getCidades,
  getEstados,
  getOrgaos,
  getPropostas,
  getUpags,
  getValidaCliente,
  postSimulacaoNovo,
  getSimulacaoSangueLaranja,
  getCPF,
  postValidaMatricula,
  postTrackingSimulacao,
  postSalvarLeadNovo,
  postCloudMarketRemove,
  postSalvarSimulacaoNovo,
  postSalvarSimulacaoSangueLaranja,
  getValidaEmpresaNovo,
  getValidaIndicacao,
  getValidaToken,
  postEnviaToken,
  getDadosBeneficiarioInss,
  postConsultaMargemExercito,
  postVerificaPendenciaLead,
  postDataLakeContato,
  postVerificaPendenciaPortabilidade,
  postValidaPendencia,
  postValidaNovoReforma,
  postConsultaMargemRio,
  postIndicacaoWhatsapp,
  getDadosUsuario,
  putScheduleSimulation,
  postScheduleSimulation,
  getDadosUsuarioPropostaSangueLaranja,
  postSalvarContratoSangueLaranja,
  putAtualizarProposta,
  getParcelaSimulacaoSangueLaranja,
  getContratosAtivos,
  getDadosInssResult,
  postInssAsyncStart,
  getCacheSiape,
  postAtualizarCacheSiape,
}
