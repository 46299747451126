import React from 'react'

import { InterUIButton } from '@interco/inter-ui-react-lib'
import { P, Colors } from '@interco/cp-react-ui-lib'
import { Grid } from '@material-ui/core'

interface Props {
  onRetry: () => void
  titleError: string | undefined
  errorMessage: string
}

const ErrorToken = ({ titleError, errorMessage, onRetry }: Props) => (
  <Grid container direction="column">
    <P
      variant="sora"
      fontWeight="600"
      fontSize="16px"
      lineHeight="20px"
      color={Colors.GRAY500}
      margin="0 0 12px 0"
    >
      {titleError || ''}
    </P>

    <P margin="0 0 20px 0">{errorMessage}</P>

    <InterUIButton onClick={onRetry}>Tentar Novamente</InterUIButton>
  </Grid>
)

export default ErrorToken
