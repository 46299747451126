import React from 'react'

import { format } from 'date-fns'
import { isIOS } from 'react-device-detect'
import qs from 'qs'

import ISafe from '@interco/icons/orangeds/MD/i-safe'
import Market from '@interco/icons/orangeds/SM/market'
import HistoricoPedido from '@interco/icons/orangeds/SM/historico-de-pedidos'
import Savings from '@interco/icons/orangeds/SM/savings'
import HomeBroker from '@interco/icons/orangeds/SM/home-broker'
import Travel from '@interco/icons/orangeds/SM/travel'
import HEllipsis from '@interco/icons/orangeds/MD/h-ellipsis'
import Hide from '@interco/icons/orangeds/SM/hide'
import PrepaymentOfReceivables from '@interco/icons/orangeds/MD/prepayment-of-receivables'
import { formatCurrencySemCifrao, removeMask } from '@utils/masks'
import { Convenio, Orgao, Cidade, Estado, Upag } from '@store/globalApi/types'
import {
  ConveniosCodes,
  FontesPagamento,
  MotivosInssNovo,
  SituacoesFuncionaisSiape,
} from '@utils/enums'
import { Simulacao } from '@store/novo/simulacao/types'
import { NovoState } from '@store/novo'
import { BridgeService } from '@services/bridge'
import { Colors } from '@utils/Colors'

export const ParcelaValores = {
  TWELVE: 12,
  TWENTY_FOUR: 24,
  THIRTY_SIX: 36,
  FORTY_EIGHT: 48,
  SIXTY: 60,
  SEVENTY_TWO: 72,
  EIGHTY_FOUR: 84,
  NINETY_SIX: 96,
  ONE_HUNDRED_TWENTY: 120,
}

export enum SimulacaoType {
  SELECT_TOTAL = 'total',
  SELECT_MONTH = 'month',
  PUBLICO = 'publico',
  EMPRESA = 'empresa',
}

export interface PublicoType {
  24: number
  36: number
  48: number
  60: number
  72: number
  84: number
  96: number
  120: number
}

export interface EmpresaType {
  12: number
  24: number
  36: number
  48: number
}

export type ParcelasTypes = 12 | 24 | 36 | 48 | 60 | 72 | 84 | 96 | 120

export const TAXA_PUBLICO = 0.017

export const TAXA_PRIVADO = 0.024

export const CARENCIA = 90

export const VALOR_ENUM = {
  publico: {
    min: 1600,
    max: 400000,
  },
  inss: {
    min: 1600,
    max: 400000,
  },
  empresa: {
    min: 1000,
    max: 100000,
  },
}

export const ENUM_PARCELAS = {
  empresa: [
    ParcelaValores.TWELVE,
    ParcelaValores.TWENTY_FOUR,
    ParcelaValores.THIRTY_SIX,
    ParcelaValores.FORTY_EIGHT,
  ],
  publico: [
    ParcelaValores.TWENTY_FOUR,
    ParcelaValores.THIRTY_SIX,
    ParcelaValores.FORTY_EIGHT,
    ParcelaValores.SIXTY,
    ParcelaValores.SEVENTY_TWO,
    ParcelaValores.EIGHTY_FOUR,
    ParcelaValores.NINETY_SIX,
    ParcelaValores.ONE_HUNDRED_TWENTY,
  ],
  inss: [
    ParcelaValores.TWENTY_FOUR,
    ParcelaValores.THIRTY_SIX,
    ParcelaValores.FORTY_EIGHT,
    ParcelaValores.SIXTY,
    ParcelaValores.SEVENTY_TWO,
    ParcelaValores.EIGHTY_FOUR,
  ],
}

export type FamiliaType = 'publico' | 'empresa' | 'inss'

interface Props {
  valorTotal: number
  parcelas: keyof PublicoType | keyof EmpresaType
  familia: FamiliaType
  isTotal: boolean
}

interface PropsMinMax {
  familia: FamiliaType
  isTotal: boolean
}

/**
 * Calcula o valor da simulação baseado nos parâmetros passados
 * @param valorTotal
 * @param parcelas
 * @param familia
 * @param isTotal
 * @returns value formatted
 */
export const calcularValorSimulado = ({ valorTotal, parcelas, familia, isTotal }: Props) => {
  const taxas = familia === FamiliaEnum.EMPRESA ? TAXA_PRIVADO : TAXA_PUBLICO
  const valorCalculado = isTotal
    ? (taxas / (1 - 1 / (1 + taxas) ** parcelas)) *
      (valorTotal * (1 + taxas) ** ((CARENCIA - 30) / 30))
    : valorTotal /
      (taxas / (1 - 1 / (1 + taxas) ** parcelas)) /
      (1 + taxas) ** ((CARENCIA - 30) / 30)

  return formatCurrencySemCifrao(valorCalculado)
}

export const getMaxValue = ({ familia, isTotal }: PropsMinMax) => {
  if (isTotal) {
    return VALOR_ENUM[familia].max
  }
  const taxas = familia === FamiliaEnum.EMPRESA ? TAXA_PRIVADO : TAXA_PUBLICO
  const { max } = VALOR_ENUM[familia]
  return taxas * max
}

export const getMinValue = ({ familia, isTotal }: PropsMinMax) => {
  if (isTotal) {
    return VALOR_ENUM[familia].min
  }
  const taxas = familia === FamiliaEnum.EMPRESA ? TAXA_PRIVADO : TAXA_PUBLICO
  const { min } = VALOR_ENUM[familia]
  return taxas * min
}

export const buildEstados = (estados: Estado[]) =>
  estados?.map(({ uf }) => ({ value: uf, label: uf }))

export const buildEstadosNovo = (estados: Estado[]) =>
  estados?.map(({ uf }) => ({ value: uf, text: uf }))

export const buildCidades = (cidades: Cidade[]) =>
  cidades?.map((cidade) => ({ value: cidade.codigo, label: cidade.nome }))

export const buildCidadesNovo = (cidades: Cidade[]) =>
  cidades?.map((cidade) => ({ value: cidade.codigo, text: cidade.nome }))

export const buildconvenios = (convenios: Convenio[]) =>
  convenios?.map((convenio) => ({
    value: convenio.codigo,
    label: convenio.descricao,
  }))

export const buildconveniosNovo = (convenios: Convenio[]) =>
  convenios?.map((convenio) => ({
    value: convenio.codigo,
    text: convenio.descricao,
  }))

export const buildUpagsNovo = (upags: Upag[]) =>
  upags?.map((upag) => ({
    value: `${upag.sigla} - ${upag.nome} - ${upag.codigoUpag}`,
    text: `${upag.sigla} - ${upag.nome}`,
  }))

export const buildOrgaos = (orgaos: Orgao[]) =>
  orgaos?.map((orgao) => ({ value: orgao.codigo, label: orgao.descricao }))

export const buildOrgaosNovo = (orgaos: Orgao[]) =>
  orgaos?.map((orgao) => ({ value: orgao.codigo, text: orgao.descricao }))

export const buildCidadePorConvenio = (convenios: Convenio[], convenioSelecionado: number) =>
  convenios.find((convenio) => convenio.codigo === convenioSelecionado)?.estado || ''

export const motivoOptionsNovo = (situacaoFuncional: string | number | undefined) => {
  if (!situacaoFuncional) return []
  if (situacaoFuncional === 'Aposentado(a)') {
    return MotivosInssNovo.aposentado_pensionista
  }
  return []
}

/**
 * Verifica se o fluxo seguinte é o de situação funcional baseado nos parâmetros informados.
 * Essa função também serve pra determinar em que step atual a barra de progresso se encontra.
 * @param convenioSelecionado
 * @param fonteSelecionada
 * @returns boolean
 */
export const verificarFluxoSituacaoFuncional = (
  convenioSelecionado: string | number | undefined,
  fonteSelecionada: number | undefined,
) => {
  if (
    (convenioSelecionado === ConveniosCodes.ESTADO_SP &&
      fonteSelecionada === FontesPagamento.ESTADUAL) ||
    (convenioSelecionado === ConveniosCodes.PREF_SP &&
      fonteSelecionada === FontesPagamento.MUNICIPAL) ||
    fonteSelecionada === FontesPagamento.FEDERAL ||
    (convenioSelecionado === ConveniosCodes.EXERCITO_BR &&
      fonteSelecionada === FontesPagamento.FORCAS_ARMADAS) ||
    fonteSelecionada === FontesPagamento.INSS ||
    (convenioSelecionado === ConveniosCodes.ESTADO_MT &&
      fonteSelecionada === FontesPagamento.ESTADUAL)
  ) {
    return true
  }
  return false
}

/**
 * Verifica se o fluxo é o Municipal/Estadual de SP
 * @param convenioSelecionado
 * @param fonteSelecionada
 * @returns boolean
 */
export const isFluxoSP = (
  convenioSelecionado: string | number | undefined,
  fonteSelecionada: number | undefined,
) => {
  if (
    (convenioSelecionado === ConveniosCodes.ESTADO_SP &&
      fonteSelecionada === FontesPagamento.ESTADUAL) ||
    (convenioSelecionado === ConveniosCodes.PREF_SP &&
      fonteSelecionada === FontesPagamento.MUNICIPAL)
  ) {
    return true
  }
  return false
}

export const EmprestimoProtegidoBeneficios = [
  {
    icone: <ISafe height={25} width={25} color={Colors.GRAY500} />,
    titulo: 'Tenha tranquilidade',
    descricao: 'Em caso de morte ou invalidez, seu empréstimo é quitado',
  },
  {
    icone: <PrepaymentOfReceivables height={25} width={25} color={Colors.GRAY500} />,
    titulo: 'Crédito rápido',
    descricao:
      'Seu crédito é liberado em até 3 dias, caso esteja tudo certo com a sua documentação',
  },
]

export const EmprestimoBeneficios = [
  {
    icone: <PrepaymentOfReceivables height={25} width={25} color={Colors.GRAY500} />,
    titulo: 'Crédito rápido',
    descricao:
      'Seu crédito é liberado em até 3 dias, caso esteja tudo certo com a sua documentação',
  },
]

export const retornaUltimaStringNaoVazia = (arr: string[]) => {
  let returnString = ''
  for (let i = arr.length - 1; i >= 0; i -= 1) {
    if (arr[i] !== '') {
      returnString = arr[i]
      break
    }
  }
  return returnString
}

export const lastName = (name: string) => {
  if (name) {
    const arr = name.split(' ')
    return arr.length > 1 ? retornaUltimaStringNaoVazia(arr) : ''
  }
  return ''
}

export const allNamesAfterFirstName = (name: string) =>
  name?.split(' ')?.slice(1, name.length)?.join(' ') || ''

export const firstName = (name: string) => {
  if (name) {
    const arr = name.split(' ')
    const [result] = arr
    return result
  }
  return ''
}

export const calcDataPrimeiroDesconto = (simulationData: Simulacao) => {
  const ultimaData = simulationData.dataUltimoVencimento
  const primeiraData = new Date(ultimaData)
  primeiraData.setMonth(primeiraData.getMonth() - simulationData.qtdeParcelas)
  return format(primeiraData, 'yyyy-MM-dd')
}

export enum TipoSimulacaoType {
  VALOR_TOTAL = 1,
  VALOR_PARCELA = 2,
}

export const isTipoSimulacaoParcela = (tipoSimulacao: number) =>
  tipoSimulacao && tipoSimulacao === TipoSimulacaoType.VALOR_PARCELA

export enum FamiliaEnum {
  EMPRESA = 'empresa',
  PUBLICO = 'publico',
}

const verificaSeSiapeConsultaAutorizada = (
  consultaAutorizada: boolean,
  convenioId: string | number | undefined,
) => {
  if (String(convenioId) === String(ConveniosCodes.SIAPE_SERVIDOR_FEDERAL)) {
    if (!consultaAutorizada) return false
  }
  return true
}

const verificaSeExercitoTentativas = (
  numeroTentativas: number,
  convenioId: string | number | undefined,
) => {
  if (String(convenioId) === String(ConveniosCodes.EXERCITO_BR)) {
    if (numeroTentativas >= 3) return false
  }
  return true
}
interface PropsRedirect {
  convenioId: number | string | undefined
  clientId: string | undefined
  cpf: string
  origem: string
  idSimulacao: number | string | undefined
  familia: FamiliaType
  codigoIndicacao: string | undefined
  matricula: string | number | undefined
  consultaAutorizada: boolean
  numeroTentativas: number
  simplificadaInss: boolean | undefined
  tipoCredito?: string
  valorSimulado: number
  codigoAplicado?: boolean
}

export const redirecionarOnboardingDocumentos = ({
  convenioId,
  clientId,
  cpf,
  origem,
  idSimulacao,
  familia,
  codigoIndicacao,
  matricula,
  consultaAutorizada,
  numeroTentativas,
  simplificadaInss,
  tipoCredito,
  valorSimulado,
  codigoAplicado,
}: PropsRedirect) => {
  /* Redireciona pra o captura caso esteja na lista de convenios que permitem contratação completa e
     Sendo IOS só redireciona pra cpfs especificos de teste.
  */
  if (
    (process.env.REACT_APP_BLOCK_ONBOARDING_DOCUMENTS_TO_APP !== 'true' &&
      !isIOS &&
      familia === FamiliaEnum.PUBLICO &&
      process.env.REACT_APP_CONVENIOS_LIST?.split(' ').includes(String(convenioId)) &&
      consultaAutorizada &&
      verificaSeExercitoTentativas(numeroTentativas, convenioId) &&
      verificaSeSiapeConsultaAutorizada(consultaAutorizada, convenioId)) ||
    (process.env.REACT_APP_BLOCK_ONBOARDING_DOCUMENTS_TO_APP !== 'true' &&
      isIOS &&
      familia === FamiliaEnum.PUBLICO &&
      process.env.REACT_APP_CONVENIOS_LIST?.split(' ').includes(String(convenioId)) &&
      // process.env.REACT_APP_CPF_VALIDOS_CAPTURA?.split(' ').includes(String(cpf)) &&
      consultaAutorizada &&
      verificaSeExercitoTentativas(numeroTentativas, convenioId) &&
      verificaSeSiapeConsultaAutorizada(consultaAutorizada, convenioId))
  ) {
    const params = qs.stringify({
      covenantId: convenioId || undefined,
      clientId: clientId || undefined,
      cpf: cpf ? removeMask(cpf) : undefined,
      origem: origem || undefined,
      simulationId: idSimulacao || undefined,
      familia: familia || undefined,
      codigoIndicacao: codigoIndicacao || undefined,
      matricula: removeMask(String(matricula)) || undefined,
      simplificadaInss: simplificadaInss || undefined,
      tipoCredito: tipoCredito || undefined,
      valorSimulado: valorSimulado || undefined,
      codigoAplicado: codigoAplicado || undefined,
    })
    // TODO: Alterar aqui conforme subidas web ou app pro inss/siape
    if (String(convenioId) === '6' || String(convenioId) === '3') {
      const url = `${process.env.REACT_APP_URL_CAPTURA_NOVO}/contratacao?${params}`
      window.location.href = url
      return true
    }

    const url = `${process.env.REACT_APP_URL_CAPTURA_NOVO}/novo?${params}`
    window.location.href = url
    return true
  }
  return false
}

/**
 * Converte data string para data no formato yyyy-mm-dd
 * @param value String
 * @returns yyyy-mm-dd
 */
export const datePtToEn = (date: string) =>
  date
    ? format(
        new Date(
          Number(date.substring(6)),
          Number(date.substring(3, 5)) - 1,
          Number(date.substring(0, 2)),
        ),
        'yyyy-MM-dd',
      )
    : ''

export const findUpag = (listaUpags: Upag[], upag: string) => {
  const upagFound = listaUpags?.find(
    (upagItem) => `${upagItem.sigla} - ${upagItem.nome} - ${upagItem.codigoUpag}` === upag,
  )
  if (upagFound) {
    return `${upagFound?.sigla} - ${upagFound.nome}`
  }
  return ''
}

export const convenioMargemAutorizada = (storeNovo: NovoState) => {
  const { convenioSelecionado } = storeNovo.fontePagamento

  switch (convenioSelecionado) {
    case ConveniosCodes.INSS:
      return (
        Boolean(storeNovo.beneficiarioInss.dadosBeneficiario?.isBeneficioValido) &&
        Boolean(storeNovo.beneficiarioInss.dadosBeneficiario?.isConsultaRealizada)
      )
    case ConveniosCodes.ESTADO_SP:
    case ConveniosCodes.ESTADO_MT:
      return storeNovo.consultaMargemCip.consultaMargemAutorizada
    case ConveniosCodes.EXERCITO_BR:
      return storeNovo.consultaMargemExercito.averbacaoValidaExercito
    case ConveniosCodes.ESTADO_RJ:
      return storeNovo.consultaMargemRio.averbacaoValidaRio
    case ConveniosCodes.PREF_SP:
    case ConveniosCodes.PREF_BH:
    case ConveniosCodes.PREF_SALVADOR:
    case ConveniosCodes.PREF_CAMPO_GRANDE:
    case ConveniosCodes.PREF_BETIM:
    case ConveniosCodes.PREF_VITORIA:
    case ConveniosCodes.SIAPE_SERVIDOR_FEDERAL:
      return storeNovo.consultaMargemGeral.consultaMargemAutorizada
    default:
      return false
  }
}

export const EmprestimoIdeal = [
  {
    value: 0,
    text: 'Para investir no meu próprio negócio',
    icon: <Market width={24} height={24} color={Colors.GRAY500} />,
  },
  {
    value: 1,
    text: 'Para despesas imprevistas',
    icon: <HistoricoPedido width={24} height={24} color={Colors.GRAY500} />,
  },
  {
    value: 2,
    text: 'Para pagamento de dívida',
    icon: <Savings width={24} height={24} color={Colors.GRAY500} />,
  },
  {
    value: 3,
    text: 'Para reformas do lar',
    icon: <HomeBroker width={24} height={24} color={Colors.GRAY500} />,
  },
  { value: 4, text: 'Para viajar', icon: <Travel width={24} height={24} color={Colors.GRAY500} /> },
  {
    value: 5,
    text: 'Outros motivos',
    icon: <HEllipsis width={24} height={24} color={Colors.GRAY500} />,
  },
  {
    value: 6,
    text: 'Prefiro não informar',
    icon: <Hide width={24} height={24} color={Colors.GRAY500} />,
  },
]

export const situacoesSiapeNaoLiberadas = (situacaoFuncional?: string | number) => {
  if (
    situacaoFuncional === SituacoesFuncionaisSiape.COMISSIONADO ||
    situacaoFuncional === SituacoesFuncionaisSiape.TEMPORARIO ||
    situacaoFuncional === SituacoesFuncionaisSiape.CURATELADO_TUTELADO ||
    situacaoFuncional === SituacoesFuncionaisSiape.CONTRATADO ||
    situacaoFuncional === SituacoesFuncionaisSiape.ANALFABETO
  ) {
    return true
  }
  return false
}

export const conveniosComConsultaMargem = [
  ConveniosCodes.INSS,
  ConveniosCodes.EXERCITO_BR,
  ConveniosCodes.SIAPE_SERVIDOR_FEDERAL,
  ConveniosCodes.PREF_SP,
  ConveniosCodes.ESTADO_SP,
  ConveniosCodes.ESTADO_MT,
]

export const skipContactTokenPages = () => {
  if (!BridgeService.isBrowser()) {
    return true
  }
  return false
}
