import { ConvenioApi } from '@store/globalApi/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (fontePagamento: number, newSimulatorApi: boolean) => {
  const pathname = !newSimulatorApi
    ? `${process.env.REACT_APP_CONVENIOS}?familia=${fontePagamento}`
    : `${process.env.REACT_APP_CONVENIOS_BFF}?family=${fontePagamento}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ConvenioApi>({
    pathname,
    method,
    host,
  })
  return response
}
