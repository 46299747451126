/**
 * Actions Types
 */

export enum ConsultaMargemGeralTypes {
  SET_CHECK_CONSULTA_MARGEM_GERAL = '@novo/simulacao/SET_CHECK_CONSULTA_MARGEM_GERAL',
  CONSULTA_MARGEM_GERAL_SUCCESS = '@novo/situacaoFuncional/CONSULTA_MARGEM_GERAL_SUCCESS',
  CONSULTA_MARGEM_GERAL_SUCCESS_CALLBACK = '@novo/situacaoFuncional/CONSULTA_MARGEM_GERAL_SUCCESS_CALLBACK',
  CONSULTA_MARGEM_GERAL_ERROR = '@novo/situacaoFuncional/CONSULTA_MARGEM_GERAL_ERROR',
  CONSULTA_MARGEM_GERAL_REQUEST = '@novo/situacaoFuncional/CONSULTA_MARGEM_GERAL_REQUEST',
  SET_VALOR_MARGEM = '@novo/situacaoFunciona/geral/SET_VALOR_MARGEM',
  SET_DATA_VALIDADE_AUTORIZACAO = '@novo/situacaoFunciona/geral/SET_DATA_VALIDADE_AUTORIZACAO',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface ConsultaMargemGeralState {
  readonly checkConsultaMargem: boolean
  readonly loading: boolean
  readonly error: boolean
  readonly consultaMargemAutorizada: boolean
  readonly margem: number
  readonly dataValidadeAutorizacao: string
}

export type ConsultaMargemGeralPayload = {
  callback: (margem: number, tentativas: number, pendencia: string) => void
}
